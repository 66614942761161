import React, { useState } from "react";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import AscendingSvg from "../../components/Form/SortSvg/AscendingSvg";
import DescendingSvg from "../../components/Form/SortSvg/DescendingSvg";
import ProposalsFilter from "../../components/Filter/ProposalsFilter";

import Pagination from "../../components/Pagination/ProposalPagination";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProcessedProposalsPage() {
  const [sortType, setSortType] = useState("name");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortDisplayName, setSortDisplayName] = useState("по ФИО");

  const sort = (e) => {
    if (e.target.name === sortType) {
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
    }
    console.log(e.target.name);
    if (e.target.name === "name") {
      setSortType("name");
      setSortDisplayName("по ФИО");
    } else if (e.target.name === "reg") {
      setSortType("reg");
      setSortDisplayName("по рег данным");
    } else if (e.target.name === "pay") {
      setSortType("pay");
      setSortDisplayName("по статусу оплаты");
    } else {
      setSortType("manager");
      setSortDisplayName("по менеджер");
    }
  };

  return (
    <div className="mx-auto flex flex-1 flex-col w-full max-w-[1163px]">
      <div className="w-full mt-9">
        <div className="flex flex-row justify-between gap-1">
          <p className="text-2xl font-medium px-2">Отклики</p>
          {/* <p className='flex flex-wrap justify-center items-center'>
                        <button
                            type='button'
                            className='text-center border rounded py-2 px-2  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300'
                        >
                            Очистить выбор
                        </button>
                    </p> */}
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row justify-between gap-4">
        <div className="flex flex-col w-full md:w-3/4 gap-y-3 p-2">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center mt-2 gap-y-4">
            <div className="flex gap-x-3 items-start md:items-center">
              <Link to={`/unprocessed`} className="underline">
                Необработанные (9)
              </Link>
              <Link to={`/processed`} className="font-bold">
                Обработанные (15)
              </Link>
            </div>
            <div className="flex md:items-center gap-x-3 md:pr-2">
              <div className="flex  items-center">
                <p className="px-2">Сортировать: </p>
                <Menu
                  as="div"
                  className="relative inline-block text-left self-center"
                >
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-3 bg-white text-sm font-semibold text-gray-900 shadow-sm border-b-1 border-b-gray-700 hover:bg-gray-50">
                      {sortDisplayName}
                      {sortDirection ? (
                        <AscendingSvg color="#000000" />
                      ) : (
                        <DescendingSvg color="#000000" />
                      )}
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              onClick={sort}
                              name="name"
                              className={classNames(
                                "flex flex-row justify-between items-center gap-x-3.5",
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              по ФИО
                              {sortType === "name" ? (
                                sortDirection ? (
                                  <AscendingSvg color="#000000" />
                                ) : (
                                  <DescendingSvg color="#000000" />
                                )
                              ) : (
                                ""
                              )}
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <Pagination
              type={`processed`}
              itemsPerPage={10}
              sortType={sortType}
              sortDirection={sortDirection}
            />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/4 p-2">
          <ProposalsFilter />
        </div>
      </div>
    </div>
  );
}

export default ProcessedProposalsPage;
