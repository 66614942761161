import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import UnProcessedFeedBackList from "../FeedBacks/UnProcessedFeedBackList";
import ProcessedFeedBackList from "../FeedBacks/ProcessedFeedBackList";

const items = [
  {
    key: 1,
    num: 153,
    name: "Куликова Александра Мироновна",
    workButton: false,
  },
  {
    key: 2,
    num: 151,
    name: "Андреева Василиса Матвеевна",
    workButton: false,
  },
  {
    key: 3,
    num: 150,
    name: "Пахомов Александр Романович",
    workButton: false,
  },
  {
    key: 4,
    num: 149,
    name: "Крючкова Милана Артёмовна",
    workButton: false,
  },
  {
    key: 5,
    num: 148,
    name: "Гусев Макар Артёмович",
    workButton: false,
  },
  {
    key: 6,
    num: 147,
    name: "Егоров Лев Всеволодович",
    workButton: false,
  },
  {
    key: 7,
    num: 146,
    name: "Кулешова Василиса Фёдоровна",
    workButton: false,
  },
  {
    key: 8,
    num: 145,
    name: "Воронин Никита Фёдорович",
    workButton: false,
  },
  {
    key: 9,
    num: 144,
    name: "Муравьев Даниил Львович",
    workButton: false,
  },
];

function ProposalPagination({ type, itemsPerPage, sortType, sortDirection }) {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // 'items' Array sort
    if (sortType === "name") {
      sortDirection !== true
        ? items.sort((a, b) => a.name.localeCompare(b.name))
        : items.sort((a, b) => a.name.localeCompare(b.name)).reverse();
    } else if (sortType === "reg") {
      sortDirection !== true
        ? items.sort((a, b) => a.regState - b.regState)
        : items.sort((a, b) => a.regState - b.regState).reverse();
    } else if (sortType === "pay") {
      sortDirection !== true
        ? items.sort((a, b) => a.payState - b.payState)
        : items.sort((a, b) => a.payState - b.payState).reverse();
    } else {
      sortDirection !== true
        ? items.sort((a, b) => a.manager.name.localeCompare(b.manager.name))
        : items
            .sort((a, b) => a.manager.name.localeCompare(b.manager.name))
            .reverse();
    }

    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, sortType, sortDirection]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const handleChildData = (data) => {
    for (let i = 0; i < items.length; i++) {
      if (items[i].num + "" === data + "") {
        if (items[i].workButton === true) items[i].workButton = false;
        else items[i].workButton = true;
      }
    }
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
  };

  const handleMultipleChildData = (data) => {
    if (data.state === "all") {
      for (let i = 0; i < items.length; i++) {
        if (items[i].workButton === true) items[i].workButton = false;
        else items[i].workButton = true;
      }
    } else {
      for (let i = 0; i < items.length; i++) {
        for (let j = 0; j < data.checkList.length; j++) {
          if (items[i].num + "" === data.checkList[j] + "") {
            if (items[i].workButton === true) items[i].workButton = false;
            else items[i].workButton = true;
          }
        }
      }
    }

    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
  };

  return (
    <>
      {type === "unprocessed" || type === undefined ? (
        <UnProcessedFeedBackList
          currentItems={currentItems}
          sendData={handleChildData}
          sendActionData={handleMultipleChildData}
        />
      ) : (
        <ProcessedFeedBackList currentItems={currentItems} />
      )}
      <div className="flex justify-center mb-10">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </>
  );
}

export default ProposalPagination;
