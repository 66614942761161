import React, { useState } from "react";
import { Link } from "react-router-dom";
import MessagesList from "../../components/List/Messages/MessagesList";
import ProfileModal from "../../components/Modal/ProfileModal";
import DialogChatModal from "../../components/Modal/DialogChatModal";
import MessageModal from "../../components/Modal/MessageModal";

const itemsFirst = [
  {
    key: 1,
    sender_name: "ООО Магнит",
    receiver_name: "Никитина И.Ю",
    date: "18:55 18.05.22",
  },
  {
    key: 2,
    sender_name: "ИП Васильев Иван Иванович",
    receiver_name: "Андреева",
    date: "18:55 19.05.22",
  },
  {
    key: 3,
    sender_name: "ООО Магнит",
    receiver_name: "Андреева",
    date: "18:55 17.05.22",
  },
  {
    key: 4,
    sender_name: "ООО Название организаци",
    receiver_name: "Пахомов",
    date: "18:55 15.05.22",
  },
  {
    key: 5,
    sender_name: "ООО Магнит",
    receiver_name: "Пахомов",
    date: "18:55 14.05.22",
  },
  {
    key: 6,
    sender_name: "ООО Магнит",
    receiver_name: "Пахомов",
    date: "18:55 13.05.22",
  },
  {
    key: 7,
    sender_name: "ООО Магнит",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
  {
    key: 8,
    sender_name: "ООО Магнит",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
  {
    key: 9,
    sender_name: "ООО Магнит",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
];

const itemsSecond = [
  {
    key: 1,
    sender_name: "Куликова",
    receiver_name: "луговой",
    date: "18:55 18.05.22",
  },
  {
    key: 2,
    sender_name: "Андреева",
    receiver_name: "луговой",
    date: "18:55 19.05.22",
  },
  {
    key: 3,
    sender_name: "Пахомов",
    receiver_name: "луговой",
    date: "18:55 17.05.22",
  },
  {
    key: 4,
    sender_name: "Крючкова",
    receiver_name: "луговой",
    date: "18:55 15.05.22",
  },
  {
    key: 5,
    sender_name: "Гусев",
    receiver_name: "луговой",
    date: "18:55 14.05.22",
  },
  {
    key: 6,
    sender_name: "Гусев",
    receiver_name: "Крючкова",
    date: "18:55 13.05.22",
  },
  {
    key: 7,
    sender_name: "Гусев",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
  {
    key: 8,
    sender_name: "Гусев",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
  {
    key: 9,
    sender_name: "Гусев",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
];

function MessagesIndex() {
  const [chatModalState, setChatModalState] = useState(false);
  const [profileModalState, setProfileModalState] = useState(false);
  const [messageModalState, setMessageModalState] = useState(false);

  const handleDataFromProfileModal = (data) => {
    setProfileModalState(false);
  };

  const handleDataFromButtonOnProfile = () => {
    setProfileModalState(!profileModalState);
    setChatModalState(!chatModalState);
  };

  const handleChatModal = () => {
    setChatModalState(!chatModalState);
  };

  const handleDataFromChatModal = () => {
    setProfileModalState(!profileModalState);
    setChatModalState(!chatModalState);
  };

  const handleMessage = () => {
    setMessageModalState(true);
  };

  const handleCancel = (state) => {
    setMessageModalState(state);
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <div className="container relative flex flex-1 flex-col mx-auto max-w-[1163px]">
        <div className="flex flex-col w-full gap-y-2">
          <h2 className="text-[25px] font-normal text-black">Сообщения</h2>
          <div className="flex flex-col 468:flex-row gap-2">
            <button
              type="button"
              className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
              onClick={handleChatModal}
            >
              Диалог
            </button>
            <button
              type="button"
              className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
              onClick={handleMessage}
            >
              Написать сообщение
            </button>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-3 mt-6">
          <div className="flex flex-col w-full lg:w-1/2 gap-2">
            <div className="flex w-full">
              <Link
                to={`/counterpartydetail`}
                className="flex flex-row gap-3 items-baseline"
              >
                <h2 className="text-lg font-normal text-black">
                  Контрагенты/Админы
                </h2>
                <label className="text-xs font-normal cursor-pointer">
                  (18 новых)
                </label>
              </Link>
            </div>
            <div className="flex flex-col w-full h-[296px] border-1 border-[#C4C4C4] shadow-lg p-4 overflow-auto">
              <MessagesList currentItems={itemsFirst} />
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-1/2 gap-2">
            <div className="flex w-full">
              <Link to={`/applicantdetail`}>
                <h2 className="text-lg font-normal text-black">
                  Соискатели/Админы
                </h2>
              </Link>
            </div>
            <div className="flex flex-col w-full h-[296px] border-1 border-[#C4C4C4] shadow-lg p-4 overflow-auto">
              <MessagesList currentItems={itemsSecond} />
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-3 my-6">
          <div className="flex flex-col w-full lg:w-1/2 gap-2">
            <div className="flex w-full">
              <Link
                to={`/managerdetail`}
                className="flex flex-row items-baseline gap-3"
              >
                <h2 className="text-lg font-normal text-black">
                  Менеджеры/Менеджеры
                </h2>
                <label className="text-xs font-normal cursor-pointer">
                  (18 новых)
                </label>
              </Link>
            </div>
            <div className="flex flex-col w-full h-[296px] border-1 border-[#C4C4C4] shadow-lg p-4 overflow-auto">
              <MessagesList
                currentItems={itemsSecond}
                currentState={"manager"}
              />
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-1/2 gap-2">
            <div className="flex w-full">
              <Link to={`/toApplicantdetail`}>
                <h2 className="text-lg font-normal text-black">
                  Контрагенты/Соискатели
                </h2>
              </Link>
            </div>
            <div className="flex flex-col w-full h-[296px] border-1 border-[#C4C4C4] shadow-lg p-4 overflow-auto">
              <MessagesList currentItems={itemsFirst} />
            </div>
          </div>
        </div>
      </div>
      <ProfileModal
        open={profileModalState}
        sendDataToParent={handleDataFromProfileModal}
        sendDataToParentFromButton={handleDataFromButtonOnProfile}
      />
      <DialogChatModal
        open={chatModalState}
        sendDataToParent={handleDataFromChatModal}
        onCancel={handleChatModal}
      />
      <MessageModal open={messageModalState} onCancel={handleCancel} />
    </div>
  );
}

export default MessagesIndex;
