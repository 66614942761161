import React, { useState, useEffect } from "react";

import ProfileModal from "../../Modal/ProfileModal";
import DialogChatModal from "../../Modal/DialogChatModal";
import { Link } from "react-router-dom";

function BlockedList({ currentItems, sendDataToParent }) {
  const [checkState, setCheckState] = useState(0);
  const [profileModalState, setProfileModalState] = useState(false);
  const [chatModalState, setChatModalState] = useState(false);

  useEffect(() => {
    if (checkState === 0) {
      sendDataToParent(0);
    }
  }, [checkState, sendDataToParent]);

  const handleCheck = (e) => {
    const tag = document.getElementById("select_all");
    if (e.target.name === "all") {
      const checkboxes = document.querySelectorAll(".select_checkbox");
      if (tag.checked) {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = true;
        });
      } else {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }

      if (e.target.checked === true) {
        setCheckState(checkboxes.length);
        sendDataToParent(checkboxes.length);
      } else {
        setCheckState(0);
        sendDataToParent(0);
      }
    } else {
      tag.checked = false;
      if (e.target.checked === true) {
        setCheckState(checkState + 1);
        sendDataToParent(checkState + 1);
      } else {
        setCheckState(checkState - 1);
        sendDataToParent(checkState - 1);
      }
    }
  };

  const handleProfileModal = () => {
    setProfileModalState(!profileModalState);
  };

  const handleDataFromProfileModal = (data) => {
    setProfileModalState(false);
  };

  const handleDataFromButtonOnProfile = () => {
    setProfileModalState(!profileModalState);
    setChatModalState(!chatModalState);
  };

  const handleChatModal = () => {
    setChatModalState(!chatModalState);
  };

  const handleDataFromChatModal = () => {
    setProfileModalState(!profileModalState);
    setChatModalState(!chatModalState);
  };

  return (
    <>
      <table className="w-fit divide-y divide-gray-200">
        <thead>
          <tr>
            <th
              scope="col"
              className="pr-2 py-3 text-start text-[16px] font-medium uppercase"
            >
              <input
                id="select_all"
                name="all"
                type="checkbox"
                aria-checked={false}
                className="h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                onClick={handleCheck}
              />
            </th>
            <th
              scope="col"
              className="px-2 py-3 text-start text-[14px] font-medium hidden md:table-cell"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-2 py-3 text-start text-[14px] font-medium"
            >
              ФИО
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 items-center justify-center align-middle">
          {currentItems &&
            currentItems.map((item) => (
              <tr key={item.key}>
                <td className="pr-2 py-4 whitespace-nowrap text-sm text-gray-800">
                  <input
                    name={item.num}
                    type="checkbox"
                    aria-checked={"false"}
                    onClick={handleCheck}
                    className="select_checkbox h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                  />
                </td>
                <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800 hidden md:table-cell">
                  {item.num}
                </td>
                <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                  <p className="block md:hidden">{item.num}</p>
                  <Link onClick={handleProfileModal}>{item.name}</Link>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <ProfileModal
        open={profileModalState}
        sendDataToParent={handleDataFromProfileModal}
        sendDataToParentFromButton={handleDataFromButtonOnProfile}
      />

      <DialogChatModal
        open={chatModalState}
        sendDataToParent={handleDataFromChatModal}
        onCancel={handleChatModal}
      />
    </>
  );
}

export default BlockedList;
