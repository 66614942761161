import React from 'react'
import { Modal } from 'antd'

function RoleModal({open, sendDataToParent}) {

    const handleCancel = () => {
        sendDataToParent(false)
    }

    return (
        <>
            <Modal 
                open={open}
                onCancel={handleCancel}
                footer=""
            >
                <div className='flex flex-col gap-3'>
                    <div className='flex flex-row items-center gap-x-2'>
                        <input 
                            type="checkbox" 
                            id='role1' 
                            className="accent-button text-sm w-4 h-4 mb-2" 
                        />
                        <label htmlFor='role1' className='block mb-2 text-base text-gray-900'>
                            Полномочие контрагента
                        </label>
                    </div>
                    <div className='flex flex-row items-center gap-x-2'>
                        <input 
                            type="checkbox" 
                            id='role1' 
                            className="accent-button text-sm w-4 h-4 mb-2" 
                        />
                        <label htmlFor='role1' className='block mb-2 text-base text-gray-900'>
                            Полномочие контрагента
                        </label>
                    </div>
                    <div className='flex flex-row items-center gap-x-2'>
                        <input 
                            type="checkbox" 
                            id='role1' 
                            className="accent-button text-sm w-4 h-4 mb-2" 
                        />
                        <label htmlFor='role1' className='block mb-2 text-base text-gray-900'>
                            Полномочие контрагента
                        </label>
                    </div>
                    <div className='flex flex-row items-center gap-x-2'>
                        <input 
                            type="checkbox" 
                            id='role1' 
                            className="accent-button text-sm w-4 h-4 mb-2" 
                        />
                        <label htmlFor='role1' className='block mb-2 text-base text-gray-900'>
                            Полномочие контрагента
                        </label>
                    </div>
                    <div className='flex flex-row justify-between items-center'>
                        <button
                            type='button'
                            className='text-center text-[14px] border rounded py-2 px-4 text-white  mr-2 w-full break-words p-2 xs:w-full hover:bg-[#069488] bg-[#00B2A4]'
                            onClick={handleCancel}
                        >
                            Сохранить
                        </button>
                        <button
                            type='button'
                            className='text-center border rounded py-2 px-4 text-button border-button border-1 w-full hover:text-white bg-white hover:bg-button transition ease-in-out duration-300'
                            onClick={handleCancel}
                        >
                            Отмена
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default RoleModal