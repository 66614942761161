import React from "react";
// import { DatePicker } from 'antd'

// const dateFormat = 'DD-MM-YYYY';

function ContractorFilter() {
  return (
    <div className="flex flex-col w-full h-fit p-4 rounded-md shadow-[0_0_5px_#C4C4C4] mb-4">
      <div className="mb-6">
        <p className="text-xl font-medium">Фильтр</p>
      </div>

      {/* <div className='mb-3'>
                <input
                    type="text"
                    name="id"
                    id="id"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                    placeholder='ID'
                    required
                />
            </div> */}

      <div className="mb-3">
        <input
          type="text"
          name="name"
          id="name"
          className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
          placeholder="Название"
          required
        />
      </div>

      {/* {
                page === 'all' || page === 'broadcast' ?
                    <div className='mb-3'>
                        <select
                            id="state"
                            name="state"
                            className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 outline-none ring-gray-300 focus:ring-1 focus:ring-blue-500 sm:text-sm sm:leading-6"
                        >
                            <option> Статус </option>
                            <option> Активных </option>
                            <option> На модерации </option>
                            <option> Не активных </option>
                            <option> Удалены </option>
                        </select>
                    </div> : ''   
            }

            {
                page === 'all' || page === 'broadcast' ?
                    <div className='mb-3'>
                        <DatePicker 
                            className='w-full py-2'
                            format={dateFormat}
                        />
                    </div> : ''   
            } */}

      <div className="mb-3 px-2 flex flex-col gap-y-2">
        <button
          type="button"
          className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full transition ease-in-out duration-300"
        >
          Применить
        </button>
        <button
          type="button"
          className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
        >
          Сбросить
        </button>
      </div>
    </div>
  );
}

export default ContractorFilter;
