import React from "react";
import FeedBacks from "../../components/FeedBacks/FeedBacks";
import { useNavigate } from "react-router-dom";

function DeletionIndex(props) {
  let navigate = useNavigate();

  const handleContractor = () => {
    if (props.response === undefined || props.response) {
      localStorage.setItem("contractorTabState", "all");
    } else {
      localStorage.setItem("contractorTabState", "applicant");
    }
    navigate("/detail");
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <section className="container relative flex flex-1 flex-col mx-auto max-w-[1163px]">
        <div className="vacancies-box flex flex-col p-1 w-full justify-center">
          <div className="flex flex-col zero:flex-row w-full justify-between items-start zero:items-center gap-4">
            <div className="vacancy-heading vacancy-heading-wrap flex flex-col">
              <h2 className="title text-3xl font-semibold">
                Вакансия “грузчик на склад срочно”
              </h2>
            </div>
            <button
              type="button"
              className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
            >
              Базовый (1000р.)
            </button>
          </div>
          <div className="flex flex-col mt-3">
            <div className="flex gap-3">
              <span className="vacancy__label text-gray-400 mt-2 cursor-pointer">
                ООО "Ромашка", ИНН 9083567289
              </span>
              <span
                className="vacancy__label text-gray-400 mt-2 underline cursor-pointer"
                onClick={handleContractor}
              >
                Open1123
              </span>
            </div>
          </div>
          <div
            className={`flex flex-col zero:flex-row mt-8 gap-4 ${
              props.response === undefined || props.response
                ? "justify-between"
                : "justify-center"
            }`}
          >
            <div className="vacancy-box w-full zero:w-[67%] shadow-xl rounded-md border-1 border-gray-300">
              <div className="vacancy-box-item p-4 flex items-center border-b-1 border-gray-200">
                <div className="vacancy-box-header flex flex-col gap-3 third:gap-0 third:flex-row third:justify-between third:items-center items-start w-full h-fit">
                  <div className="vacancy-box__text text-gray-900 text-xl">
                    <span>Адрес вакансии: </span>ООО Леруа мерлен г.Краснодар
                  </div>
                </div>
              </div>
              <div className="vacancy-box-item p-4 flex items-center border-b-1 border-gray-200">
                <div className="vacancy-box flex flex-wrap gap-4">
                  <div className="me-5 mb-2">
                    <div className="vacancy-box__desc text-lg text-gray-500">
                      <small>Оплата:</small>
                    </div>
                    <div className="vacancy-box__text text-gray-900">
                      1800 - 2500 руб./смена
                    </div>
                  </div>
                  <div className="me-5 mb-2">
                    <div className="text-lg text-gray-500 vacancy-box__desc  ">
                      <small>Периодичность выплат:</small>
                    </div>
                    <div className="vacancy-box__text text-gray-900">
                      1 раз в неделю
                    </div>
                  </div>
                  <div>
                    <div className="text-lg text-gray-500 vacancy-box__desc  ">
                      <small>Длительность смены:</small>
                    </div>
                    <div className="vacancy-box__text text-gray-900">
                      2-10 часов
                    </div>
                  </div>
                </div>
              </div>
              <div className="vacancy-box-item p-4 flex items-center border-b-1 border-gray-200">
                <div className="vacancy-box flex gap-4">
                  <div className="me-5 mb-2">
                    <div className="vasc text-lg text-gray-500">
                      <small>Пол:</small>
                    </div>
                    <div className="vacancy-box__text text-gray-900">
                      Мужской
                    </div>
                  </div>
                  <div>
                    <div className="vasc text-lg  text-gray-500">
                      <small>Возраст:</small>
                    </div>
                    <div className="vacancy-box__text text-gray-900">
                      20-30 лет
                    </div>
                  </div>
                </div>
              </div>
              <div className="vacancy-box-item p-4 flex flex-col items-start justify-center border-b-1 border-gray-200">
                <div className="vasc text-lg text-gray-500 mb-1">
                  <small>Описание вакансии:</small>
                </div>
                <div className="vacancy-box__text text-gray-900">
                  Здесь описание вакансии Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  e...
                </div>
              </div>
              <div className="vacancy-box-item p-4 flex flex-col items-start justify-center">
                <div className="vasc text-lg text-gray-500 mb-1">
                  <small>Требование к соискателю:</small>
                </div>
                <div className="vacancy-box__text text-gray-900">
                  Здесь описание вакансии Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                  e...
                </div>
              </div>
            </div>
            {props.response === undefined || props.response ? (
              <div className="w-full zero:w-[33%] mt-2 zero:-mt-10">
                <FeedBacks />
              </div>
            ) : (
              ""
            )}
          </div>
          <div
            className={`flex flex-col md:flex-row w-full lg:w-[67%] mt-4 gap-4 ${
              props.response === undefined || props.response
                ? "justify-start"
                : "justify-center"
            }`}
          >
            <button
              type="button"
              className=" text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
            >
              Восстановить
            </button>
            <button
              type="button"
              className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto bg-white transition ease-in-out duration-300"
            >
              Удалить безвозвратно
            </button>
          </div>
        </div>
      </section>
    </div>
  );
}

export default DeletionIndex;
