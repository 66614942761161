import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
// import { Link } from 'react-router-dom'

function MessagesList({ currentItems, check, currentState }) {
  let navigate = useNavigate();

  const [checkState, setCheckState] = useState(0);

  const handleCheck = (e) => {
    if (e.target.checked === true) {
      setCheckState(checkState + 1);
    } else setCheckState(checkState - 1);
  };

  const handleClick = (e) => {
    currentState === "manager"
      ? navigate("/managerChatPage")
      : navigate("/chatPage");
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Вы действительно хотите удалить сообщения?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Сообщения удалена!",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  return (
    <div className="flex flex-col gap-3">
      {checkState ? (
        <button
          type="button"
          className="self-end text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
          onClick={handleDelete}
        >
          Удалить
        </button>
      ) : (
        ""
      )}
      <div className="overflow-x-auto">
        <div className="min-w-full inline-block align-middle">
          <div className="overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200">
              <tbody className="divide-y divide-gray-200 items-center justify-center align-middle">
                {currentItems &&
                  currentItems.map((item) => (
                    <tr
                      key={item.key}
                      className="cursor-pointer transition duration-300 ease-in-out hover:bg-gray-100"
                    >
                      {check ? (
                        <td className="pr-2 py-3 whitespace-nowrap text-sm text-gray-800">
                          <input
                            name={item.num}
                            type="checkbox"
                            aria-checked={"false"}
                            onClick={handleCheck}
                            className="select_checkbox h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                          />
                        </td>
                      ) : (
                        ""
                      )}
                      <td
                        className="pz-2 py-3 whitespace-nowrap text-sm text-gray-800"
                        onClick={handleClick}
                      >
                        {item.sender_name}
                      </td>
                      <td
                        className="px-2 py-3 whitespace-nowrap text-sm text-gray-800"
                        onClick={handleClick}
                      >
                        {item.receiver_name}
                      </td>
                      <td
                        className="pl-2 py-3 whitespace-nowrap text-sm text-gray-800"
                        onClick={handleClick}
                      >
                        {item.date}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessagesList;
