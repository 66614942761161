import React, { useState, useEffect, Fragment } from "react";

import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import AscendingSvg from "../../Form/SortSvg/AscendingSvg";
import DescendingSvg from "../../Form/SortSvg/DescendingSvg";

import Swal from "sweetalert2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ApplicantList({
  currentItems,
  sortType,
  sortDirection,
  childSortData,
}) {
  const [checkState, setCheckState] = useState(0);

  const [sortTypeState, setSortTypeState] = useState(sortType);
  const [sortDirectionState, setSortDirectionState] = useState(sortDirection);

  useEffect(() => {
    setSortTypeState(sortType);
    setSortDirectionState(sortDirection);
  }, [sortType, sortDirection]);

  const handleSort = (e) => {
    if (e.target.id === sortTypeState) {
      setSortDirectionState(!sortDirectionState);
      childSortData(e.target.id, !sortDirectionState);
    } else {
      setSortTypeState(e.target.id);
      setSortDirectionState(true);
      childSortData(e.target.id, true);
    }
  };

  const handleCheck = (e) => {
    const tag = document.getElementById("select_all");
    if (e.target.name === "all") {
      const checkboxes = document.querySelectorAll(".select_checkbox");
      if (tag.checked) {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = true;
        });
      } else {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }

      if (e.target.checked === true) {
        setCheckState(checkboxes.length);
      } else setCheckState(0);
    } else {
      tag.checked = false;
      if (e.target.checked === true) {
        setCheckState(checkState + 1);
      } else setCheckState(checkState - 1);
    }
  };

  const handleApprove = () => {
    Swal.fire({
      title:
        "Вы действительно хотите переместить заявку в блок Без трансляции?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title:
            'Учетная запись контрагента перемещена в блок "заблокированные"',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handleBlock = () => {
    Swal.fire({
      title: "Вы действительно хотите заблокировать контрагента?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Заявка контрагента перемещена в блок Без трансляции",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th
            scope="col"
            className="pr-2 py-3 text-start text-[16px] font-medium uppercase"
          >
            <input
              id="select_all"
              name="all"
              type="checkbox"
              aria-checked={false}
              className="h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
              onClick={handleCheck}
            />
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-start text-[14px] font-medium hidden md:table-cell"
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="id"
              onClick={handleSort}
            >
              ID
              {sortTypeState === "id" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-start text-[14px] font-medium"
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="title"
              onClick={handleSort}
            >
              Наименование
              {sortTypeState === "title" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            scope="col"
            className="pl-2 py-1 text-start text-[14px] font-medium"
          >
            {checkState ? (
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center border-0 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                    Действия
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12px"
                      height="12px"
                      fill="currentColor"
                      className="bi bi-caret-down-fill self-center"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                    </svg>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={handleApprove}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Одобрить
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            onClick={handleBlock}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Заблокировать
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            ) : (
              ""
            )}
          </th>
          <th
            scope="col"
            className="pl-2 py-1 text-start text-[14px] font-medium"
          ></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 items-center">
        {currentItems &&
          currentItems.map((item) => (
            <tr key={item.key}>
              <td className="pr-2 py-4 whitespace-nowrap text-sm text-gray-800">
                <input
                  name={item.num}
                  type="checkbox"
                  aria-checked={"false"}
                  onClick={handleCheck}
                  className="select_checkbox h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                />
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800 hidden md:table-cell">
                {item.num}
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                <p className="block md:hidden">{item.num}</p>
                <Link to={`/detail`}>{item.name}</Link>
              </td>
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                <button
                  className="flex w-fit p-2 text-sm text-gray-800 bg-[#E5E5E5] border-1 border-[#C4C4C4] rounded-md"
                  onClick={handleApprove}
                >
                  Одобрить
                </button>
              </td>
              <td className="pl-2 py-2 whitespace-nowrap text-sm text-gray-800">
                <button
                  className="flex w-fit p-2 text-sm text-gray-800 bg-[#E5E5E5] border-1 border-[#C4C4C4] rounded-md"
                  onClick={handleBlock}
                >
                  Заблокировать
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default ApplicantList;
