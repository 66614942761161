import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';

let checkState = 0

function PerformerList({ currentItems, onData, buttonState }) {
    
    useEffect(() => {
        if (buttonState === 1) {
            const tag = document.getElementById('select_all');
            const checkboxes = document.querySelectorAll('.select_checkbox');
            tag.checked = false;
            checkboxes.forEach(checkbox => {
                checkbox.checked = false;
            });
            checkState = 0
            onData(checkState)
        }
    }, [buttonState, onData])

    const handleCheck = (e) => {
        const tag = document.getElementById('select_all');
        if (e.target.name === 'all') {
            const checkboxes = document.querySelectorAll('.select_checkbox');
            if (tag.checked) {
                checkboxes.forEach(checkbox => {
                    checkbox.checked = true;
                });
            } else {
                checkboxes.forEach(checkbox => {
                    checkbox.checked = false;
                });
            }

            if(e.target.checked === true) {
               checkState = checkboxes.length
            }
            else
                checkState = 0

        } else {
            tag.checked = false;
            if(e.target.checked === true) {
                checkState += 1
            }
            else
                checkState -= 1
        }
        onData(checkState)
    }

    return (
        <div className='flex w-full flex-col'>
            <div className='overflow-y-auto'>
                <div className="p-2 min-w-full inline-block align-middle">
                    <div className="border rounded-lg overflow-hidden justify-start">
                        <table className="min-w-full items-center divide-y divide-gray-200 mb-20">
                            <thead>
                                <tr className=''>
                                    <th scope="col" className=" py-3 text-start text-[16px] font-medium uppercase">
                                        <input
                                            id='select_all'
                                            name='all'
                                            type="checkbox"
                                            aria-checked={false}
                                            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                            onClick={handleCheck}
                                        />
                                    </th>
                                    <th scope="col" className=" py-3 text-start text-[16px] font-medium uppercase">
                                        №
                                    </th>
                                    <th scope="col" className=" py-3 text-start text-[16px] font-medium uppercase">
                                        ФИО
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 items-center justify-center align-middle">
                                {
                                    currentItems && 
                                    currentItems.map((item) => (
                                        <tr key={item.key}>
                                            <td className=' py-4 whitespace-nowrap text-sm text-gray-800'>
                                                <input
                                                    name={item.num}
                                                    type="checkbox"
                                                    aria-checked={'false'}
                                                    onClick={handleCheck}
                                                    className="select_checkbox h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                                />
                                            </td>
                                            <td className=' py-4 whitespace-nowrap text-sm text-gray-800'>
                                               {item.num} 
                                            </td>
                                            <td className=' py-4 whitespace-nowrap text-sm text-gray-800'>
                                                <Link to={`/profile`}>
                                                    {item.name}
                                                </Link>
                                            </td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PerformerList