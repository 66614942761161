import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FeedBacks from "../../components/FeedBacks/FeedBacks";
import DialogChatModal from "../../components/Modal/DialogChatModal";
import ProfileModal from "../../components/Modal/ProfileModal";
import BroadCastModal from "../../components/Modal/BroadCastModal";
import { Link } from "react-router-dom";

import Green from "../../components/Form/PCheckSvg/Green";
import Orange from "../../components/Form/PCheckSvg/Orange";
import Red from "../../components/Form/PCheckSvg/Red";
import GreenPay from "../../components/Form/PayCheckSvg/GreenPay";
import RedPay from "../../components/Form/PayCheckSvg/RedPay";

function InActivationIndex() {
  let navigate = useNavigate();

  const [broadcastModalState, setBroadcastModalState] = useState(false);
  const [chatModalState, setChatModalState] = useState(false);
  const [profileModalState, setProfileModalState] = useState(false);
  const [checkState, setCheckState] = useState("green");
  const [payCheckState, setPayCheckState] = useState("green");
  const [sendState, setSendState] = useState(false);

  const handleContractor = () => {
    localStorage.setItem("contractorTabState", "all");
    navigate("/detail");
  };

  const handleBroadcastModal = () => {
    setBroadcastModalState(!broadcastModalState);
  };

  // const handleProfileModal = () => {
  //     setProfileModalState(true)
  // }

  const handleCancelProfileModal = () => {
    setProfileModalState(false);
  };

  const handleDataFromProfileModalButton = () => {
    setProfileModalState(false);
    setChatModalState(true);
  };

  const handleChatModal = () => {
    setChatModalState(true);
  };

  const handleDataFromChatModal = () => {
    setProfileModalState(true);
    setChatModalState(false);
  };

  const handleCancelChatModal = () => {
    setChatModalState(false);
  };

  const handleSend = () => {
    setSendState(true);
  };

  const handleSendCancel = () => {
    setSendState(false);
  };

  const handleClose = () => {
    // navigate('/vacancy')
    navigate(-1);
  };

  const handleCheckState = () => {
    checkState === "green"
      ? setCheckState("orange")
      : checkState === "orange"
      ? setCheckState("red")
      : setCheckState("green");
  };

  const handlePayCheckState = () => {
    payCheckState === "green"
      ? setPayCheckState("red")
      : setPayCheckState("green");
  };

  const handleCancel = () => {
    // navigate('/vacancy')
    navigate(-1);
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <section className="container relative flex flex-1 flex-col mx-auto max-w-[1163px]">
        <div className="vacancies-box flex flex-col zero:flex-row p-1 gap-7 w-full justify-center">
          <div className="flex justify-start first:w-[67%] w-full first:p-1 p-4 flex-col">
            <div className="vacancy-heading vacancy-heading-wrap flex flex-col">
              <h2 className="title text-3xl font-semibold">
                Вакансия “грузчик на склад срочно”
              </h2>
              <div className="flex flex-col md:flex-row justify-between gap-3">
                <div className="flex gap-3">
                  <span className="vacancy__label text-gray-400 mt-2 cursor-pointer">
                    ООО "Ромашка", ИНН 9083567289
                  </span>
                  <span
                    className="vacancy__label text-gray-400 mt-2 underline cursor-pointer"
                    onClick={handleContractor}
                  >
                    Open1123
                  </span>
                </div>

                <button
                  type="button"
                  className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                  onClick={handleChatModal}
                >
                  Диалог
                </button>
              </div>
            </div>
            <div className="vacancy-wrap mt-4 border-1 rounded-md border-gray-200 shadow-md">
              <div className="vacancy-box shadow rounded">
                <div className="vacancy-box-item p-4 flex items-center border-b-1 border-gray-200">
                  <div className="vacancy-box-header flex flex-col gap-3 third:gap-0 third:flex-row third:justify-between third:items-center items-start w-full h-fit">
                    <div className="vacancy-box__text text-gray-900 text-xl">
                      <span>Адрес вакансии: </span>ООО Леруа мерлен г.Краснодар
                    </div>
                    {/* <div
                                            className='relative px-3 py-1.5 border rounded border-emerald-400 bg-emerald-400 text-white'
                                            role='alert'
                                        >
                                            {' '}
                                            Транслирование{' '}
                                        </div> */}
                  </div>
                </div>
                <div className="vacancy-box-item p-4 flex items-center border-b-1 border-gray-200">
                  <div className="vacancy-box flex flex-wrap gap-4">
                    <div className="me-5 mb-2">
                      <div className="vacancy-box__desc text-lg text-gray-500">
                        <small>Оплата:</small>
                      </div>
                      <div className="vacancy-box__text text-gray-900">
                        1800 - 2500 руб./смена
                      </div>
                    </div>
                    <div className="me-5 mb-2">
                      <div className="text-lg text-gray-500 vacancy-box__desc  ">
                        <small>Периодичность выплат:</small>
                      </div>
                      <div className="vacancy-box__text text-gray-900">
                        1 раз в неделю
                      </div>
                    </div>
                    <div>
                      <div className="text-lg text-gray-500 vacancy-box__desc  ">
                        <small>Длительность смены:</small>
                      </div>
                      <div className="vacancy-box__text text-gray-900">
                        2-10 часов
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vacancy-box-item p-4 flex items-center border-b-1 border-gray-200">
                  <div className="vacancy-box flex gap-4">
                    <div className="me-5 mb-2">
                      <div className="vasc text-lg text-gray-500">
                        <small>Пол:</small>
                      </div>
                      <div className="vacancy-box__text text-gray-900">
                        Мужской
                      </div>
                    </div>
                    <div>
                      <div className="vasc text-lg  text-gray-500">
                        <small>Возраст:</small>
                      </div>
                      <div className="vacancy-box__text text-gray-900">
                        20-30 лет
                      </div>
                    </div>
                  </div>
                </div>
                <div className="vacancy-box-item p-4 flex flex-col items-start justify-center border-b-1 border-gray-200">
                  <div className="vasc text-lg text-gray-500 mb-1">
                    <small>Описание вакансии:</small>
                  </div>
                  <div className="vacancy-box__text text-gray-900">
                    Здесь описание вакансии Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex e...
                  </div>
                </div>
                <div className="vacancy-box-item p-4 flex flex-col items-start justify-center">
                  <div className="vasc text-lg text-gray-500 mb-1">
                    <small>Требование к соискателю:</small>
                  </div>
                  <div className="vacancy-box__text text-gray-900">
                    Здесь описание вакансии Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi
                    ut aliquip ex e...
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex first:w-[33%] w-full flex-col">
            <div className="flex flex-col md:flex-row justify-end gap-3 px-4 md:px-0">
              <button
                type="button"
                className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto bg-white transition ease-in-out duration-300"
              >
                Стартовый
              </button>
              <button
                type="button"
                className=" text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
                onClick={handleBroadcastModal}
              >
                транслирование
              </button>
            </div>
            <div className="mt-7">
              <FeedBacks />
            </div>
          </div>
        </div>
        {!sendState ? (
          <div className="flex flex-col md:flex-row p-5 mb-2 gap-3 items-center">
            <Link to={`/editInActivation`} className="flex w-full md:w-fit">
              <button
                type="button"
                className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov w-full transition ease-in-out duration-300"
              >
                Редактировать
              </button>
            </Link>

            <button
              type="button"
              className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto bg-white transition ease-in-out duration-300"
              onClick={handleBroadcastModal}
            >
              Разместить
            </button>

            <button
              type="button"
              className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto bg-white transition ease-in-out duration-300"
              onClick={handleSend}
            >
              Отправить
            </button>

            <div className="flex items-center gap-3">
              <div className="cursor-pointer" onClick={handleCheckState}>
                {checkState === "green" ? (
                  <Green />
                ) : checkState === "orange" ? (
                  <Orange />
                ) : (
                  <Red />
                )}
              </div>

              <div className="cursor-pointer" onClick={handlePayCheckState}>
                {payCheckState === "green" ? <GreenPay /> : <RedPay />}
              </div>
            </div>

            <button
              type="button"
              className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto bg-white transition ease-in-out duration-300"
              onClick={handleCancel}
            >
              Отмена
            </button>
          </div>
        ) : (
          <div className="flex flex-col md:flex-row p-5 mb-2 gap-3 items-center">
            <button
              type="button"
              className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov w-full md:w-auto transition ease-in-out duration-300"
              onClick={handleSendCancel}
            >
              Отменить отправку
            </button>

            <button
              type="button"
              className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto bg-white transition ease-in-out duration-300"
              onClick={handleClose}
            >
              Закрыть
            </button>
          </div>
        )}
      </section>

      <BroadCastModal
        open={broadcastModalState}
        onClose={handleBroadcastModal}
      />

      <ProfileModal
        open={profileModalState}
        sendDataToParent={handleCancelProfileModal}
        sendDataToParentFromButton={handleDataFromProfileModalButton}
      />

      <DialogChatModal
        open={chatModalState}
        sendDataToParent={handleDataFromChatModal}
        onCancel={handleCancelChatModal}
      />
    </div>
  );
}

export default InActivationIndex;
