import React, { useState } from "react";

import { Link } from "react-router-dom";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import Select from "react-tailwindcss-select";

var check_state = 0;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function DeleteList({ actionState }) {
  const [stateLists, setActiveLists] = useState([
    {
      key: 1,
      num: 155,
      date: "15.02.24",
      jobName: "Грузчики на склад",
      company_name: "Газпром",
      tariff: 1,
      manager: {
        name: "Alexander",
        options: [
          { value: "fox", label: "Fox", num: 155 },
          { value: "Butterfly", label: "Butterfly", num: 155 },
          { value: "Honeybee", label: "Honeybee", num: 155 },
        ],
      },
      response: false,
    },
    {
      key: 2,
      num: 151,
      date: "13.02.24",
      jobName: "Горничная в отель",
      company_name: "Ямал СПГ",
      tariff: 2,
      manager: {
        name: "Sasha",
        options: [
          { value: "fox", label: "Fox", num: 151 },
          { value: "Butterfly", label: "Butterfly", num: 151 },
          { value: "Honeybee", label: "Honeybee", num: 151 },
        ],
      },
      response: true,
    },
  ]);

  let activeLists = [
    {
      key: 1,
      num: 155,
      date: "15.02.24",
      jobName: "Грузчики на склад",
      company_name: "Газпром",
      tariff: 1,
      manager: {
        name: "Alexander",
        options: [
          { value: "fox", label: "Fox", num: 155 },
          { value: "Butterfly", label: "Butterfly", num: 155 },
          { value: "Honeybee", label: "Honeybee", num: 155 },
        ],
      },
      response: false,
    },
    {
      key: 2,
      num: 151,
      date: "13.02.24",
      jobName: "Горничная в отель",
      company_name: "Ямал СПГ",
      tariff: 2,
      manager: {
        name: "Sasha",
        options: [
          { value: "fox", label: "Fox", num: 151 },
          { value: "Butterfly", label: "Butterfly", num: 151 },
          { value: "Honeybee", label: "Honeybee", num: 151 },
        ],
      },
      response: true,
    },
  ];

  const handleCheckChange = (e) => {
    if (e.target.checked === true) {
      check_state += 1;
    } else check_state -= 1;
    actionState(check_state, "delete");
  };

  const handleChange = (value) => {
    console.log(value);
    for (var i = 0; i < activeLists.length; i++) {
      if (activeLists[i].num + "" === value.num + "") {
        activeLists[i].manager.name = value.label;
        setActiveLists(activeLists);
      }
    }
  };

  const handleManagerChange = (e) => {
    console.log(e.target.name);
    for (var i = 0; i < activeLists.length; i++) {
      if (activeLists[i].num + "" === e.target.name + "") {
        activeLists[i].manager.name = "";
        setActiveLists(activeLists);
      }
    }
  };

  return (
    <div className="flex w-full flex-col">
      <div className="overflow-x-auto">
        <div className="p-2 min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden min-h-[300px]">
            <table className="min-w-full divide-y divide-gray-200 mb-20">
              <tbody className="divide-y divide-gray-200">
                {stateLists.map((list) => (
                  <tr key={list.key}>
                    <td className=" pr-4 py-2">
                      <div className="flex items-center h-5">
                        <input
                          id={list.num}
                          type="checkbox"
                          aria-checked={false}
                          onClick={handleCheckChange}
                          className="w-4 h-4 border-gray-200 rounded accent-button"
                        />
                      </div>
                    </td>
                    <td className=" px-4 py-2 hidden md:table-cell">
                      {list.num}-{list.date}
                    </td>
                    <td className=" px-4 py-2 min-w-48">
                      <p className="block md:hidden">
                        {list.num}-{list.date}
                      </p>
                      <p className="flex flex-row">
                        {list.response ? (
                          <Link to={`/deletion`}>{list.jobName}</Link>
                        ) : (
                          <Link to={`/deletionNoResponse`}>{list.jobName}</Link>
                        )}
                      </p>
                      <span className="flex text-gray-400">
                        {list.company_name}
                      </span>
                    </td>
                    <td className="py-2">{list.tariff}</td>
                    <td className="py-2">
                      {list.manager.name !== "" ? (
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center border-0 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                              {list.manager.name}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12px"
                                height="12px"
                                fill="currentColor"
                                className="bi bi-caret-down-fill self-center"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                              </svg>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      onClick={handleManagerChange}
                                      name={list.num}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      снять менеджера
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/`}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      профиль менеджера
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      ) : (
                        <Select
                          classNames={{
                            menuButton: ({ isDisabled }) =>
                              `flex justify-between pr-1.5 py-1 text-gray-900 text-sm ${
                                isDisabled
                                  ? "bg-gray-200"
                                  : "bg-white hover:border-gray-400"
                              }`,
                            menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                            listItem: ({ isSelected }) =>
                              `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                isSelected
                                  ? `text-white bg-blue-500`
                                  : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                              }`,
                            searchIcon: "hidden ",
                            searchBox:
                              "w-full text-sm text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                          }}
                          placeholder="manager"
                          value={list.manager.name}
                          onChange={handleChange}
                          options={list.manager.options}
                          isSearchable
                        />
                      )}
                    </td>
                    <td className="py-2 text-center">
                      {list.response ? (
                        <Link to={`/unprocessed`}>
                          <button className="text-center rounded py-1.5 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300">
                            отклики
                          </button>
                        </Link>
                      ) : (
                        "отсутствия откликов"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeleteList;
