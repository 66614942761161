import React from 'react'
import { useNavigate } from 'react-router-dom';

import {DatePicker} from 'antd'

function SelectionFilter(props) {
    let navigate = useNavigate();

    const handleApply = () => {
        navigate(-1);
    }

    const handleReset = () => {
        props.reset()
    }

    return (
        <div className='flex flex-col w-full p-4 rounded-md shadow-[0_0_3px_#C4C4C4]'>
            <div className='mb-6'>
                <p className='text-xl font-medium'>Фильтр</p>
            </div>
            <div className='mb-2'>
                <input
                    type="number"
                    name="number"
                    id="number"
                    min={1}
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                    placeholder='Порядковый номер'
                    required
                />
            </div>
            <div className='mb-2'>
                <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                    placeholder='ФИО'
                    required
                />
            </div>

            <div className='mb-2'>
                <select
                    id="gender"
                    name="gender"
                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 outline-none ring-gray-300 focus:ring-1 focus:ring-blue-500 sm:text-sm sm:leading-6"
                >
                    <option> Пол </option>
                    <option> Мужской </option>
                    <option> Женский </option>
                    <option> Неважно </option>
                </select>
            </div>

            <div className='flex flex-row mb-2 gap-x-2'>
                <div className="relative w-1/2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                        <span className="text-gray-500 sm:text-sm"> от</span>
                    </div>
                    <input
                        type="number"
                        name="min_date"
                        id="min_date"
                        min={1}
                        className="block w-full rounded-md border-0 py-2 pl-7 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <span className="text-gray-500 sm:text-sm">лет</span>
                    </div>
                </div>
                <div className="relative w-1/2 rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                        <span className="text-gray-500 sm:text-sm"> от</span>
                    </div>
                    <input
                        type="number"
                        name="max_date"
                        id="max_date"
                        className="block w-full rounded-md border-0 py-2 pl-7 pr-7 text-gray-900 ring-1 ring-inset ring-gray-300 outline-none placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                    />
                    <div className="absolute inset-y-0 right-0 flex items-center pr-2">
                        <span className="text-gray-500 sm:text-sm">лет</span>
                    </div>
                </div>
            </div>

            <div className='mb-2'>
                <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                        <input
                            id="photo"
                            name="photo"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        />
                    </div>
                    <div className="text-sm leading-6">
                        <label htmlFor="photo" className="font-medium text-gray-900">
                            Только с фото
                        </label>
                    </div>
                </div>
            </div>

            <div className='mb-2'>
                <DatePicker showTime size='large' className='w-full' />
            </div>

            <div className='mb-2'>
                <DatePicker showTime size='large' className='w-full' />
            </div>

            <div className='mb-2 px-2 flex flex-col gap-y-2'>
                <button
                    type='button'
                    className='text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300'
                >
                    Выбрать по фильтру
                </button>
                <button 
                    type='button'
                    className='text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full transition ease-in-out duration-300'
                    onClick={handleApply}
                >
                    Применить
                </button>
                <button
                    type='button'
                    className='text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300'
                    onClick={handleReset}
                >
                    Сбросить
                </button>
            </div>
        </div>
    )
}

export default SelectionFilter