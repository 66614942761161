import React, { useState, useEffect } from "react";

import ReactPaginate from "react-paginate";
import List from "../List/Contractor/ContractorList";

function ContractorPagination({ page, itemsPerPage }) {
  const [items, setItems] = useState([
    {
      key: 1,
      num: 153,
      name: "Куликова Александра Мироновна",
      state: "Активных",
      active: 2,
      activeHR: 3,
      moderation: 10,
      inactive: 8,
      delete: 5,
    },
    {
      key: 2,
      num: 151,
      name: "Андреева Василиса Матвеевна",
      state: "Активных",
      active: 3,
      activeHR: 9,
      moderation: 7,
      inactive: 9,
      delete: 5,
    },
    {
      key: 3,
      num: 150,
      name: "Пахомов Александр Романович",
      state: "Удалены",
      active: 5,
      activeHR: 7,
      moderation: 3,
      inactive: 4,
      delete: 5,
    },
    {
      key: 4,
      num: 149,
      name: "Крючкова Милана Артёмовна",
      state: "Не активных",
      active: 7,
      activeHR: 5,
      moderation: 10,
      inactive: 3,
      delete: 5,
    },
    {
      key: 5,
      num: 148,
      name: "Гусев Макар Артёмович",
      state: "На модерации",
      active: 5,
      activeHR: 3,
      moderation: 4,
      inactive: 5,
      delete: 5,
    },
    {
      key: 6,
      num: 147,
      name: "Егоров Лев Всеволодович",
      state: "Активных",
      active: 3,
      activeHR: 2,
      moderation: 6,
      inactive: 8,
      delete: 5,
    },
    {
      key: 7,
      num: 146,
      name: "Кулешова Василиса Фёдоровна",
      state: "Активных",
      active: 4,
      activeHR: 6,
      moderation: 9,
      inactive: 6,
      delete: 7,
    },
    {
      key: 8,
      num: 145,
      name: "Воронин Никита Фёдорович",
      state: "Активных",
      active: 6,
      activeHR: 9,
      moderation: 5,
      inactive: 5,
      delete: 2,
    },
    {
      key: 9,
      num: 144,
      name: "Муравьев Даниил Львович",
      state: "Активных",
      active: 9,
      activeHR: 7,
      moderation: 4,
      inactive: 8,
      delete: 1,
    },
  ]);

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const [itemOffset, setItemOffset] = useState(0);

  const [sortType, setSortType] = useState("id");
  const [sortDirection, setSortDirection] = useState(true);

  useEffect(() => {
    setSortType("id");
    setSortDirection(true);
  }, [page]);

  useEffect(() => {
    if (sortType === "id") {
      sortDirection !== true
        ? items.sort((a, b) => a.num - b.num)
        : items.sort((a, b) => a.num - b.num).reverse();
    } else if (sortType === "title") {
      sortDirection !== true
        ? items.sort((a, b) => a.name.localeCompare(b.name))
        : items.sort((a, b) => a.name.localeCompare(b.name)).reverse();
    } else if (sortType === "active") {
      sortDirection !== true
        ? items.sort((a, b) => a.active - b.active)
        : items.sort((a, b) => a.active - b.active).reverse();
    } else if (sortType === "activeHR") {
      sortDirection !== true
        ? items.sort((a, b) => a.active - b.active)
        : items.sort((a, b) => a.active - b.active).reverse();
    } else if (sortType === "moderation") {
      sortDirection !== true
        ? items.sort((a, b) => a.moderation - b.moderation)
        : items.sort((a, b) => a.moderation - b.moderation).reverse();
    } else if (sortType === "inactive") {
      sortDirection !== true
        ? items.sort((a, b) => a.inactive - b.inactive)
        : items.sort((a, b) => a.inactive - b.inactive).reverse();
    } else {
      sortDirection !== true
        ? items.sort((a, b) => a.delete - b.delete)
        : items.sort((a, b) => a.delete - b.delete).reverse();
    }
    setItems(items);
  }, [sortType, sortDirection, items]);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, items, itemsPerPage, sortType, sortDirection]);

  const childSortData = (childSortType, childSortDirection) => {
    setSortType(childSortType);
    setSortDirection(childSortDirection);
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <div className="w-full flex flex-col">
      <List
        currentItems={currentItems}
        page={page}
        sortType={sortType}
        sortDirection={sortDirection}
        childSortData={childSortData}
      />
      <div className="flex justify-center w-full mb-10">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </div>
  );
}

export default ContractorPagination;
