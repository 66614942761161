import React, { useState } from 'react'
import Avatar from '../../submodule/img/01.jpg';
import DialogChatModal from '../Modal/DialogChatModal';
import ProfileModal from '../Modal/ProfileModal';

function FeedBackMessage() {

    const [ chatModalState, setChatModalState ] = useState(false)
    const [ profileModalState, setProfileModalState ] = useState(false)

    const handleProfileModal = () => {
        setProfileModalState(true)
    }

    const handleDataFromProfileModal = () => {
        setProfileModalState(false)
    }

    const handleDataFromProfileModalButton = () => {
        setProfileModalState(false)
        setChatModalState(true)
    }

    const handleDataFromChatModal = () => {
        setProfileModalState(true)
        setChatModalState(false)
    }

    const handleCancelChatModal = () => {
        setChatModalState(false)
    }

    return (
        <>
            <li className='flex w-full mx-atuo customBottomBorder cursor-pointer mb-2 p-2' onClick={handleProfileModal}>
                <div className='flex justify-content'>
                    <img src={Avatar} className='w-[45px] h-[45px]' alt='avatar'/>
                </div>
                <div className='flex flex-col text-left text-[#212529] font-bold pl-3'>
                    <p>18:55 15.06.2022</p>
                    <p>Васильев Василий Васильевич</p>
                </div>
            </li>

            <ProfileModal 
                open={profileModalState} 
                sendDataToParent={handleDataFromProfileModal}
                sendDataToParentFromButton={handleDataFromProfileModalButton}
            />

            <DialogChatModal 
                open={chatModalState} 
                sendDataToParent={handleDataFromChatModal}
                onCancel={handleCancelChatModal}
            />

        </>
    )
}

export default FeedBackMessage