import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AscendingSvg from "../../Form/SortSvg/AscendingSvg";
import DescendingSvg from "../../Form/SortSvg/DescendingSvg";

function ListAll({ currentItems, sortType, sortDirection, childSortData }) {
  const [sortTypeState, setSortTypeState] = useState(sortType);
  const [sortDirectionState, setSortDirectionState] = useState(sortDirection);

  useEffect(() => {
    setSortTypeState(sortType);
    setSortDirectionState(sortDirection);
  }, [sortType, sortDirection]);

  const handleSort = (e) => {
    if (e.target.id === sortTypeState) {
      setSortDirectionState(!sortDirectionState);
      childSortData(e.target.id, !sortDirectionState);
    } else {
      setSortTypeState(e.target.id);
      setSortDirectionState(true);
      childSortData(e.target.id, true);
    }
  };

  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          {/* <th scope="col" className=" py-3 text-start text-[16px] font-medium uppercase">
                        <input
                            id='select_all'
                            name='all'
                            type="checkbox"
                            aria-checked={false}
                            className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                            // onClick={handleCheck}
                        />
                    </th> */}
          <th
            scope="col"
            className="pr-2 py-3 text-start text-[14px] font-medium hidden md:table-cell"
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="id"
              onClick={handleSort}
            >
              ID
              {sortTypeState === "id" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-start text-[14px] font-medium"
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="title"
              onClick={handleSort}
            >
              Наименование
              {sortTypeState === "title" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          {/* <th scope="col" className="px-2 py-3 text-start text-[14px] font-medium">
                        <div className='flex flex-row items-center gap-2 cursor-pointer' id='status' onClick={handleSort} >
                            Статус
                            {
                                sortTypeState === 'status' ? 
                                    sortDirectionState ?
                                        <AscendingSvg color="#000000" />
                                        :
                                        <DescendingSvg color="#000000" />
                                    : ''
                            }
                        </div>
                    </th> */}
          <th
            scope="col"
            className="px-2 py-3 text-center text-[14px] font-medium"
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="active"
              onClick={handleSort}
            >
              Активных
              {sortTypeState === "active" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-center text-[14px] font-medium"
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="activeHR"
              onClick={handleSort}
            >
              Активные (HR)
              {sortTypeState === "activeHR" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-center text-[14px] font-medium"
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="moderation"
              onClick={handleSort}
            >
              На модерации
              {sortTypeState === "moderation" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            scope="col"
            className="px-2 py-3 text-center text-[14px] font-medium"
            id="inactive"
            onClick={handleSort}
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="inactive"
              onClick={handleSort}
            >
              Не активных
              {sortTypeState === "inactive" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
          <th
            scope="col"
            className="pl-2 py-3 text-center text-[14px] font-medium"
            id="delete"
            onClick={handleSort}
          >
            <div
              className="flex flex-row items-center gap-2 cursor-pointer"
              id="delete"
              onClick={handleSort}
            >
              Удалены
              {sortTypeState === "delete" ? (
                sortDirectionState ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )
              ) : (
                ""
              )}
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 items-center justify-center align-middle">
        {currentItems &&
          currentItems.map((item) => (
            <tr key={item.key}>
              <td className="pr-2 py-4 whitespace-nowrap text-sm text-gray-800 hidden md:table-cell">
                {item.num}
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                <p className="block md:hidden">{item.num}</p>
                <Link to={`/detail`}>{item.name}</Link>
              </td>
              {/* <td className='px-2 py-4 whitespace-nowrap text-sm text-gray-800'>
                                { item.state }
                            </td> */}
              <td className="px-2 py-4 whitespace-nowrap text-center text-sm text-gray-800">
                {item.active}
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-center text-sm text-gray-800">
                {item.activeHR}
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-center text-sm text-gray-800">
                {item.moderation}
              </td>
              <td className="px-2 py-4 whitespace-nowrap text-center text-sm text-gray-800">
                {item.inactive}
              </td>
              <td className="pl-2 py-4 whitespace-nowrap text-center text-sm text-gray-800">
                {item.delete}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default ListAll;
