import React from 'react'
import UserList from '../../components/List/Manage/UserList';
import { Link } from 'react-router-dom';

const items = [
    {
        key: 1,
        name: 'Куликова Александра Мироновна',
    },
    {
        key: 2,
        name: 'Андреева Василиса Матвеевна',
    },
    {
        key: 3,
        name: 'Пахомов Александр Романович',
    },
    {
        key: 4,
        name: 'Крючкова Милана Артёмовна',
    },
    {
        key: 5,
        name: 'Гусев Макар Артёмович',
    }
];

function CounterPartyManage() {
    return (
        <div className='w-full flex flex-1 mt-10 p-1'>
            <div className='container relative flex flex-1 flex-col mx-auto max-w-[1163px]'>
                <div className='flex flex-col w-full gap-3'>
                    <div className='flex flex-row justify-between items-center align-middle mt-2'>
                        <label className='text-lg underline'>
                            Контрагенты
                        </label>
                        <Link to={`/addUser`}>
                            <button
                                type='button'
                                className='text-center border rounded py-2 px-4 mb-2 bg-button text-white hover:bg-button-hov mr-2 w-fit transition ease-in-out duration-300'
                            >
                                Добавить контрагента
                            </button>
                        </Link>
                    </div>
                    <div className='flex flex-col w-full p-4 border-1 border-[#C4C4C4] shadow-lg'>
                        <UserList currentItems={items} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CounterPartyManage