import React, { useState } from "react";
// import { Menu, Transition } from '@headlessui/react'
// import { Link } from 'react-router-dom'

import AscendingSvg from "../../components/Form/SortSvg/AscendingSvg";
import DescendingSvg from "../../components/Form/SortSvg/DescendingSvg";
import Pagination from "../../components/Pagination/MessagesPagination";
import MessagesFilter from "../../components/Filter/MessagesFilter";

const items = [
  {
    key: 1,
    sender_name: "ООО Магнит",
    receiver_name: "Никитина И.Ю",
    date: "18:55 18.05.22",
  },
  {
    key: 2,
    sender_name: "ИП Васильев Иван Иванович",
    receiver_name: "Андреева",
    date: "18:55 19.05.22",
  },
  {
    key: 3,
    sender_name: "ООО Магнит",
    receiver_name: "Андреева",
    date: "18:55 17.05.22",
  },
  {
    key: 4,
    sender_name: "ООО Название организаци",
    receiver_name: "Пахомов",
    date: "18:55 15.05.22",
  },
  {
    key: 5,
    sender_name: "ООО Магнит",
    receiver_name: "Пахомов",
    date: "18:55 14.05.22",
  },
  {
    key: 6,
    sender_name: "ООО Магнит",
    receiver_name: "Пахомов",
    date: "18:55 13.05.22",
  },
  {
    key: 7,
    sender_name: "ООО Магнит",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
  {
    key: 8,
    sender_name: "ООО Магнит",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
  {
    key: 9,
    sender_name: "ООО Магнит",
    receiver_name: "Крючкова",
    date: "18:55 18.05.22",
  },
];
items.sort((a, b) => a.date.localeCompare(b.date)).reverse();

// function classNames(...classes) {
//     return classes.filter(Boolean).join(' ')
// }

function CounterPartyToApplicantMessages() {
  // const [sortType, setSortType] = useState('name')
  const [sortDirection, setSortDirection] = useState(true);
  // const [sortDisplayName, setSortDisplayName] = useState('по ФИО')
  const [sortFlag, setSortFlag] = useState(0);

  const sort = (e) => {
    setSortDirection(!sortDirection);
    setSortFlag(sortFlag + 1);
    !sortDirection !== true
      ? items.sort((a, b) => a.date.localeCompare(b.date))
      : items.sort((a, b) => a.date.localeCompare(b.date)).reverse();

    // if (e.target.name === sortType) {
    //     setSortDirection(!sortDirection);
    // } else {
    //     setSortDirection(true);
    // }
    // if (e.target.name === 'date') {
    //     setSortType('date');
    //     setSortDisplayName('по дате');
    // } else if (e.target.name === 'vac_name') {
    //     setSortType('vac_name');
    //     setSortDisplayName('по рег данным');
    // } else if (e.target.name === 'pay') {
    //     setSortType('pay');
    //     setSortDisplayName('по статусу оплаты');
    // } else {
    //     setSortType('manager');
    //     setSortDisplayName('по менеджер');
    // }
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <div className="container relative flex flex-1 flex-col mx-auto max-w-[1163px]">
        <div className="flex flex-col lg:flex-row w-full gap-3">
          <div className="flex flex-col w-full lg:w-3/4 gap-3">
            <div className="flex flex-col sm:flex-row w-full justify-between items-start sm:items-center">
              <div className="flex items-baseline gap-x-3">
                <h2 className="text-xl sm:text-2xl font-normal">
                  Контрагенты/Соискатели
                </h2>
                {/* <label className='text-xs font-normal'>(18 новых)</label> */}
              </div>
              <div className="flex items-baseline pt-2 gap-x-2">
                <label className="text-base">Сортировать</label>
                <button
                  className="flex flex-row justify-between items-center gap-x-3 border-b-1 border-b-gray-700"
                  onClick={sort}
                >
                  по дате
                  {sortDirection ? (
                    <AscendingSvg color="#000000" />
                  ) : (
                    <DescendingSvg color="#000000" />
                  )}
                </button>
                {/* <Menu as="div" className="relative inline-block text-left">
                                    <div>
                                        <Menu.Button className="inline-flex w-full justify-center gap-x-3 bg-white text-sm font-semibold text-gray-900 shadow-sm border-b-1 border-b-gray-700 hover:bg-gray-50">
                                            {sortDisplayName}
                                            {
                                                sortDirection ?
                                                    <AscendingSvg color="#000000" />
                                                    :
                                                    <DescendingSvg color="#000000" />
                                            }
                                        </Menu.Button>
                                    </div>

                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            <div className="py-1">
                                                <Menu.Item>
                                                    {({ active }) => (
                                                        <Link
                                                            onClick={sort}
                                                            name='name'
                                                            className={classNames(
                                                                'flex flex-row justify-between items-center gap-x-3.5',
                                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                'block px-4 py-2 text-sm'

                                                            )}
                                                        >
                                                            по ФИО
                                                            {
                                                                sortType === 'name' ?
                                                                    sortDirection ?
                                                                        <AscendingSvg color="#000000" />
                                                                        :
                                                                        <DescendingSvg color="#000000" />
                                                                    : ''
                                                            }
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            </div>
                                        </Menu.Items>
                                    </Transition>
                                </Menu> */}
              </div>
            </div>
            <div className="flex flex-col w-full p-6 border-1 border-[#C4C4C4] shadow-lg rounded-md">
              <Pagination
                items={items}
                itemsPerPage={10}
                sortFlag={sortFlag}
                currentState="noManager"
              />
            </div>
          </div>
          <div className="flex flex-col w-full lg:w-1/4 lg:mt-12">
            <MessagesFilter type={3} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CounterPartyToApplicantMessages;
