import React from 'react'

function Red() {
    return (
        <svg 
            height="30px" 
            width="30px" 
            version="1.1" 
            id="Capa_1" 
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22.61 22.61"
            fill="#000000" 
            stroke="#000000" 
            strokeWidth="0.00022613"
        >
            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
            <g id="SVGRepo_iconCarrier"> 
                <g> 
                    <g> 
                        <path style={{fill:'#ff0000'}} d="M11.988,5.382c-1.969,0-3.559,1.815-3.559,4.057s1.59,4.058,3.559,4.058 c1.968,0,3.562-1.816,3.562-4.058S13.956,5.382,11.988,5.382z" />
                        <path style={{fill:'#ff0000'}} d="M20.715,0.001H1.9c-1.05,0-1.9,0.85-1.9,1.898v18.815c0,1.051,0.85,1.898,1.9,1.898h18.814 c1.049,0,1.899-0.848,1.899-1.898V1.899C22.614,0.851,21.764,0.001,20.715,0.001z M11.988,14.839 c-1.459,0-2.761-0.757-3.621-1.946v5.627H7.063V4.273h1.304v1.828c0.86-1.188,2.162-1.946,3.621-1.946 c2.588,0,4.691,2.392,4.691,5.343C16.68,12.447,14.577,14.839,11.988,14.839z" /> 
                    </g> 
                </g> 
            </g>
        </svg>
    )
}

export default Red
