import React, { Component } from 'react'

class DescendingSvg extends Component {
    constructor(props) {
        super(props);
        this.state = {
            color: props.color
        }
    }

    render(props) {
        return (
            <svg
                fill={this.state.color}
                height="15px"
                width="15px"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 301.219 301.219"
            >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                <g id="SVGRepo_iconCarrier">
                    <g>
                        <path d="M149.365,262.736H10c-5.523,0-10,4.477-10,10v10c0,5.523,4.477,10,10,10h139.365c5.522,0,10-4.477,10-10v-10 C159.365,267.213,154.888,262.736,149.365,262.736z"></path>
                        <path d="M10,229.736h120.586c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10 C0,225.259,4.477,229.736,10,229.736z"></path>
                        <path d="M10,166.736h101.805c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10 C0,162.259,4.477,166.736,10,166.736z"></path>
                        <path d="M10,96.736h83.025c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10 C0,92.259,4.477,96.736,10,96.736z"></path>
                        <path d="M10,33.736h64.244c5.522,0,10-4.477,10-10v-10c0-5.523-4.478-10-10-10H10c-5.523,0-10,4.477-10,10v10 C0,29.259,4.477,33.736,10,33.736z"></path>
                        <path d="M298.29,216.877l-7.07-7.071c-1.875-1.875-4.419-2.929-7.071-2.929c-2.652,0-5.195,1.054-7.071,2.929l-34.394,34.393 V18.736c0-5.523-4.477-10-10-10h-10c-5.522,0-10,4.477-10,10v225.462l-34.394-34.393c-1.876-1.876-4.419-2.929-7.071-2.929 c-2.652,0-5.196,1.054-7.071,2.929l-7.07,7.071c-3.905,3.905-3.905,10.237,0,14.142l63.535,63.536 c1.876,1.875,4.419,2.929,7.071,2.929c2.652,0,5.195-1.054,7.071-2.929l63.535-63.536 C302.195,227.113,302.195,220.782,298.29,216.877z"></path>
                    </g>
                </g>
            </svg>
        )
    }
}

export default DescendingSvg;