import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function UnProcessedFeedBackList({ currentItems, sendData, sendActionData }) {
  const [checkState, setCheckState] = useState(0);

  const handleClickWorkButton = (e) => {
    sendData(e.target.name);
  };

  const handleClickAllWorkButton = () => {
    const data = [];
    const tag = document.getElementById("select_all");
    const checkboxes = document.querySelectorAll(".select_checkbox");
    if (tag.checked) {
      data.state = "all";
    } else {
      data.state = "notAll";
      data.checkList = [];
      checkboxes.forEach((checkbox) => {
        if (checkbox.checked === true) {
          data.checkList.push(checkbox.name);
        }
      });
    }
    sendActionData(data);
  };

  const handleCheck = (e) => {
    const tag = document.getElementById("select_all");
    if (e.target.name === "all") {
      const checkboxes = document.querySelectorAll(".select_checkbox");
      if (tag.checked) {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = true;
        });
      } else {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }

      if (e.target.checked === true) {
        setCheckState(checkboxes.length);
      } else setCheckState(0);
    } else {
      tag.checked = false;
      if (e.target.checked === true) {
        setCheckState(checkState + 1);
      } else setCheckState(checkState - 1);
    }
  };

  const handleProcess = () => {
    Swal.fire({
      title: "Вы действительно переместить отклик в обработанные?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Отклик перемещен в обработанные",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Вы действительно хотите удалить отклик?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Отклик удален",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  return (
    <div className="flex flex-col w-full">
      <div className="overflow-y-auto">
        <div className="p-2 min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden justify-start">
            <table className="min-w-full items-center align-middle divide-y divide-gray-200 ">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="pr-2 py-3 text-start text-[16px] font-medium uppercase"
                  >
                    <input
                      id="select_all"
                      name="all"
                      type="checkbox"
                      aria-checked={false}
                      className="h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      onClick={handleCheck}
                    />
                  </th>
                  <th
                    scope="col"
                    className=" px-2 py-3 text-start text-[16px] font-medium uppercase"
                  >
                    №
                  </th>
                  <th
                    scope="col"
                    className=" px-2 py-3 text-start text-[16px] font-medium uppercase"
                  >
                    ФИО
                  </th>
                  <th>
                    {checkState ? (
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center border-0 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                            Действия
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12px"
                              height="12px"
                              fill="currentColor"
                              className="bi bi-caret-down-fill self-center"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                            </svg>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    onClick={handleClickAllWorkButton}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Взять в работу
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    onClick={handleProcess}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Обработать
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    onClick={handleDelete}
                                    className={classNames(
                                      active
                                        ? "bg-gray-100 text-gray-900"
                                        : "text-gray-700",
                                      "block px-4 py-2 text-sm"
                                    )}
                                  >
                                    Удалить
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      ""
                    )}
                  </th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className={`items-center align-middle`}>
                {currentItems &&
                  currentItems.map((item) => (
                    <tr key={item.key} className="">
                      <td className="pr-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        <input
                          name={item.num}
                          type="checkbox"
                          aria-checked={"false"}
                          onClick={handleCheck}
                          className="select_checkbox h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        />
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        {item.num}
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        <Link to={`/profile`}>{item.name}</Link>
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        {item.workButton === true ? (
                          <button
                            className={`flex w-fit px-2.5 py-1 rounded-md bg-green-500`}
                            name={item.num}
                            onClick={handleClickWorkButton}
                          >
                            Взято в работу
                          </button>
                        ) : (
                          <button
                            className={`flex w-fit px-2.5 py-1 rounded-md bg-gray-300`}
                            name={item.num}
                            onClick={handleClickWorkButton}
                          >
                            Взять в работу
                          </button>
                        )}
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center  gap-x-1.5 rounded-md  px-2.5 py-1 bg-gray-300 text-sm font-semibold text-gray-900">
                              Другие отклики (2)
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12px"
                                height="12px"
                                fill="currentColor"
                                className="bi bi-caret-down-fill self-center"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                              </svg>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/activationManager`}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      149 Грузчики на склад Срочно
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/activationManager`}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      256 Охранник
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/activationManager`}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      882 Уборщица
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        <button
                          className={`flex w-fit px-2.5 py-1 rounded-md bg-gray-300`}
                          onClick={handleProcess}
                        >
                          Обработать
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UnProcessedFeedBackList;
