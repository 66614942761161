import React, { Fragment, useState, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ContractorMenu({ sendDataToParent }) {
  let tab = localStorage.getItem("contractorTabState");

  const [active, setActive] = useState("all");

  useEffect(() => {
    if (tab === null) {
      localStorage.setItem("contractorTabState", "all");
      setActive("all");
    }
    setActive(tab);
  }, [tab]);

  const handleClick = (e) => {
    setActive(e.target.id);
    localStorage.setItem("contractorTabState", e.target.id);
    sendDataToParent(e.target.id);
  };

  const handleMobileTabList = (e) => {
    setActive(e.target.name);
    localStorage.setItem("contractorTabState", e.target.name);
    sendDataToParent(e.target.name);
  };

  return (
    <div className="flex w-full justify-between">
      <ul className="hidden md:flex list-none gap-3">
        <li
          className={`cursor-pointer ${
            active === "all" ? "font-bold underline" : ""
          }`}
          onClick={handleClick}
          id="all"
        >
          Все: 150
        </li>
        <li
          className={`cursor-pointer ${
            active === "broadcast" ? "font-bold underline" : ""
          }`}
          onClick={handleClick}
          id="broadcast"
        >
          Транслируемые: 30
        </li>
        <li
          className={`cursor-pointer ${
            active === "noBroadcast" ? "font-bold underline" : ""
          }`}
          onClick={handleClick}
          id="noBroadcast"
        >
          Без трансляции: 60
        </li>
        <li
          className={`cursor-pointer ${
            active === "applicant" ? "font-bold underline" : ""
          }`}
          onClick={handleClick}
          id="applicant"
        >
          Заявки: 30
        </li>
        <li
          className={`cursor-pointer ${
            active === "blocked" ? "font-bold underline" : ""
          }`}
          onClick={handleClick}
          id="blocked"
        >
          Заблокированные: 30
        </li>
      </ul>
      <div className="flex md:hidden">
        <Menu as="div" className="relative inline-block text-left">
          <div>
            <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              {active === "all"
                ? "Все: 150"
                : active === "broadcast"
                ? "Транслируемые: 30"
                : active === "noBroadcast"
                ? "Без трансляции: 60"
                : active === "applicant"
                ? "Заявки: 30"
                : "Заблокированные: 30"}
              <ChevronDownIcon
                className="-mr-1 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      name="all"
                      onClick={handleMobileTabList}
                    >
                      Все: 150
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      name="broadcast"
                      onClick={handleMobileTabList}
                    >
                      Транслируемые: 30
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      name="noBroadcast"
                      onClick={handleMobileTabList}
                    >
                      Без трансляции: 60
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      name="applicant"
                      onClick={handleMobileTabList}
                    >
                      Заявки: 30
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm"
                      )}
                      name="blocked"
                      onClick={handleMobileTabList}
                    >
                      Заблокированные: 30
                    </Link>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

export default ContractorMenu;
