import React, { useState, useEffect } from 'react'
import ListAll from './ListAll'
import BroadCastList from './BroadCastList'
import NoBroadCastList from './NoBroadCastList'
import ApplicantList from './ApplicantList'
import BlockedList from './BlockedList'

function ContractorList({ page, currentItems, sortType, sortDirection, childSortData }) {
    const [pageState, setPageState] = useState(page);

    useEffect(() => {
        setPageState(page)
    }, [page]);
    
    return (
        <div className='flex flex-col w-full'>
            <div className='overflow-y-auto'>
                <div className="p-2 min-w-full inline-block align-middle">
                    <div className='border rounded-lg overflow-hidden'>
                        {
                            pageState === 'all' ? 
                                <ListAll currentItems={currentItems} sortType={sortType} sortDirection={sortDirection} childSortData={childSortData} /> :
                                pageState === 'broadcast' ?
                                    <BroadCastList currentItems={currentItems} sortType={sortType} sortDirection={sortDirection} childSortData={childSortData} /> :
                                    pageState === 'noBroadcast' ?
                                        <NoBroadCastList currentItems={currentItems} sortType={sortType} sortDirection={sortDirection} childSortData={childSortData} /> :
                                        pageState === 'applicant' ?
                                        <ApplicantList currentItems={currentItems} sortType={sortType} sortDirection={sortDirection} childSortData={childSortData} />:
                                        <BlockedList currentItems={currentItems} sortType={sortType} sortDirection={sortDirection} childSortData={childSortData} />
                        }
                        {/* <ListAll currentItems={currentItems} /> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContractorList