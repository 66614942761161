import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

function Edit(props) {

    let navigate = useNavigate()

    const [name, setName] = useState('ООО Леруа мерлен')
    const [phone, setPhone] = useState('8999999999')
    const [email, setEmail] = useState('king85@opengamer.com')
    const [jobName, setJobName] = useState('грузчик на склад срочно')
    const [address, setAddress] = useState('Ульяновск, Ленина, 61')
    const [type, setType] = useState('1')
    const [description, setDescription] = useState('Здесь написано описание вакансии')
    const [requirement, setRequirement] = useState('Здесь указаны требования к соискателю')
    const [gender, setGender] = useState('1')
    const [minAge, setMinAge] = useState(19)
    const [maxAge, setMaxAge] = useState(35)
    const [payRate, setPayRate] = useState(1)
    const [minCost, setMinCost] = useState(1800)
    const [maxCost, setMaxCost] = useState(2200)
    const [minDuration, setMinDuration] = useState(2)
    const [maxDuration, setMaxDuration] = useState(16)
    const [manager, setManager] = useState(1)
    const [counterparty, setCounterparty] = useState(1)

    const handleChange = (e) => {
        var inputName = e.target.name
        var inputValue = e.target.value

        if ( inputName === 'name') {
            setName(inputValue)
        } else if (inputName === 'tel') {
            setPhone(inputValue)
        } else if (inputName === 'mail') {
            setEmail(inputValue)
        } else if (inputName === 'jobName') {
            setJobName(inputValue)
        } else if (inputName === 'address') {
            setAddress(inputValue)
        } else if (inputName === 'type') {
            setType(inputValue)
        } else if (inputName === 'desc') {
            setDescription(inputValue)
        } else if (inputName === 'reqr') {
            setRequirement(inputValue)
        } else if (inputName === 'gender') {
            setGender(inputValue)
        } else if (inputName === 'minAge') {
            setMinAge(inputValue)
        } else if (inputName === 'maxAge') {
            setMaxAge(inputValue)
        } else if (inputName === 'payRate') {
            setPayRate(inputValue)
        } else if (inputName === 'minCost') {
            setMinCost(inputValue)
        } else if (inputName === 'maxCost') {
            setMaxCost(inputValue)
        } else if (inputName === 'minDuration') {
            setMinDuration(inputValue)
        } else if (inputName === 'maxDuration') {
            setMaxDuration(inputValue)
        } else if (inputName === 'manager') {
            setManager(inputValue)
        } else if (inputName === 'counterparty') {
            setCounterparty(inputValue)
        }

    }

    const handleSave = () => {
        Swal.fire({
            title: "Изменения сохранены",
            icon: "success",
            showConfirmButton: false,
            timer: 1000
        }).then((result) => {
            if (result.isDismissed) {
                
                if (props.page === 'activation') {
                    navigate('/activation')
                } else {
                    navigate('/inactivation')
                }
            }
        });
    }

    const handleCancel = () => {
        if (props.page === 'activation') {
            navigate('/activation')
        } else {
            navigate('/inactivation')
        }
    }

    return (
        <div className='mx-auto flex flex-1 w-full p-2 max-w-3xl'>
            <div className='w-full mt-9'>
                <div className='flex flex-col justify-center items-center px-6 gap-1'>
                    <p className='text-2xl font-medium'>Неактивная вакансия Грузчик на склад</p>
                </div>

                <div className='flex flex-col w-full p-3 mt-4 gap-x-3'>
                    <div className='flex flex-col w-full p-6 mt-2 border-1 border-gray-200 rounded-xl shadow-sm transition-all'>
                        <div className='mt-6'>
                            <div className='flex flex-wrap zero:items-end'>
                                <label htmlFor='name' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Название организации
                                </label>
                            </div>
                            
                            <input
                                type='text'
                                name='name'
                                id='name'
                                className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                placeholder='ПАО Сбербанк'
                                onChange={handleChange}
                                value={name}
                            />
                        </div>

                        <div className='w-full flex flex-col third:flex-row justify-evenly gap-x-2'>
                            <div className='w-full third:w-1/2 mt-6'>
                                <div className='flex flex-wrap zero:items-end'>
                                    <label htmlFor='tel' className='block mb-2 text-sm font-bold text-gray-900'>
                                        Номер телефона 
                                    </label>
                                </div>
                                
                                <input
                                    type='text'
                                    name='tel'
                                    id='tel'
                                    className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                    placeholder='ПАО Сбербанк'
                                    onChange={handleChange}
                                    value={phone}
                                />
                            </div>
                            <div className='w-full third:w-1/2 mt-6'>
                                <div className='flex flex-wrap zero:items-end'>
                                    <label htmlFor='mail' className='block mb-2 text-sm font-bold text-gray-900'>
                                        E-mail 
                                    </label>
                                </div>
                                
                                <input
                                    type='email'
                                    name='mail'
                                    id='mail'
                                    className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                    placeholder='ПАО Сбербанк'
                                    onChange={handleChange}
                                    value={email}
                                />
                            </div>
                        </div>

                        <div className='mt-6'>
                            <div className='flex flex-wrap zero:items-end'>
                                <label htmlFor='jobName' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Название вакансии 
                                </label>
                            </div>
                            
                            <input
                                type='text'
                                name='jobName'
                                id='jobName'
                                className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                placeholder='ПАО Сбербанк'
                                onChange={handleChange}
                                value={jobName}
                            />
                        </div>

                        <div className='mt-6'>
                            <div className='flex flex-wrap zero:items-end'>
                                <label htmlFor='address' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Название вакансии 
                                </label>
                            </div>
                            
                            <input
                                type='text'
                                name='address'
                                id='address'
                                className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                placeholder='ПАО Сбербанк'
                                onChange={handleChange}
                                value={address}
                            />
                        </div>

                        <div className='mt-6 w-full third:w-1/2'>
                            <label htmlFor='type' className='block mb-2 text-sm font-bold text-gray-900'>
                                Тип вакансии 
                            </label>
                            <div className='relative'>
                                <select
                                    className='block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                    id='type'
                                    name='type'
                                    onChange={handleChange}
                                    defaultValue={type}
                                >
                                    <option value={1}>Постоянная работа</option>
                                    <option value={2}>Разовая работа</option>
                                    <option value={3}>Вахтовый метод</option>
                                </select>
                                <div className='pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700'>
                                    <svg
                                        className='fill-current h-4 w-4'
                                        xmlns='http://www.w3.org/2000/svg'
                                        viewBox='0 0 20 20'
                                    >
                                        <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                    </svg>
                                </div>
                            </div>
                        </div>

                        <div className='mt-6'>
                            <div className='flex zero:items-end'>
                                <label htmlFor='desc' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Описание вакансии
                                </label>
                            </div>
                            
                            <textarea
                                id='desc'
                                name='desc'
                                rows='4'
                                className='block text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                placeholder='' 
                                onChange={handleChange}
                                value={description}
                            >
                            </textarea>
                        </div>

                        <div className='mt-6'>
                            <div className='flex zero:items-end'>
                                <label htmlFor='reqr' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Требования к соискателю 
                                </label>
                            </div>
                            
                            <textarea
                                id='reqr'
                                name='reqr'
                                rows='4'
                                className='block text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                placeholder='' 
                                onChange={handleChange}
                                value={requirement}
                            >
                            </textarea>
                        </div>

                        <div className='flex flex-col third:flex-row gap-x-3'>
                            <div className='w-full third:w-1/3 mt-6'>
                                <label htmlFor='gender' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Пол 
                                </label>
                                <div className='relative'>
                                    <select
                                        className='block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                        id='gender'
                                        name='gender'
                                        onChange={handleChange}
                                        defaultValue={gender}
                                    >
                                        <option value={1}>Мужской</option>
                                        <option value={2}>Женский</option>
                                        <option value={3}>Неважно</option>
                                    </select>
                                    <div className='pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700'>
                                        <svg
                                            className='fill-current h-4 w-4'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 20 20'
                                        >
                                            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full third:w-1/3 mt-6'>
                                <label htmlFor='minAge' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Мин возраст  
                                </label>
                                <div className='relative'>
                                    <select
                                        className='block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                        id='minAge'
                                        name='minAge'
                                        onChange={handleChange}
                                        defaultValue={minAge}
                                    >
                                        <option value={19}>19</option>
                                        <option value={20}>20</option>
                                        <option value={21}>21</option>
                                    </select>
                                    <div className='pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700'>
                                        <svg
                                            className='fill-current h-4 w-4'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 20 20'
                                        >
                                            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full third:w-1/3 mt-6'>
                                <label htmlFor='maxAge' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Макс возраст  
                                </label>
                                <div className='relative'>
                                    <select
                                        className='block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                        id='maxAge'
                                        name='maxAge'
                                        onChange={handleChange}
                                        defaultValue={maxAge}
                                    >
                                        <option value={33}>33</option>
                                        <option value={34}>34</option>
                                        <option value={35}>35</option>
                                    </select>
                                    <div className='pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700'>
                                        <svg
                                            className='fill-current h-4 w-4'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 20 20'
                                        >
                                            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col third:flex-row gap-x-3'>
                            <div className='w-full third:w-1/3 mt-6'>
                                <label htmlFor='payRate' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Периодичность выплат  
                                </label>
                                <div className='relative'>
                                    <select
                                        className='block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                        id='payRate'
                                        name='payRate'
                                        onChange={handleChange}
                                        defaultValue={payRate}
                                    >
                                        <option value={1}>Ежедневные</option>
                                        <option value={2}>Еженежедельные</option>
                                        <option value={3}>Ежемесячные</option>
                                    </select>
                                    <div className='pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700'>
                                        <svg
                                            className='fill-current h-4 w-4'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 20 20'
                                        >
                                            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full third:w-1/3 mt-6'>
                                <div className='flex flex-wrap zero:items-end'>
                                    <label htmlFor='minCost' className='block mb-2 text-sm font-bold text-gray-900'>
                                        Мин стоимость раб. смены 
                                    </label>
                                </div>
                                
                                <input
                                    type='text'
                                    name='minCost'
                                    id='minCost'
                                    className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                    placeholder='ПАО Сбербанк'
                                    onChange={handleChange}
                                    value={minCost}
                                />
                            </div>

                            <div className='w-full third:w-1/3 mt-6'>
                                <div className='flex flex-wrap zero:items-end'>
                                    <label htmlFor='maxCost' className='block mb-2 text-sm font-bold text-gray-900'>
                                        Макс стоимость раб. смены
                                    </label>
                                </div>
                                
                                <input
                                    type='text'
                                    name='maxCost'
                                    id='maxCost'
                                    className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                    placeholder='ПАО Сбербанк'
                                    onChange={handleChange}
                                    value={maxCost}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col third:flex-row gap-x-3'>

                            <div className='w-full third:w-1/3 mt-6'>
                                <div className='flex flex-wrap zero:items-end'>
                                    <label htmlFor='minDuration' className='block mb-2 text-sm font-bold text-gray-900'>
                                        Мин. длит. раб. смены
                                    </label>
                                </div>
                                
                                <input
                                    type='text'
                                    name='minDuration'
                                    id='minDuration'
                                    className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                    placeholder='ПАО Сбербанк'
                                    onChange={handleChange}
                                    value={minDuration}
                                />
                            </div>

                            <div className='w-full third:w-1/3 mt-6'>
                                <div className='flex flex-wrap zero:items-end'>
                                    <label htmlFor='maxDuration' className='block mb-2 text-sm font-bold text-gray-900'>
                                        Макс стоимость раб. смены
                                    </label>
                                </div>
                                
                                <input
                                    type='text'
                                    name='maxDuration'
                                    id='maxDuration'
                                    className='block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                    placeholder='ПАО Сбербанк'
                                    onChange={handleChange}
                                    value={maxDuration}
                                />
                            </div>
                        </div>

                        <div className='flex flex-col third:flex-row gap-x-3'>
                            <div className='w-full third:w-1/3 mt-6'>
                                <label htmlFor='manager' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Менеджеры 
                                </label>
                                <div className='relative'>
                                    <select
                                        className='block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                        id='manager'
                                        name='manager'
                                        onChange={handleChange}
                                        defaultValue={manager}
                                    >
                                        <option value={1}>Иванов</option>
                                        <option value={2}>Васильев</option>
                                        <option value={3}>выбрать</option>
                                    </select>
                                    <div className='pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700'>
                                        <svg
                                            className='fill-current h-4 w-4'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 20 20'
                                        >
                                            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full third:w-1/3 mt-6'>
                                <label htmlFor='counterparty' className='block mb-2 text-sm font-bold text-gray-900'>
                                    Контрагенты  
                                </label>
                                <div className='relative'>
                                    <select
                                        className='block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                                        id='counterparty'
                                        name='counterparty'
                                        onChange={handleChange}
                                        defaultValue={counterparty}
                                    >
                                        <option value={1}>выбрать</option>
                                        <option value={2}>Васильев</option>
                                        <option value={3}>Иванов</option>
                                    </select>
                                    <div className='pointer-events-none absolute inset-y-0 right-1 flex items-center px-2 text-gray-700'>
                                        <svg
                                            className='fill-current h-4 w-4'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 20 20'
                                        >
                                            <path d='M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z' />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div className='flex flex-col middle:flex-row p-6 my-4 gap-x-2 items-center'>
                        <button
                            type='button'
                            className=' text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300'
                            onClick={handleSave}
                        >
                            Сохранить
                        </button>
                        
                        <button
                            type='button'
                            className='text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto bg-white transition ease-in-out duration-300'
                            onClick={handleCancel}
                        >
                            Отмена
                        </button>

                        {
                            props.page === 'activation' ?
                                ''
                                :
                                <input type="checkbox" className="w-4 h-4 border-gray-200 rounded accent-button" />
                        }
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default Edit