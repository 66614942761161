import React, { useState, useEffect } from "react";
import Avatar from "../../submodule/img/01.jpg";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import RoleModal from "../../components/Modal/RoleModal";

import Swal from "sweetalert2";
import ActiveList from "../../components/List/Contractor/ContractorDetail/ActiveList";
import ModerationList from "../../components/List/Contractor/ContractorDetail/ModerationList";
import InActiveList from "../../components/List/Contractor/ContractorDetail/InActiveList";
import DeleteList from "../../components/List/Contractor/ContractorDetail/DeleteList";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ContractorDetail() {
  let tab = localStorage.getItem("contractorTabState");

  const [active, setActive] = useState("active");
  const [info, setInfo] = useState(false);
  const [checkState, setCheckState] = useState(false);
  const [roleState, setRoleState] = useState(false);
  const [showVacState, setShowVacState] = useState();

  useEffect(() => {
    if (tab === "applicant") {
      setShowVacState(false);
    } else setShowVacState(true);
  }, [tab]);

  const handleClick = (e) => {
    setActive(e.target.id);
    // sendDataToParent(e.target.id);
  };

  const handleMobileTabList = (e) => {
    setActive(e.target.name);
  };

  const handleInfo = () => {
    setInfo(!info);
  };

  const handleDownload = () => {
    Swal.fire({
      title: 'Файл отправлен!"',
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });
  };

  const handleBlock = () => {
    Swal.fire({
      title: "Вы действительно хотите заблокировать контрагента?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title:
            'Учетная запись контрагента перемещена в блок "заблокированные"',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handleUnBlock = () => {
    Swal.fire({
      title: "Вы действительно хотите разблокировать контрагента?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: 'Учетная запись контрагента перемещена в блок "Не активные"',
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handleModal = () => {
    setRoleState(true);
  };

  const handleCancel = (data) => {
    setRoleState(data);
  };

  const handleActionState = (state, tab) => {
    setCheckState(state);
    if (tab) {
      setActive(tab);
    }
  };

  return (
    <div className="flex flex-1 flex-col w-full mx-auto max-w-[1163px]">
      <div className="mx-auto px-6 pt-6 flex flex-col first:flex-row w-full">
        <div className="flex w-full first:w-[20%] justify-center">
          <img
            src={Avatar}
            className="avatar avatar-md-sm rounded-sm w-[200px] h-[200px] p-5"
            alt="avatar"
          />
        </div>
        <div className="flex w-full first:w-[60%] p-3">
          <div className="flex flex-col justify-start gap-y-3 w-full">
            <p className="text-2xl font-normal">Страница контрагента 445132</p>
            <p className="text-base font-normal">
              Данные для входа: login/password
            </p>
            {/* <p className="text-base font-normal">Телефон: +7 999 999 99 99</p>
                        <p className="text-base font-normal">E-mail: не заполнено</p> */}
            <div className="flex gap-x-5 items-center">
              <button
                className="flex w-fit text-sm font-normal p-2 bg-[#E5E5E5] border-1 border-[#C4C4C4] rounded-[5px]"
                onClick={handleInfo}
              >
                Карточка организации
              </button>
              <button
                className="flex w-fit text-sm font-normal p-2 bg-[#E5E5E5] border-1 border-[#C4C4C4] rounded-[5px]"
                onClick={handleModal}
              >
                Доступ пользователя
              </button>
            </div>
            {info ? (
              <div className="flex flex-col w-full gap-y-1">
                <div
                  className="flex gap-x-1 items-center cursor-pointer"
                  onClick={handleDownload}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.4999 6.5C8.4999 6.36739 8.44722 6.24021 8.35345 6.14645C8.25969 6.05268 8.13251 6 7.9999 6C7.86729 6 7.74011 6.05268 7.64635 6.14645C7.55258 6.24021 7.4999 6.36739 7.4999 6.5V10.293L6.3539 9.146C6.30741 9.09951 6.25222 9.06264 6.19148 9.03748C6.13074 9.01232 6.06564 8.99937 5.9999 8.99937C5.93416 8.99937 5.86906 9.01232 5.80832 9.03748C5.74758 9.06264 5.69239 9.09951 5.6459 9.146C5.59941 9.19249 5.56254 9.24768 5.53738 9.30842C5.51222 9.36916 5.49927 9.43426 5.49927 9.5C5.49927 9.56574 5.51222 9.63084 5.53738 9.69158C5.56254 9.75232 5.59941 9.80751 5.6459 9.854L7.6459 11.854C7.69234 11.9006 7.74752 11.9375 7.80827 11.9627C7.86901 11.9879 7.93413 12.0009 7.9999 12.0009C8.06567 12.0009 8.13079 11.9879 8.19153 11.9627C8.25228 11.9375 8.30745 11.9006 8.3539 11.854L10.3539 9.854C10.4004 9.80751 10.4373 9.75232 10.4624 9.69158C10.4876 9.63084 10.5005 9.56574 10.5005 9.5C10.5005 9.43426 10.4876 9.36916 10.4624 9.30842C10.4373 9.24768 10.4004 9.19249 10.3539 9.146C10.3074 9.09951 10.2522 9.06264 10.1915 9.03748C10.1307 9.01232 10.0656 8.99937 9.9999 8.99937C9.93416 8.99937 9.86906 9.01232 9.80832 9.03748C9.74758 9.06264 9.69239 9.09951 9.6459 9.146L8.4999 10.293V6.5Z"
                      fill="black"
                    />
                    <path
                      d="M14 14V4.5L9.5 0H4C3.46957 0 2.96086 0.210714 2.58579 0.585786C2.21071 0.960859 2 1.46957 2 2V14C2 14.5304 2.21071 15.0391 2.58579 15.4142C2.96086 15.7893 3.46957 16 4 16H12C12.5304 16 13.0391 15.7893 13.4142 15.4142C13.7893 15.0391 14 14.5304 14 14ZM9.5 3C9.5 3.39782 9.65804 3.77936 9.93934 4.06066C10.2206 4.34196 10.6022 4.5 11 4.5H13V14C13 14.2652 12.8946 14.5196 12.7071 14.7071C12.5196 14.8946 12.2652 15 12 15H4C3.73478 15 3.48043 14.8946 3.29289 14.7071C3.10536 14.5196 3 14.2652 3 14V2C3 1.73478 3.10536 1.48043 3.29289 1.29289C3.48043 1.10536 3.73478 1 4 1H9.5V3Z"
                      fill="black"
                    />
                  </svg>
                  <span className="text-sm font-normal underline">
                    Выгрузить
                  </span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">Телефон</span>
                  <span className="flex break-all w-[50%]">
                    +7 999 999 99 99
                  </span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">E-mail</span>
                  <span className="flex break-all w-[50%]">не заполнено</span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">наименование</span>
                  <span className="flex break-all w-[50%]">ООО Магнит</span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">юр. адрес</span>
                  <span className="flex break-all w-[50%]">
                    Здесь полный адрес
                  </span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">факт. адрес</span>
                  <span className="flex break-all w-[50%]">
                    Здесь полный адрес
                  </span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">ИНН/КПП</span>
                  <span className="flex break-all w-[50%]">
                    848484684864684/45615313351
                  </span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">ОКПО</span>
                  <span className="flex break-all w-[50%]">8646448451</span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">ОКВЭД</span>
                  <span className="flex break-all w-[50%]">65484</span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">Р/С</span>
                  <span className="flex break-all w-[50%]">
                    468468464684684
                  </span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">
                    Корреспондентский счет банка
                  </span>
                  <span className="flex break-all w-[50%]">844643848</span>
                </div>
                <div className="flex w-full gap-2">
                  <span className="flex break-all w-[50%]">БИК Банка</span>
                  <span className="flex break-all w-[50%]">3543484</span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="flex items-center gap-x-2">
              <input
                type="checkbox"
                id="agree"
                className="accent-button text-sm w-4 h-4 mb-2"
              />
              <label
                htmlFor="agree"
                className="block mb-2 text-base text-gray-900"
              >
                согласие с правилами пользования
              </label>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col first:w-[20%] p-3">
          <div className="mb-4">
            <button
              type="button"
              className="text-center text-[14px] border rounded py-2 px-4 text-white  mr-2 w-full break-words p-2 xs:w-full hover:bg-[#069488] bg-[#00B2A4]"
            >
              Написать сообщение
            </button>
          </div>

          {tab === "applicant" ? (
            <div className="mb-4">
              <button
                type="button"
                className="text-center text-[14px] border-1 border-[#00B2A4] rounded py-2 px-4 text-[#00B2A4]  mr-2 w-full break-words p-2 xs:w-full hover:bg-[#00B2A4] hover:text-white bg-white"
              >
                Одобрить
              </button>
            </div>
          ) : (
            ""
          )}

          {tab !== "blocked" ? (
            <div className="mb-4">
              <button
                className="text-center text-[14px] py-2 px-4 mr-2 w-full break-words p-2 xs:w-full underline"
                onClick={handleBlock}
              >
                Заблокировать
              </button>
            </div>
          ) : (
            <div className="mb-4">
              <button
                className="text-center text-[14px] py-2 px-4 mr-2 w-full break-words p-2 xs:w-full underline"
                onClick={handleUnBlock}
              >
                Разблокировать
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="mx-auto px-6 flex flex-col first:flex-row w-full">
        <div className="flex w-full first:w-[20%] mb-2 justify-center"></div>
        <div className="flex flex-col w-full first:w-[80%] mb-2 px-3 gap-y-3">
          <label className="text-2xl font-normal ">
            Вакансий: {showVacState ? "9" : "0"}
          </label>
          <div className="flex w-full justify-between items-center">
            <ul className="hidden md:flex list-none gap-3">
              <li
                className={`cursor-pointer ${
                  active === "active" ? "font-bold " : "underline"
                }`}
                onClick={handleClick}
                id="active"
              >
                Активные {showVacState ? "(3)" : ""}
              </li>
              <li
                className={`cursor-pointer ${
                  active === "moderation" ? "font-bold " : "underline"
                }`}
                onClick={handleClick}
                id="moderation"
              >
                На модерации{showVacState ? "(1)" : ""}
              </li>
              <li
                className={`cursor-pointer ${
                  active === "inactive" ? "font-bold " : "underline"
                }`}
                onClick={handleClick}
                id="inactive"
              >
                Неактивные {showVacState ? "(2)" : ""}
              </li>
              <li
                className={`cursor-pointer ${
                  active === "delete" ? "font-bold " : "underline"
                }`}
                onClick={handleClick}
                id="delete"
              >
                Удаленные {showVacState ? "(2)" : ""}
              </li>
            </ul>
            <div className="flex md:hidden">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {active === "active"
                      ? "Активные"
                      : active === "moderation"
                      ? "На модерации"
                      : active === "inactive"
                      ? "Неактивные"
                      : "Удаленные"}
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="active"
                            onClick={handleMobileTabList}
                          >
                            Активные
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="moderation"
                            onClick={handleMobileTabList}
                          >
                            На модерации
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="inactive"
                            onClick={handleMobileTabList}
                          >
                            Неактивные
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="delete"
                            onClick={handleMobileTabList}
                          >
                            Удаленные
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            {checkState ? (
              active === "active" ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Действия
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Деактивировать
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Удалить
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : active === "moderation" ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Действия
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Согласовать
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Удалить
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : active === "inactive" ? (
                <Link
                  to={`/`}
                  className={
                    "inline-flex w-fit justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  }
                >
                  Удалить
                </Link>
              ) : (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Действия
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Восстановить
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Удалить безвозвратно
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )
            ) : (
              ""
            )}
          </div>
          <div className="flex flex-col items-start w-full mt-1 first:pr-10 pb-10">
            {showVacState ? (
              active === "active" ? (
                <ActiveList actionState={handleActionState} />
              ) : active === "moderation" ? (
                <ModerationList actionState={handleActionState} />
              ) : active === "inactive" ? (
                <InActiveList actionState={handleActionState} />
              ) : (
                <DeleteList actionState={handleActionState} />
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <RoleModal open={roleState} sendDataToParent={handleCancel} />
    </div>
  );
}

export default ContractorDetail;
