import React, { useState, useEffect } from 'react'
import WholeApplicantsList from './WholeApplicantsList';
import DeletedList from './DeletedList';
import BlockedList from './BlockedList';

function ApplicantsList({ page, currentItems, sendDataToParent }) {

    const [pageState, setPageState] = useState(page);

    useEffect(() => {
        setPageState(page)
    }, [page]);

    return (
        <div className='flex flex-col w-full'>
            <div className='overflow-y-auto'>
                <div className="p-2 min-w-full inline-block align-middle">
                    <div className='border rounded-lg overflow-hidden'>
                        {
                            pageState === 'all' ?
                                <WholeApplicantsList currentItems={currentItems} sendDataToParent={sendDataToParent} />
                                :
                                pageState === 'deleted' ?
                                    <DeletedList currentItems={currentItems} sendDataToParent={sendDataToParent} />
                                    :
                                    <BlockedList currentItems={currentItems} sendDataToParent={sendDataToParent} />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicantsList