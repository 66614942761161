import React from 'react'
import Avatar from '../../submodule/img/01.jpg';

function AddUser() {
    return (
        <div className='w-full flex flex-1 mt-10 p-1'>
            <div className='container relative flex flex-1 flex-col mx-auto max-w-[1163px]'>
                <div className='flex flex-col sm:flex-row w-full gap-3 '>
                    <div className='flex w-full sm:w-[20%] mb-2 justify-center'>
                        <img
                            src={Avatar}
                            className='avatar avatar-md-sm rounded-sm w-[200px] h-[200px] p-5'
                            alt='avatar'
                        />
                    </div>
                    <div className='flex flex-col w-full sm:w-[50%] mb-2 p-5 gap-3'>
                        <div className=''>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                                placeholder='ФИО'
                                required
                            />
                        </div>
                        <div className=''>
                            <input
                                type="text"
                                name="user"
                                id="user"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                                placeholder='логин'
                                required
                            />
                        </div>
                        <div className=''>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                                placeholder='пароль'
                                required
                            />
                        </div>
                        <div className=''>
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="role1"
                                        name="role1"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="role1" className="font-medium text-gray-900">
                                        Полномочие администратора
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="role2"
                                        name="role2"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="role2" className="font-medium text-gray-900">
                                        Полномочие администратора
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="role3"
                                        name="role3"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="role3" className="font-medium text-gray-900">
                                        Полномочие администратора
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className=''>
                            <div className="relative flex gap-x-3">
                                <div className="flex h-6 items-center">
                                    <input
                                        id="role4"
                                        name="role4"
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                                    />
                                </div>
                                <div className="text-sm leading-6">
                                    <label htmlFor="role4" className="font-medium text-gray-900">
                                        Полномочие администратора
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-row gap-3'>
                            <button
                                type='button'
                                className='text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300'
                            >
                                Сохранить
                            </button>
                            <button
                                type='button'
                                className='text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300'
                            >
                                Отмена
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser