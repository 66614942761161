import React, {useState, useEffect} from 'react'

import ReactPaginate from 'react-paginate'
import PerformerList from '../List/Performer/PerformerList';

const items = [
    {
        key: 1,
        num: 153,
        name: 'Куликова Александра Мироновна'
    },
    {
        key: 2,
        num: 151,
        name: 'Андреева Василиса Матвеевна'
    },
    {
        key: 3,
        num: 150,
        name: 'Пахомов Александр Романович'
    },
    {
        key: 4,
        num: 149,
        name: 'Крючкова Милана Артёмовна'
    },
    {
        key: 5,
        num: 148,
        name: 'Гусев Макар Артёмович'
    },
    {
        key: 6,
        num: 147,
        name: 'Егоров Лев Всеволодович'
    },
    {
        key: 7,
        num: 146,
        name: 'Кулешова Василиса Фёдоровна'
    },
    {
        key: 8,
        num: 145,
        name: 'Воронин Никита Фёдорович'
    },
    {
        key: 9,
        num: 144,
        name: 'Муравьев Даниил Львович'
    }
];

function PerformerPagination({ itemsPerPage, sortType, sortDirection, onData, buttonState }) {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
        // 'items' Array sort
        if (sortType === 'name') {
            sortDirection !== true ?
                items.sort((a, b) => a.name.localeCompare(b.name))
                :
                items.sort((a, b) => a.name.localeCompare(b.name)).reverse()
        } else if (sortType === 'reg') {
            sortDirection !== true ?
                items.sort((a, b) => a.regState - b.regState)
                :
                items.sort((a, b) => a.regState - b.regState).reverse()
        } else if (sortType === 'pay') {
            sortDirection !== true ?
                items.sort((a, b) => a.payState - b.payState)
                :
                items.sort((a, b) => a.payState - b.payState).reverse()
        } else {
            sortDirection !== true ?
                items.sort((a, b) => a.manager.name.localeCompare(b.manager.name))
                :
                items.sort((a, b) => a.manager.name.localeCompare(b.manager.name)).reverse()
        }


        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(items.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(items.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, sortType, sortDirection]);

    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % items.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setItemOffset(newOffset);
    };

    return (
        <>
            <PerformerList currentItems={currentItems} onData={onData} buttonState={buttonState} />
            <div className='flex justify-center'>
                <ReactPaginate
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel="<"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                />
            </div>
        </>
    )
}

export default PerformerPagination