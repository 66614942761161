import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";

import AscendingSvg from "../../components/Form/SortSvg/AscendingSvg";
import DescendingSvg from "../../components/Form/SortSvg/DescendingSvg";
import NotificationModal from "../../components/Modal/NotificationModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const data = [];

function ApplicantsMenu({ actionState, sendDataToParent, sendSortState }) {
  const [active, setActive] = useState("all");
  const [sortType, setSortType] = useState("name");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortDisplayName, setSortDisplayName] = useState("по ФИО");
  const [state, setState] = useState(actionState);
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    setState(actionState);
  }, [actionState]);

  const sort = (e) => {
    data.type = e.target.name;
    data.active = active;
    if (e.target.name === sortType) {
      setSortDirection(!sortDirection);
      data.direction = !sortDirection;
    } else {
      setSortDirection(true);
      data.direction = true;
    }
    if (e.target.name === "name") {
      setSortType("name");
      setSortDisplayName("По ФИО");
    } else if (e.target.name === "gender") {
      setSortType("gender");
      setSortDisplayName("По полу");
    } else if (e.target.name === "age") {
      setSortType("age");
      setSortDisplayName("По возрасту");
    } else if (e.target.name === "reg_date") {
      setSortType("reg_date");
      setSortDisplayName("По дате регистрации");
    } else {
    }
    sendSortState(data);
  };

  const handleClick = (e) => {
    setActive(e.target.id);
    sendDataToParent(e.target.id);
    setSortType("name");
    setSortDisplayName("По ФИО");
  };

  const handleMobileTabList = (e) => {
    setActive(e.target.name);
    sendDataToParent(e.target.name);
    setSortType("name");
    setSortDisplayName("По ФИО");
  };

  const handleNotification = () => {
    setNotification(true);
  };

  const handleCancelNotification = (data) => {
    setNotification(data);
  };

  const handleRestore = () => {
    Swal.fire({
      title: "Вы действительно хотите восстановить анкету соискателя?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Анкета восстановлена",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handlePermanentlyDelete = () => {
    Swal.fire({
      title: "Вы действительно хотите заблокировать анкету соискателя?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Анкета заблокированные",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  return (
    <div className="flex flex-col lg:flex-row w-full justify-between gap-3">
      <div className="flex justify-between lg:justify-start gap-3">
        <ul className="hidden lg:flex list-none gap-3 py-2">
          <li
            className={`cursor-pointer ${
              active === "all" ? "font-bold underline" : ""
            }`}
            onClick={handleClick}
            id="all"
          >
            Все: 150
          </li>
          <li
            className={`cursor-pointer ${
              active === "deleted" ? "font-bold underline" : ""
            }`}
            onClick={handleClick}
            id="deleted"
          >
            Удаленные: 50
          </li>
          <li
            className={`cursor-pointer ${
              active === "permanently" ? "font-bold underline" : ""
            }`}
            onClick={handleClick}
            id="permanently"
          >
            Заблокированные: 100
          </li>
        </ul>
        <div className="flex md:hidden">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                {active === "all"
                  ? "Все: 150"
                  : active === "deleted"
                  ? "Удаленные: 50"
                  : "Заблокированные: 100"}
                <ChevronDownIcon
                  className="-mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        name="all"
                        onClick={handleMobileTabList}
                      >
                        Все: 150
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        name="deleted"
                        onClick={handleMobileTabList}
                      >
                        Удаленные: 50
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        className={classNames(
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                        name="permanently"
                        onClick={handleMobileTabList}
                      >
                        Заблокированные: 100
                      </Link>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        {state ? (
          active === "all" ? (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Действия
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/`}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Удалить
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          onClick={handleNotification}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Уведомить
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : active === "deleted" ? (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Действия
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          onClick={handleRestore}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Восстановить
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          onClick={handlePermanentlyDelete}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Заблокировать
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          onClick={handleNotification}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Уведомить
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          ) : (
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                  Действия
                  <ChevronDownIcon
                    className="-mr-1 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to={`/`}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          разблокировать
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          onClick={handleNotification}
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "block px-4 py-2 text-sm"
                          )}
                        >
                          Уведомить
                        </Link>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          )
        ) : (
          ""
        )}
      </div>
      <div className="flex items-center gap-x-3 pr-4">
        <div className="flex flex-row items-center">
          <p className="px-2">Сортировать: </p>
          <Menu
            as="div"
            className="relative inline-block text-left self-center"
          >
            <div>
              <Menu.Button className="inline-flex w-full justify-center gap-x-3 bg-white text-sm font-semibold text-gray-900 shadow-sm border-b-1 border-b-gray-700 hover:bg-gray-50">
                {sortDisplayName}
                {sortDirection ? (
                  <AscendingSvg color="#000000" />
                ) : (
                  <DescendingSvg color="#000000" />
                )}
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        onClick={sort}
                        name="name"
                        className={classNames(
                          "flex flex-row justify-between items-center gap-x-3.5",
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        по ФИО
                        {sortType === "name" ? (
                          sortDirection ? (
                            <AscendingSvg color="#000000" />
                          ) : (
                            <DescendingSvg color="#000000" />
                          )
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        onClick={sort}
                        name="gender"
                        className={classNames(
                          "flex flex-row justify-between items-center gap-x-3.5",
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        По полу
                        {sortType === "gender" ? (
                          sortDirection ? (
                            <AscendingSvg color="#000000" />
                          ) : (
                            <DescendingSvg color="#000000" />
                          )
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        onClick={sort}
                        name="age"
                        className={classNames(
                          "flex flex-row justify-between items-center gap-x-3.5",
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        По возрасту
                        {sortType === "age" ? (
                          sortDirection ? (
                            <AscendingSvg color="#000000" />
                          ) : (
                            <DescendingSvg color="#000000" />
                          )
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        onClick={sort}
                        name="reg_date"
                        className={classNames(
                          "flex flex-row justify-between items-center gap-x-3.5",
                          active
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        По дате регистрации
                        {sortType === "reg_date" ? (
                          sortDirection ? (
                            <AscendingSvg color="#000000" />
                          ) : (
                            <DescendingSvg color="#000000" />
                          )
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                  </Menu.Item>
                  {/* <Menu.Item>
                                        {({ active }) => (
                                            <Link
                                                onClick={sort}
                                                name='name'
                                                className={classNames(
                                                    'flex flex-row justify-between items-center gap-x-3.5',
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                    
                                                )}
                                            >
                                                Сначала активные
                                                {
                                                    sortType === 'name' ?
                                                        sortDirection ?
                                                            <AscendingSvg color="#000000" />
                                                            :
                                                            <DescendingSvg color="#000000" />
                                                        : ''
                                                }
                                            </Link>
                                        )}
                                    </Menu.Item> */}
                  {/* <Menu.Item>
                                        {({ active }) => (
                                            <Link
                                                onClick={sort}
                                                name='name'
                                                className={classNames(
                                                    'flex flex-row justify-between items-center gap-x-3.5',
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                    
                                                )}
                                            >
                                                Сначала неактивные
                                                {
                                                    sortType === 'name' ?
                                                        sortDirection ?
                                                            <AscendingSvg color="#000000" />
                                                            :
                                                            <DescendingSvg color="#000000" />
                                                        : ''
                                                }
                                            </Link>
                                        )}
                                    </Menu.Item> */}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <NotificationModal
          open={notification}
          sendDataToParent={handleCancelNotification}
        />
      </div>
    </div>
  );
}

export default ApplicantsMenu;
