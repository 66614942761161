import React, { useState } from "react";
import Avatar from "../../submodule/img/01.jpg";

import { Upload } from "antd";

function EditUser() {
  const [editState, setEditState] = useState(false); // This is an EditState for role
  const [roleState, setRoleState] = useState(false);
  const [vacancyState, setVacancyState] = useState(false);
  const [userState, setUser] = useState("ivanov");
  const [passwordState, setPassword] = useState("iuhew9809w");
  const [fileList, setFileList] = useState([]);
  const [totalEditState, setTotalEditState] = useState(false);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleChange = (e) => {
    if (e.target.name === "user") {
      setUser(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const handleTotalEdit = () => {
    setTotalEditState(!totalEditState);
  };

  const handleEdit = () => {
    setEditState(true);
  };

  const handleCancel = () => {
    setEditState(false);
  };

  const handleRole = () => {
    setRoleState(!roleState);
  };

  const handleVacancyList = () => {
    setVacancyState(!vacancyState);
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <div className="container relative flex flex-1 flex-col mx-auto max-w-[1163px]">
        <div className="flex flex-col sm:flex-row w-full gap-3 ">
          <div className="flex w-full sm:w-[20%] mb-2 justify-center">
            {totalEditState ? (
              <div className="flex justify-center p-5">
                <Upload
                  action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                >
                  {fileList.length < 1 && "ВыгрузитьЬ"}
                </Upload>
              </div>
            ) : (
              <img
                src={Avatar}
                className="avatar avatar-md-sm rounded-sm w-[200px] h-[200px] p-5"
                alt="avatar"
              />
            )}
          </div>
          <div className="flex flex-col w-full sm:w-[50%] mb-2 p-5 gap-3">
            <div className="">
              <label className="text-lg">Иванов Иван Иванович</label>
            </div>
            <div className="">
              {totalEditState ? (
                <input
                  type="text"
                  name="user"
                  id="user"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="логин"
                  onChange={handleChange}
                  value={userState}
                />
              ) : (
                <input
                  type="text"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                  value={userState}
                  disabled
                />
              )}
            </div>
            <div className="">
              {totalEditState ? (
                <input
                  type="text"
                  name="password"
                  id="password"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                  placeholder="пароль"
                  onChange={handleChange}
                  value={passwordState}
                  required
                />
              ) : (
                <input
                  type="text"
                  className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                  value={passwordState}
                  disabled
                />
              )}
            </div>
            {!totalEditState ? (
              <button
                type="button"
                className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
                onClick={handleTotalEdit}
              >
                Pедактировать
              </button>
            ) : (
              <button
                type="button"
                className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
                onClick={handleTotalEdit}
              >
                Cохранить
              </button>
            )}
            <button
              type="button"
              className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
              onClick={handleRole}
            >
              Полномочия
            </button>
            {roleState ? (
              <>
                <div className="">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="role1"
                        name="role1"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="role1"
                        className="font-medium text-gray-900"
                      >
                        Полномочие администратора
                      </label>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="role2"
                        name="role2"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="role2"
                        className="font-medium text-gray-900"
                      >
                        Полномочие администратора
                      </label>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="role3"
                        name="role3"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="role3"
                        className="font-medium text-gray-900"
                      >
                        Полномочие администратора
                      </label>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="relative flex gap-x-3">
                    <div className="flex h-6 items-center">
                      <input
                        id="role4"
                        name="role4"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      />
                    </div>
                    <div className="text-sm leading-6">
                      <label
                        htmlFor="role4"
                        className="font-medium text-gray-900"
                      >
                        Полномочие администратора
                      </label>
                    </div>
                  </div>
                </div>
                {!editState ? (
                  <div className="flex flex-row gap-3">
                    <button
                      type="button"
                      className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
                      onClick={handleEdit}
                    >
                      Редактировать
                    </button>
                    <button
                      type="button"
                      className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                    >
                      Удалить
                    </button>
                  </div>
                ) : (
                  <div className="flex gap-3">
                    <button
                      type="button"
                      className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                      onClick={handleCancel}
                    >
                      Отмена
                    </button>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
            <button
              type="button"
              className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full md:w-auto transition ease-in-out duration-300"
              onClick={handleVacancyList}
            >
              Вакансии
            </button>
          </div>
        </div>
        {vacancyState ? (
          <div className="flex">
            <div className="hidden sm:block w-[20%]"></div>
            <div className="w-full sm:w-[55%] sm:px-8 overflow-x-auto">
              <div className="border-1 shadow-md p-4 min-w-full inline-block align-middle">
                <div className="overflow-hidden">
                  <table className="min-w-full divide-y divide-gray-200">
                    <tbody className="divide-y divide-gray-200 ">
                      <tr>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          209
                        </td>
                        <td className="flex flex-col px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          <p>Горничная в отель</p>
                          <p className="text-[#D9D9D9]">Nikolay</p>
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          HR 2
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          <button
                            type="button"
                            className="text-center border rounded py-2 px-2  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                          >
                            Отклики
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          210
                        </td>
                        <td className="flex flex-col px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          <p>Разнорабочий</p>
                          <p className="text-[#D9D9D9]">Annushuka</p>
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          разовый
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          <button
                            type="button"
                            className="text-center border rounded py-2 px-2  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                          >
                            Отклики
                          </button>
                        </td>
                      </tr>
                      <tr>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          216
                        </td>
                        <td className="flex flex-col px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          <p>Комплектовщик на склад</p>
                          <p className="text-[#D9D9D9]">Anna</p>
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          стартовый
                        </td>
                        <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-800">
                          <button
                            type="button"
                            className="text-center border rounded py-2 px-2  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                          >
                            Отклики
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default EditUser;
