import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import AscendingSvg from "../../components/Form/SortSvg/AscendingSvg";
import DescendingSvg from "../../components/Form/SortSvg/DescendingSvg";

import ActiveVacancyLists from "../../components/List/Vacancy/ActiveVacancyLists";
import ModerationVacancyLists from "../../components/List/Vacancy/ModerationVacancyLists";
import InActiveVacancyLists from "../../components/List/Vacancy/InActiveVacancyLists";
import DeleteVacancyLists from "../../components/List/Vacancy/DeleteVacancyLists";
import VacancyFilter from "../../components/Filter/VacancyFilter";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function VacancyIndex() {
  let tab = localStorage.getItem("tabState");

  const [sortType, setSortType] = useState("date");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortDisplayName, setSortDisplayName] = useState("по дате");
  const [actionState, setActionState] = useState(0);
  const [tabState, setTabState] = useState("");

  useEffect(() => {
    if (tab === null) {
      localStorage.setItem("tabState", "active");
      setTabState("active");
    }
    setTabState(tab);
  }, [tab]);

  const sort = (e) => {
    if (e.target.name === sortType) {
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
    }

    if (e.target.name === "date") {
      setSortType("date");
      setSortDisplayName("по дате");
    } else if (e.target.name === "reg") {
      setSortType("reg");
      setSortDisplayName("по рег данным");
    } else if (e.target.name === "pay") {
      setSortType("pay");
      setSortDisplayName("по статусу оплаты");
    } else {
      setSortType("manager");
      setSortDisplayName("по менеджер");
    }
  };

  const handleActionState = (state, tab) => {
    setActionState(state);
    if (tab) {
      setTabState(tab);
      localStorage.setItem("tabState", tab);
    }
  };

  const handleTabList = (e) => {
    setActionState(0);
    setTabState(e.target.id);
    localStorage.setItem("tabState", e.target.id);
  };

  const handleMobileTabList = (e) => {
    setTabState(e.target.name);
    localStorage.setItem("tabState", e.target.name);
  };

  return (
    <div className="mx-auto flex flex-1 flex-col w-full max-w-[1163px]">
      <div className="w-full mt-6 p-2">
        <div className="flex flex-row justify-between gap-1">
          <p className="text-2xl font-medium">Вакансии</p>
          <p className="flex flex-wrap justify-center items-center">
            <Link to={`/create`}>
              <button
                type="button"
                className="text-center border rounded py-2 px-2 text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
              >
                Создать вакансию
              </button>
            </Link>
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col first:flex-row zero:justify-evenly">
        <div className="flex w-full flex-col zero:w-[70%] p-2">
          <div className="flex justify-between items-center">
            <ul className="hidden md:flex list-none gap-x-3 border-b border-gray-200 justify-center items-baseline">
              <li
                className={`cursor-pointer hover:text-[#00B2A4] pb-1 ${
                  tabState === "active"
                    ? "font-bold text-[#00B2A4]"
                    : "border-b-1 border-[#00B2A4] border-dotted"
                }`}
                id="active"
                onClick={handleTabList}
              >
                Активные (2)
              </li>
              <li
                className={`cursor-pointer hover:text-[#00B2A4] pb-1 ${
                  tabState === "moderation"
                    ? "font-bold text-[#00B2A4]"
                    : "border-b-1 border-[#00B2A4] border-dotted"
                }`}
                id="moderation"
                onClick={handleTabList}
              >
                На модерации (12)
              </li>
              <li
                className={`cursor-pointer hover:text-[#00B2A4] pb-1 ${
                  tabState === "inactive"
                    ? "font-bold text-[#00B2A4]"
                    : "border-b-1 border-[#00B2A4] border-dotted"
                }`}
                id="inactive"
                onClick={handleTabList}
              >
                Неактивные (155)
              </li>
              <li
                className={`cursor-pointer hover:text-[#00B2A4] pb-1 ${
                  tabState === "delete"
                    ? "font-bold text-[#00B2A4]"
                    : "border-b-1 border-[#00B2A4] border-dotted"
                }`}
                id="delete"
                onClick={handleTabList}
              >
                Удаленные (3)
              </li>
            </ul>
            <div className="flex md:hidden">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                    {tabState === "active"
                      ? "Активные (2)"
                      : tabState === "moderation"
                      ? "На модерации (12)"
                      : tabState === "inactive"
                      ? "Неактивные (155)"
                      : "Удаленные (3)"}
                    <ChevronDownIcon
                      className="-mr-1 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="active"
                            onClick={handleMobileTabList}
                          >
                            Активные (2)
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="moderation"
                            onClick={handleMobileTabList}
                          >
                            На модерации (12)
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="inactive"
                            onClick={handleMobileTabList}
                          >
                            Неактивные (155)
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                            name="delete"
                            onClick={handleMobileTabList}
                          >
                            Удаленные (3)
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            <div className="hs-dropdown relative inline-flex [--strategy:absolute]">
              <button
                id="hs-dropdown-right-but-left-on-lg-custom"
                type="button"
                className="hs-dropdown-toggle flex items-center gap-x-2 text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov w-fit transition ease-in-out duration-300"
              >
                {sortDisplayName}
                {sortDirection ? (
                  <AscendingSvg color="#ffffff" />
                ) : (
                  <DescendingSvg color="#ffffff" />
                )}
              </button>

              <div
                className="hs-dropdown-menu w-60 transition-[opacity,margin] duration hs-dropdown-open:opacity-100 opacity-0 hidden z-10 top-0 end-0 start-auto min-w-[10rem] bg-white shadow-md rounded-lg p-2 mt-2 "
                aria-labelledby="hs-dropdown-right-but-left-on-lg-custom"
              >
                <Link
                  onClick={sort}
                  name="date"
                  className="flex flex-row justify-between items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                >
                  по дате
                  {sortType === "date" ? (
                    sortDirection ? (
                      <AscendingSvg color="#000000" />
                    ) : (
                      <DescendingSvg color="#000000" />
                    )
                  ) : (
                    ""
                  )}
                </Link>
                <Link
                  onClick={sort}
                  name="reg"
                  className="flex flex-row justify-between items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                >
                  по рег данным
                  {sortType === "reg" ? (
                    sortDirection ? (
                      <AscendingSvg color="#000000" />
                    ) : (
                      <DescendingSvg color="#000000" />
                    )
                  ) : (
                    ""
                  )}
                </Link>
                <Link
                  onClick={sort}
                  name="pay"
                  className="flex flex-row justify-between items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                >
                  по статусу оплаты
                  {sortType === "pay" ? (
                    sortDirection ? (
                      <AscendingSvg color="#000000" />
                    ) : (
                      <DescendingSvg color="#000000" />
                    )
                  ) : (
                    ""
                  )}
                </Link>
                <Link
                  onClick={sort}
                  name="manager"
                  className="flex flex-row justify-between items-center gap-x-3.5 py-2 px-3 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100"
                >
                  по менеджер
                  {sortType === "manager" ? (
                    sortDirection ? (
                      <AscendingSvg color="#000000" />
                    ) : (
                      <DescendingSvg color="#000000" />
                    )
                  ) : (
                    ""
                  )}
                </Link>
              </div>
            </div>
          </div>
          {actionState ? (
            <div className="flex justify-end mt-4">
              {tabState === "active" ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Действия
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Деактивировать
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Удалить
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : tabState === "moderation" ? (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Действия
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Согласовать
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Удалить
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              ) : tabState === "inactive" ? (
                <Link
                  to={`/`}
                  className={
                    "inline-flex w-fit justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                  }
                >
                  Удалить
                </Link>
              ) : (
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                      Действия
                      <ChevronDownIcon
                        className="-mr-1 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Восстановить
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to={`/`}
                              className={classNames(
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              Удалить безвозвратно
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              )}
            </div>
          ) : (
            <></>
          )}

          {/* tab content */}
          <div className="mt-3">
            {tabState === "active" ? (
              <ActiveVacancyLists
                sortType={sortType}
                sortDirection={sortDirection}
                actionState={handleActionState}
              />
            ) : tabState === "moderation" ? (
              <ModerationVacancyLists
                sortType={sortType}
                sortDirection={sortDirection}
                actionState={handleActionState}
              />
            ) : tabState === "inactive" ? (
              <InActiveVacancyLists
                sortType={sortType}
                sortDirection={sortDirection}
                actionState={handleActionState}
              />
            ) : (
              <DeleteVacancyLists
                sortType={sortType}
                sortDirection={sortDirection}
                actionState={handleActionState}
              />
            )}
          </div>
        </div>
        <div className="hidden justify-start w-[30%] first:flex zero:p-1 xs:p-4 flex-col">
          <VacancyFilter />
        </div>
      </div>
    </div>
  );
}

export default VacancyIndex;
