import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';

import { Link } from 'react-router-dom';

import ProfileModal from '../../components/Modal/ProfileModal';
import DialogChatModal from '../../components/Modal/DialogChatModal';
import BroadCastModal from '../../components/Modal/BroadCastModal';
import Select from 'react-tailwindcss-select';
import Swal from 'sweetalert2';

import Green from '../../components/Form/PCheckSvg/Green';
import Orange from '../../components/Form/PCheckSvg/Orange';
import Red from '../../components/Form/PCheckSvg/Red';
import GreenPay from '../../components/Form/PayCheckSvg/GreenPay';
import RedPay from '../../components/Form/PayCheckSvg/RedPay';

const managerOptions = [
  { value: 'fox', label: 'Fox' },
  { value: 'Butterfly', label: 'Butterfly' },
  { value: 'Honeybee', label: 'Honeybee' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function Moderation(props) {
  let navigate = useNavigate();
  const { id } = useParams();

  const [item, setItem] = useState([]);

  const [profileModalState, setProfileModalState] = useState(false);
  const [chatModalState, setChatModalState] = useState(false);

  const [editState, setEditState] = useState(false);

  const [historyState, setHistoryState] = useState(false);
  const [managerName, setManagerName] = useState('Ivan');

  const [flagModerationVisible, setFlagModerationVisible] = useState(false);

  const [sendButtonState, setSendButtonState] = useState(false);
  const [approveButtonState, setApproveButtonState] = useState(false);
  const [postButtonState, setPostButtonState] = useState(false);
  const [regButtonState, setRegButtonState] = useState(false);
  const [payButtonState, setPayBUttonState] = useState(false);
  const [cancelButtonState, setCancelButtonState] = useState(false);

  const [regCheckState, setRegCheckState] = useState('green');
  const [payCheckState, setPayCheckState] = useState('green');

  const [modalState, setModalState] = useState(false);

  const [inputName, setInputName] = useState('');
  const [inputVac, setInputVac] = useState('');
  const [inputAdd, setInputAdd] = useState('');
  const [inputDesc, setInputDesc] = useState('');
  const [inputReqr, setInputReqr] = useState('');
  const [inputMinAge, setInputMinAge] = useState('');
  const [inputMaxAge, setInputMaxAge] = useState('');
  const [inputMinPay, setInputMinPay] = useState('');
  const [inputMaxPay, setInputMaxPay] = useState('');

  useEffect(() => {
    let activeLists = [
      {
        key: 1,
        num: 153,
        date: '15.01.24',
        state: 0,
        jobName: 'Охранник в ТЦ',
        jobPosterName: 'Яндекс',
        regState: 1,
        type: 'стартовый',
        payState: 1,
        manager: {
          name: 'Maria',
          options: [
            { value: 'fox', label: 'Fox', num: 153 },
            { value: 'Butterfly', label: 'Butterfly', num: 153 },
            { value: 'Honeybee', label: 'Honeybee', num: 153 },
          ],
        },
        vacState: 0,
      },
      {
        key: 2,
        num: 151,
        date: '12.01.24',
        state: 1,
        jobName: 'Горничная в отель',
        jobPosterName: 'Арктик СПГ 2',
        regState: 1,
        type: 'разовый',
        payState: 0,
        manager: {
          name: 'Ivan',
          options: [
            { value: 'fox', label: 'Fox', num: 151 },
            { value: 'Butterfly', label: 'Butterfly', num: 151 },
            { value: 'Honeybee', label: 'Honeybee', num: 151 },
          ],
        },
        vacState: 1,
      },
      {
        key: 3,
        num: 150,
        date: '12.01.25',
        state: 2,
        jobName: 'Разнорабочий',
        jobPosterName: 'X5 Group',
        regState: 1,
        type: 'HR 1',
        payState: 0,
        manager: {
          name: 'Natalia',
          options: [
            { value: 'fox', label: 'Fox', num: 150 },
            { value: 'Butterfly', label: 'Butterfly', num: 150 },
            { value: 'Honeybee', label: 'Honeybee', num: 150 },
          ],
        },
        vacState: 3,
      },
      {
        key: 4,
        num: 149,
        date: '13.01.24',
        state: 3,
        jobName: 'Комплектовщик на склад',
        jobPosterName: 'Мегафон',
        regState: 1,
        type: 'HR 2',
        payState: 0,
        manager: {
          name: 'Ekaterina',
          options: [
            { value: 'fox', label: 'Fox', num: 149 },
            { value: 'Butterfly', label: 'Butterfly', num: 149 },
            { value: 'Honeybee', label: 'Honeybee', num: 149 },
          ],
        },
        vacState: 7,
      },
      {
        key: 5,
        num: 148,
        date: '10.01.24',
        state: 3,
        jobName: 'Повар в ресторан',
        jobPosterName: 'Федеральная грузовая компания',
        regState: 1,
        type: 'HR 3',
        payState: 1,
        manager: {
          name: 'Anna',
          options: [
            { value: 'fox', label: 'Fox', num: 148 },
            { value: 'Butterfly', label: 'Butterfly', num: 148 },
            { value: 'Honeybee', label: 'Honeybee', num: 148 },
          ],
        },
        vacState: 4,
      },
      {
        key: 6,
        num: 147,
        date: '09.01.24',
        state: 1,
        jobName: 'Копирайтер',
        jobPosterName: 'Каспийский трубопроводный консорциум - Р',
        regState: 1,
        type: 'разовый',
        payState: 0,
        manager: {
          name: 'Anastasia',
          options: [
            { value: 'fox', label: 'Fox', num: 147 },
            { value: 'Butterfly', label: 'Butterfly', num: 147 },
            { value: 'Honeybee', label: 'Honeybee', num: 147 },
          ],
        },
        vacState: 5,
      },
      {
        key: 7,
        num: 146,
        date: '07.01.24',
        state: 1,
        jobName: 'Разнорабочий',
        jobPosterName: 'FESCO',
        regState: 1,
        type: 'разовый',
        payState: 1,
        manager: {
          name: 'Sasha',
          options: [
            { value: 'fox', label: 'Fox', num: 146 },
            { value: 'Butterfly', label: 'Butterfly', num: 146 },
            { value: 'Honeybee', label: 'Honeybee', num: 146 },
          ],
        },
        vacState: 6,
      },
      {
        key: 8,
        num: 145,
        date: '11.01.24',
        state: 1,
        jobName: 'Горничная в отель',
        jobPosterName: 'Первая грузовая компания',
        regState: 1,
        type: 'стартовый',
        payState: 0,
        manager: {
          name: 'Ekaterina',
          options: [
            { value: 'fox', label: 'Fox', num: 145 },
            { value: 'Butterfly', label: 'Butterfly', num: 145 },
            { value: 'Honeybee', label: 'Honeybee', num: 145 },
          ],
        },
        vacState: 2,
      },
      {
        key: 9,
        num: 144,
        date: '09.01.23',
        state: 1,
        jobName: 'Охранник в ТЦ',
        jobPosterName: 'Первая грузовая компания',
        regState: 0,
        type: 'стартовый',
        payState: 0,
        manager: {
          name: 'Dmitri',
          options: [
            { value: 'fox', label: 'Fox', num: 144 },
            { value: 'Butterfly', label: 'Butterfly', num: 144 },
            { value: 'Honeybee', label: 'Honeybee', num: 144 },
          ],
        },
        vacState: 5,
      },
    ];
    for (let i = 0; i < activeLists.length; i++) {
      if (id === activeLists[i].key.toString()) {
        setItem(activeLists[i]);

        if (activeLists[i].vacState === 0) {
          // This is GreenCheckMark Part.
          setFlagModerationVisible(true);

          // buttonState
          setSendButtonState(true);
          setPostButtonState(true);
          setRegButtonState(true);
          setPayBUttonState(true);
          setCancelButtonState(true);

          // Input Value
          setInputName('ТЦ');
          setInputVac('Охранник в ТЦ');
          setInputAdd('Moscow');
          setInputDesc('Выполнение погрузочных работ на складе сборного груза: сбор товара на поддоны, загрузка с использование ручной рохли. Склад с комфортной температурой. Масса грузов не превышает 10 кг. Дневные и ночные смены на выбор. Перерывы каждые 2 часа, полноценный часовой перерыв.');
          setInputReqr('Готовы рассмотреть сотрудников без опыта работы. Обязательно наличие медицинской книжки. Гражданство РФ.');
          setInputMinAge('18');
          setInputMaxAge('40');
          setInputMinPay('1000');
          setInputMaxPay('10000');
        } else if (activeLists[i].vacState === 1) {
          //This is BlueCross Part.
          setFlagModerationVisible(true);

          // buttonState
          setPostButtonState(true);
          setRegButtonState(true);
          setPayBUttonState(true);
          setCancelButtonState(true);

          // input value init
          setInputName('ТЦ');
          setInputVac('Охранник в ТЦ');
          setInputAdd('Moscow');
          setInputDesc('Выполнение погрузочных работ на складе сборного груза: сбор товара на поддоны, загрузка с использование ручной рохли. Склад с комфортной температурой. Масса грузов не превышает 10 кг. Дневные и ночные смены на выбор. Перерывы каждые 2 часа, полноценный часовой перерыв.');
          setInputReqr('Готовы рассмотреть сотрудников без опыта работы. Обязательно наличие медицинской книжки. Гражданство РФ.');
          setInputMinAge('18');
          setInputMaxAge('40');
          setInputMinPay('1000');
          setInputMaxPay('10000');
        } else if (activeLists[i].vacState === 2) {
          // This is Red Cross Part.
          setFlagModerationVisible(false);

          // buttonState
          setSendButtonState(true);
          setPostButtonState(true);
          setRegButtonState(true);
          setPayBUttonState(true);
          setCancelButtonState(true);
        } else if (activeLists[i].vacState === 3) {
          // This is Orange Square
          setFlagModerationVisible(false);

          // ButtonState
          setPostButtonState(true);
          setRegButtonState(true);
          setPayBUttonState(true);
          // setCancelButtonState(true)
        } else if (activeLists[i].vacState === 4) {
          // This is Green Square
          setFlagModerationVisible(true);

          // Input Value
          setInputName('ТЦ');
          setInputVac('Охранник в ТЦ');
          setInputAdd('Moscow');
          setInputDesc('Выполнение погрузочных работ на складе сборного груза: сбор товара на поддоны, загрузка с использование ручной рохли. Склад с комфортной температурой. Масса грузов не превышает 10 кг. Дневные и ночные смены на выбор. Перерывы каждые 2 часа, полноценный часовой перерыв.');
          setInputReqr('Готовы рассмотреть сотрудников без опыта работы. Обязательно наличие медицинской книжки. Гражданство РФ.');
          setInputMinAge('18');
          setInputMaxAge('40');
          setInputMinPay('1000');
          setInputMaxPay('10000');
        } else if (activeLists[i].vacState === 5) {
          // This is GreenCheckMark Part.
          setFlagModerationVisible(true);

          // buttonState
          setSendButtonState(true);
          setPostButtonState(true);
          setRegButtonState(true);
          setPayBUttonState(true);
          setCancelButtonState(true);

          // Input Value
          setInputName('ТЦ');
          setInputVac('Охранник в ТЦ');
          setInputAdd('Moscow');
          setInputDesc('Выполнение погрузочных работ на складе сборного груза: сбор товара на поддоны, загрузка с использование ручной рохли. Склад с комфортной температурой. Масса грузов не превышает 10 кг. Дневные и ночные смены на выбор. Перерывы каждые 2 часа, полноценный часовой перерыв.');
          setInputReqr('Готовы рассмотреть сотрудников без опыта работы. Обязательно наличие медицинской книжки. Гражданство РФ.');
          setInputMinAge('18');
          setInputMaxAge('40');
          setInputMinPay('1000');
          setInputMaxPay('10000');
        } else if (activeLists[i].vacState === 6) {
          // This is green circle
          setFlagModerationVisible(true);

          // Input Value
          setInputName('ТЦ');
          setInputVac('Охранник в ТЦ');
          setInputAdd('Moscow');
          setInputDesc('Выполнение погрузочных работ на складе сборного груза: сбор товара на поддоны, загрузка с использование ручной рохли. Склад с комфортной температурой. Масса грузов не превышает 10 кг. Дневные и ночные смены на выбор. Перерывы каждые 2 часа, полноценный часовой перерыв.');
          setInputReqr('Готовы рассмотреть сотрудников без опыта работы. Обязательно наличие медицинской книжки. Гражданство РФ.');
          setInputMinAge('18');
          setInputMaxAge('40');
          setInputMinPay('1000');
          setInputMaxPay('10000');
        } else {
          // This is yellow circle
          setFlagModerationVisible(false);

          // ButtonState
          setApproveButtonState(true);
          setRegButtonState(true);
        }
      }
    }
  }, [id]);

  const handleChange = (e) => {
    if (e.target.name === 'edit_name') {
      setInputName(e.target.value);
    } else if (e.target.name === 'edit_vac') {
      setInputVac(e.target.value);
    } else if (e.target.name === 'edit_add') {
      setInputAdd(e.target.value);
    } else if (e.target.name === 'edit_desc') {
      setInputDesc(e.target.value);
    } else if (e.target.name === 'edit_reqr') {
      setInputReqr(e.target.value);
    } else if (e.target.name === 'min_age') {
      setInputMinAge(e.target.value);
    } else if (e.target.name === 'max_age') {
      setInputMaxAge(e.target.value);
    } else if (e.target.name === 'min_pay') {
      setInputMinPay(e.target.value);
    } else if (e.target.name === 'max_pay') {
      setInputMaxPay(e.target.value);
    } else {
    }
  };

  // const handleProfileModal = () => {
  //     setProfileModalState(!profileModalState)
  // }

  const handleDataFromProfileModal = (data) => {
    setProfileModalState(false);
  };

  const handleDataFromButtonOnProfile = () => {
    setProfileModalState(!profileModalState);
    setChatModalState(!chatModalState);
  };

  const handleChatModal = () => {
    setChatModalState(!chatModalState);
  };

  const handleDataFromChatModal = () => {
    setProfileModalState(!profileModalState);
    setChatModalState(!chatModalState);
  };

  const goProfile = () => {
    navigate('/detail');
  };

  const handleHistoryState = () => {
    setHistoryState(!historyState);
  };

  const handleDeleteManager = () => {
    setManagerName('');
  };

  const handleChangeManager = (data) => {
    console.log(data.value);
    setManagerName(data.value);
  };

  const handleEditState = () => {
    setEditState(true);
    setFlagModerationVisible(true);
  };

  const handleSend = () => {
    setSendButtonState(false);
    setPostButtonState(false);
    setRegButtonState(false);
    setPayBUttonState(false);
  };

  const handleApprove = () => {
    setApproveButtonState(false);
    setRegButtonState(false);
    setPayBUttonState(false);
    // setCancelButtonState(true)
  };

  const cancelEditState = () => {
    Swal.fire({
      title: 'Отменить правки?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
    }).then((result) => {
      if (result.isConfirmed) {
        setEditState(false);
        setFlagModerationVisible(false);
        setSendButtonState(false);
      }
    });
  };

  const handleSaveState = () => {
    setEditState(false);
    if (item.vacState === 3) {
      setSendButtonState(true);
    }
  };

  const handleCheckState = () => {
    regCheckState === 'green' ? setRegCheckState('orange') : regCheckState === 'orange' ? setRegCheckState('red') : setRegCheckState('green');
  };

  const handlePayCheckState = () => {
    payCheckState === 'green' ? setPayCheckState('red') : setPayCheckState('green');
  };

  const handleCancel = () => {
    setEditState(false);
  };

  const handleClose = () => {
    Swal.fire({
      title: 'Закрыть страницу?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Да',
      cancelButtonText: 'Нет',
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/vacancy');
      }
    });
  };

  function handlePost() {
    setModalState(!modalState);
  }

  return (
    <div className="mx-auto p-2 flex-1 w-full max-w-[1163px]">
      <div className="w-full mt-9">
        <div className="flex flex-col justify-center items-center px-6 gap-1">
          <p className="text-2xl font-medium">Вакансия Грузчик на склад на модерации</p>
          <input type="text" name="title" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm border border-b-1 border-b-gray-500 focus:border-b-blue-700 focus:outline-none " placeholder="Комментарии менеджера" />
        </div>

        <div className="flex flex-col first:flex-row w-full mt-2 gap-2 justify-between">
          {/* <button
                        type='button'
                        className='text-center p-4 w-fit transition ease-in-out duration-300'
                        onClick={handleProfileModal}
                    >
                        ООО "Ромашка", ИНН 9083567289, +7900 9999999, romashka@mail.ru
                    </button> */}

          <div className="flex flex-col sm:flex-row items-start sm:items-center px-4 sm:px-0">
            <p>ООО "Ромашка", ИНН 9083567289, +7900 9999999, romashka@mail.ru</p>
            {item.vacState !== 7 ? (
              <button type="button" className="text-center p-4 w-fit underline transition ease-in-out duration-300" onClick={goProfile}>
                open1123
              </button>
            ) : (
              ''
            )}
          </div>

          <div className="flex px-4 sm:px-0 gap-x-2">
            <button type="button" className="text-center border rounded py-2 px-4  text-button border-button border-1 w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300" onClick={handleChatModal}>
              Сообщение
            </button>
            <button type="button" className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov w-fit transition ease-in-out duration-300" onClick={handleHistoryState}>
              История вакансии
            </button>
          </div>
        </div>

        {!historyState ? (
          <></>
        ) : (
          <div className="flex w-full p-3">
            <div className="flex flex-col w-full mt-2 p-6 gap-y-4 border-1 border-gray-200 rounded-xl shadow-sm max-h-80 overflow-y-scroll">
              <div className="w-full">
                {item.vacState !== 7 ? (
                  <div className="flex w-fit py-3">
                    {/* <Select
                      classNames={{
                        menuButton: ({ isDisabled }) =>
                          `flex justify-between pr-1.5 py-1 shadow-sm text-gray-900 text-sm border border-1 border-gray-300 focus:border-button focus:outline-none ${
                            isDisabled
                              ? "bg-gray-200"
                              : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                          }`,
                        menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                        listItem: ({ isSelected }) =>
                          `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                            isSelected
                              ? `text-white bg-blue-500`
                              : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                          }`,
                        searchIcon: "hidden ",
                        searchBox:
                          "w-full text-sm text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                      }}
                      placeholder="Менеджер"
                      value={managerName}
                      onChange={handleChangeManager}
                      options={managerOptions}
                      isSearchable
                      isClearable
                    /> */}
                    {managerName !== '' ? (
                      <Menu as="div" className="relative inline-block text-left">
                        <div>
                          <Menu.Button className="inline-flex w-full justify-center border-1 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                            {managerName}
                            <svg xmlns="http://www.w3.org/2000/svg" width="12px" height="12px" fill="currentColor" className="bi bi-caret-down-fill self-center" viewBox="0 0 16 16">
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                            </svg>
                          </Menu.Button>
                        </div>

                        <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="absolute left-0 z-10 mt-2 w-60 origin-top-left rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {({ active }) => (
                                  <Link onClick={handleDeleteManager} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                    снять менеджера
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link to={`/editUser`} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                    профиль менеджера
                                  </Link>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <Select
                        classNames={{
                          menuButton: ({ isDisabled }) => `flex justify-between pr-1.5 py-1 text-gray-900 text-sm ${isDisabled ? 'bg-gray-200' : 'bg-white hover:border-gray-400'}`,
                          menu: 'absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700',
                          listItem: ({ isSelected }) => `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${isSelected ? `text-white bg-blue-500` : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`}`,
                          searchIcon: 'hidden ',
                          searchBox: 'w-full text-sm text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none',
                        }}
                        placeholder="manager"
                        value={managerName}
                        onChange={handleChangeManager}
                        options={managerOptions}
                        isSearchable
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
                <ul className="list-disc px-2 list-outside text-gray-800">
                  <li>вакансия создана Контрагентом «логин» с присвоением номера ***</li>
                  <li>вакансия создана менеджером «логин» с присвоением номера ***</li>
                  <li>вакансия согласована менеджером «логин» и отправлена Администратору «логин» на согласование</li>
                  <li>вакансия изменена менеджером и отправлена Контрагенту</li>
                  <li>вакансия отправлена Администратором с правками контрагенту</li>
                  <li>вакансия активирована менеджером</li>
                  <li>вакансия активирована Администратором</li>
                  <li>вакансия отправлена менеджером с правками контрагенту</li>
                  <li>вакансия изменена контрагентом </li>
                </ul>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-col first:flex-row w-full p-3 mt-4 gap-x-3">
          <div className={`p-6 mt-2 border-1 border-gray-200 rounded-xl shadow-sm transition-all  ${!flagModerationVisible ? 'w-full animate-small-width transition ease-in-out duration-[1200ms]' : 'w-full first:w-1/2 animate-small-width transition ease-in-out duration-[1200ms]'}`}>
            <div className="my-6">
              <div className="flex flex-wrap zero:items-end">
                <label htmlFor="name" className="block mb-2 text-sm font-bold text-gray-900">
                  Название организации
                </label>
              </div>

              <input type="name" name="name" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" placeholder="ПАО Сбербанк" value={`ТЦ`} disabled />
            </div>

            <div className="mb-6">
              <div className="flex zero:items-end">
                <label htmlFor="vac" className="block mb-2 text-sm font-bold text-gray-900">
                  Название вакансии <span style={{ color: '#ff0000' }}>*</span>
                </label>
              </div>
              <input type="text" name="vac" className="shadow-sm block w-full p-2.5 text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" placeholder="ПАО Сбербанк" value={`Охранник в ТЦ`} disabled />
            </div>

            <div className="mb-6">
              <div className="flex zero:items-end">
                <label htmlFor="add" className="block mb-2 text-sm font-bold text-gray-900">
                  Адрес <span style={{ color: '#ff0000' }}>*</span>
                </label>
              </div>
              <input type="text" name="add" className="shadow-sm block w-full p-2.5 text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" placeholder="ПАО Сбербанк" value={`Moscow`} disabled />
            </div>

            <div className="mb-6">
              <label htmlFor="typeVac" className="block mb-2 text-sm font-bold text-gray-900">
                Тип вакансии <span style={{ color: '#ff0000' }}>*</span>
              </label>
              <div className="relative">
                <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" name="grid-state" disabled>
                  <option>Постоянная работа</option>
                  <option>Разовая работа</option>
                  <option>Вахтовый метод</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <div className="flex zero:items-end">
                <label htmlFor="desc" className="block mb-2 text-sm font-bold text-gray-900">
                  Описание вакансии <span style={{ color: '#ff0000' }}>*</span>
                </label>
              </div>

              <textarea
                id="desc"
                name="desc"
                rows="4"
                className="block text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200"
                placeholder=""
                value={`Выполнение погрузочных работ на складе сборного груза: сбор товара на поддоны, загрузка с использование ручной рохли. Склад с комфортной температурой. Масса грузов не превышает 10 кг. Дневные и ночные смены на выбор. Перерывы каждые 2 часа, полноценный часовой перерыв.`}
                disabled></textarea>
            </div>

            <div className="mb-6">
              <div className="flex zero:items-end">
                <label htmlFor="reqr" className="block mb-2 text-sm font-bold text-gray-900">
                  Требования к соискателю
                </label>
              </div>
              <textarea
                name="reqr"
                rows="4"
                className="block text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200"
                placeholder=""
                value="Готовы рассмотреть сотрудников без опыта работы. Обязательно наличие медицинской книжки. Гражданство РФ."
                disabled></textarea>
            </div>

            <div className="w-full mb-6 flex flex-col md:flex-row md:justify-evenly">
              <div className="mb-6 md:w-1/3 pr-2">
                <label htmlFor="gender" className="block mb-2 text-sm font-bold text-gray-900">
                  Пол
                </label>
                <div className="relative">
                  <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" id="gender" disabled>
                    <option>Неважно</option>
                    <option>Мужской</option>
                    <option>Женский</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="mb-6 md:w-1/3 pr-2">
                <label htmlFor="min-age" className="block mb-2 text-sm font-bold text-gray-900">
                  Мин. возраст
                </label>
                <input type="name" name="min-age" className="block w-full p-2.5 shadow-sm text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" placeholder="" value="18" disabled />
              </div>

              <div className="mb-6 md:w-1/3">
                <label htmlFor="max-age" className="block mb-2 text-sm font-bold text-gray-900">
                  Макс. возраст
                </label>
                <input type="text" id="max-age" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" placeholder="" value="65" disabled />
              </div>
            </div>

            <div className="w-full mb-6 flex flex-col md:flex-row md:justify-evenly zero:items-end">
              <div className="mb-6 md:w-1/3 pr-2">
                <label htmlFor="payFreq" className="block mb-2 text-sm font-bold text-gray-900">
                  Периодичность выплат <span style={{ color: '#ff0000' }}>*</span>
                </label>
                <div className="relative">
                  <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" id="payFreq" disabled>
                    <option>Еженедельно</option>
                    <option>Еженедневно</option>
                    <option>Еженемесячно</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="mb-6 md:w-1/3 pr-2">
                <label htmlFor="min-pay" className="block mb-2 text-sm font-bold text-gray-900">
                  Мин стоимость раб. смены <span style={{ color: '#ff0000' }}>*</span>
                </label>
                <input type="text" id="min-pay" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" placeholder="" value="1000" disabled />
              </div>

              <div className="mb-6 md:w-1/3">
                <label htmlFor="max-pay" className="block mb-2 text-sm font-bold text-gray-900">
                  Макс стоимость раб. смены <span style={{ color: '#ff0000' }}>*</span>
                </label>
                <input type="max-pay" id="max-pay" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" placeholder="" value="10000" disabled />
              </div>
            </div>

            <div className="w-full mb-6 flex flex-col md:flex-row md:justify-evenly">
              <div className="mb-6 md:w-1/2 pr-2">
                <label htmlFor="min-wtime" className="block mb-2 text-sm font-bold text-gray-900">
                  Мин. длит. раб. смены
                </label>
                <div className="relative">
                  <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" id="min-wtime" disabled>
                    <option>2 часа</option>
                    <option>3 часа</option>
                    <option>4 часа</option>
                    <option>5 часов</option>
                    <option>6 часов</option>
                    <option>7 часов</option>
                    <option>8 часов</option>
                    <option>9 часов</option>
                    <option>10 часов</option>
                    <option>11 часов</option>
                    <option>12 часов</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="mb-6 md:w-1/2">
                <label htmlFor="max-wtime" className="block mb-2 text-sm font-bold text-gray-900">
                  Мин. длит. раб. смены
                </label>
                <div className="relative">
                  <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" id="max-wtime" disabled>
                    <option>16 часов</option>
                    <option>15 часов</option>
                    <option>14 часов</option>
                    <option>13 часов</option>
                    <option>12 часов</option>
                    <option>11 часов</option>
                    <option>10 часов</option>
                    <option>9 часов</option>
                    <option>8 часов</option>
                    <option>7 часов</option>
                    <option>6 часов</option>
                    <option>5 часов</option>
                    <option>4 часа</option>
                    <option>3 часа</option>
                    <option>2 часа</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-6">
              <label htmlFor="tariff" className="block mb-2 text-sm font-bold text-gray-900">
                Выбор тарифа <span style={{ color: '#ff0000' }}>*</span>
              </label>
              <div className="relative">
                <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200" id="tariff" disabled>
                  <option>Стартовый (бесплатно)</option>
                  <option>Разовый (500р.)</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {!flagModerationVisible ? (
            <></>
          ) : (
            <div className={`w-full first:w-1/2 p-6 mt-2 border-1 border-gray-200 rounded-xl shadow-sm animate-wiggle`}>
              <div className="my-6">
                <div className="flex flex-row mb-1.5 justify-between items-center">
                  <label htmlFor="edit_name" className="block w-1/2 text-sm font-bold text-gray-900">
                    Название организации
                  </label>

                  <input type="text" name="" className="block w-1/2 px-2.5 text-gray-900 text-sm shadow-sm border border-b-1 border-b-gray-500 focus:border-b-blue-700 focus:outline-none " placeholder="Комментарии менеджера" />
                </div>

                <input
                  type="text"
                  id="edit_name"
                  name="edit_name"
                  className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200"
                  placeholder="ПАО Сбербанк"
                  value={inputName}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-6">
                <div className="flex flex-row mb-1.5 justify-between items-center">
                  <label htmlFor="edit_vac" className="block w-1/2 text-sm font-bold text-gray-900">
                    Название вакансии <span style={{ color: '#ff0000' }}>*</span>
                  </label>

                  <input type="text" name="" className="block w-1/2 px-2.5 text-gray-900 text-sm shadow-sm border border-b-1 border-b-gray-500 focus:border-b-blue-700 focus:outline-none " placeholder="Комментарии менеджера" />
                </div>
                <input
                  type="text"
                  id="edit_vac"
                  name="edit_vac"
                  className="shadow-sm block w-full p-2.5 text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200"
                  placeholder="ПАО Сбербанк"
                  value={inputVac}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-6">
                <div className="flex flex-row mb-2 justify-between items-center">
                  <label htmlFor="edit_add" className="block text-sm font-bold text-gray-900">
                    Адрес <span style={{ color: '#ff0000' }}>*</span>
                  </label>

                  <input type="text" name="" className="block w-1/2 px-2.5 text-gray-900 text-sm shadow-sm border border-b-1 border-b-gray-500 focus:border-b-blue-700 focus:outline-none " placeholder="Комментарии менеджера" />
                </div>

                <input
                  type="text"
                  id="edit_add"
                  name="edit_add"
                  className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200"
                  placeholder="ПАО Сбербанк"
                  value={inputAdd}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-6">
                <div className="flex flex-row mb-1.5 justify-between items-center">
                  <label htmlFor="edit_typeVac" className="block w-1/2 text-sm font-bold text-gray-900">
                    Тип вакансии <span style={{ color: '#ff0000' }}>*</span>
                  </label>

                  <input type="text" name="" className="block w-1/2 px-2.5 text-gray-900 text-sm shadow-sm border border-b-1 border-b-gray-500 focus:border-b-blue-700 focus:outline-none " placeholder="Комментарии менеджера" />
                </div>
                <div className="relative">
                  <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" id="edit_typeVac" name="edit_typeVac">
                    <option>Постоянная работа</option>
                    <option>Разовая работа</option>
                    <option>Вахтовый метод</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <div className="flex flex-row mb-2 justify-between items-center">
                  <label htmlFor="edit_desc" className="block text-sm font-bold text-gray-900">
                    Описание вакансии <span style={{ color: '#ff0000' }}>*</span>
                  </label>

                  <input type="text" name="" className="block w-1/2 px-2.5 text-gray-900 text-sm shadow-sm border border-b-1 border-b-gray-500 focus:border-b-blue-700 focus:outline-none " placeholder="Комментарии менеджера" />
                </div>

                <textarea id="edit_desc" name="edit_desc" rows="4" className="block text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" placeholder="ПАО Сбербанк" value={inputDesc} onChange={handleChange}></textarea>
              </div>

              <div className="mb-6">
                <div className="flex flex-row mb-1.5 justify-between items-center">
                  <label htmlFor="edit_reqr" className="block w-1/2 text-sm font-bold text-gray-900">
                    Требования к соискателю
                  </label>

                  <input type="text" name="" className="block w-1/2 px-2.5 text-gray-900 text-sm shadow-sm border border-b-1 border-b-gray-500 focus:border-b-blue-700 focus:outline-none " placeholder="Комментарии менеджера" />
                </div>

                <textarea id="edit_reqr" name="edit_reqr" rows="4" className="block text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" placeholder="ПАО Сбербанк" value={inputReqr} onChange={handleChange}></textarea>
              </div>

              <div className="w-full mb-6 flex flex-col md:flex-row md:justify-evenly">
                <div className="mb-6 md:w-1/3 pr-2">
                  <label htmlFor="edit_gender" className="block mb-2 text-sm font-bold text-gray-900">
                    Пол
                  </label>
                  <div className="relative">
                    <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" id="edit_gender">
                      <option>Неважно</option>
                      <option>Мужской</option>
                      <option>Женский</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-6 md:w-1/3 pr-2">
                  <label htmlFor="edit-min-age" className="block mb-2 text-sm font-bold text-gray-900">
                    Мин. возраст
                  </label>
                  <input type="text" id="edit-min-age" name="min_age" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none " placeholder="ПАО Сбербанк" value={inputMinAge} onChange={handleChange} />
                </div>

                <div className="mb-6 md:w-1/3">
                  <label htmlFor="edit-max-age" className="block mb-2 text-sm font-bold text-gray-900">
                    Макс. возраст
                  </label>
                  <input type="text" id="edit-max-age" name="max_age" className="shadow-sm  text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none block w-full p-2.5" placeholder="ПАО Сбербанк" value={inputMaxAge} onChange={handleChange} />
                </div>
              </div>

              <div className="w-full mb-6 flex flex-col md:flex-row md:justify-evenly zero:items-end">
                <div className="mb-6 md:w-1/3 pr-2">
                  <label htmlFor="edit_payFreq" className="block mb-2 text-sm font-bold text-gray-900">
                    Периодичность выплат <span style={{ color: '#ff0000' }}>*</span>
                  </label>
                  <div className="relative">
                    <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" id="edit_payFreq">
                      <option>Еженедельно</option>
                      <option>Еженедневно</option>
                      <option>Еженемесячно</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="mb-6 md:w-1/3 pr-2">
                  <label htmlFor="edit-min-pay" className="block mb-2 text-sm font-bold text-gray-900">
                    Мин стоимость раб. смены <span style={{ color: '#ff0000' }}>*</span>
                  </label>
                  <input type="text" id="edit-min-pay" name="min_pay" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none " placeholder="ПАО Сбербанк" value={inputMinPay} onChange={handleChange} />
                </div>

                <div className="mb-6 md:w-1/3">
                  <label htmlFor="edit-max-pay" className="block mb-2 text-sm font-bold text-gray-900">
                    Макс стоимость раб. смены <span style={{ color: '#ff0000' }}>*</span>
                  </label>
                  <input type="text" id="edit-max-pay" name="max_pay" className="block w-full p-2.5 text-gray-900 text-sm shadow-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none " placeholder="ПАО Сбербанк" value={inputMaxPay} onChange={handleChange} />
                </div>
              </div>

              <div className="w-full mb-6 flex flex-col md:flex-row md:justify-evenly">
                <div className="mb-6 md:w-1/2 pr-2">
                  <label htmlFor="edit-min-wtime" className="block mb-2 text-sm font-bold text-gray-900">
                    Мин. длит. раб. смены
                  </label>
                  <div className="relative">
                    <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" id="edit-min-wtime">
                      <option>2 часа</option>
                      <option>3 часа</option>
                      <option>4 часа</option>
                      <option>5 часов</option>
                      <option>6 часов</option>
                      <option>7 часов</option>
                      <option>8 часов</option>
                      <option>9 часов</option>
                      <option>10 часов</option>
                      <option>11 часов</option>
                      <option>12 часов</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div className="mb-6 md:w-1/2">
                  <label htmlFor="edit-max-wtime" className="block mb-2 text-sm font-bold text-gray-900">
                    Мин. длит. раб. смены
                  </label>
                  <div className="relative">
                    <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" id="edit-max-wtime">
                      <option>16 часов</option>
                      <option>15 часов</option>
                      <option>14 часов</option>
                      <option>13 часов</option>
                      <option>12 часов</option>
                      <option>11 часов</option>
                      <option>10 часов</option>
                      <option>9 часов</option>
                      <option>8 часов</option>
                      <option>7 часов</option>
                      <option>6 часов</option>
                      <option>5 часов</option>
                      <option>4 часа</option>
                      <option>3 часа</option>
                      <option>2 часа</option>
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mb-6">
                <label htmlFor="edit-tariff" className="block mb-2 text-sm font-bold text-gray-900">
                  Выбор тарифа <span style={{ color: '#ff0000' }}>*</span>
                </label>
                <div className="relative">
                  <select className="block appearance-none text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none" id="edit-tariff">
                    <option>Стартовый (бесплатно)</option>
                    <option>Разовый (500р.)</option>
                  </select>
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex gap-2 md:justify-start items-center md:my-10">
          {!editState ? (
            <div className="flex flex-col md:flex-row w-full gap-2 items-center px-4 md:px-0">
              <button type="button" className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov w-full md:w-fit transition ease-in-out duration-300" onClick={handleEditState}>
                Редактировать
              </button>
              {sendButtonState ? (
                <button type="button" className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300" onClick={handleSend}>
                  Отправить
                </button>
              ) : (
                ''
              )}
              {approveButtonState ? (
                <button type="button" className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300" onClick={handleApprove}>
                  Согласовать
                </button>
              ) : (
                ''
              )}
              {postButtonState ? (
                <button type="button" onClick={handlePost} className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300">
                  Разместить
                </button>
              ) : (
                ''
              )}
              {regButtonState ? (
                <div className="cursor-pointer" onClick={handleCheckState}>
                  {regCheckState === 'green' ? <Green /> : regCheckState === 'orange' ? <Orange /> : <Red />}
                </div>
              ) : (
                ''
              )}
              {payButtonState ? (
                <div className="cursor-pointer" onClick={handlePayCheckState}>
                  {payCheckState === 'green' ? <GreenPay /> : <RedPay />}
                </div>
              ) : (
                ''
              )}
              <button type="button" onClick={handleClose} className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300">
                Закрыть
              </button>
            </div>
          ) : (
            <div className="flex flex-col md:flex-row w-full gap-2 items-center px-4 md:px-0">
              <button type="button" className="text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov w-full md:w-fit transition ease-in-out duration-300" onClick={handleSaveState}>
                Сохранить
              </button>
              {cancelButtonState ? (
                <button type="button" className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300" onClick={handleCancel}>
                  Отмена
                </button>
              ) : (
                ''
              )}
              <button type="button" onClick={cancelEditState} className="text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-fit hover:text-white bg-white hover:bg-button transition ease-in-out duration-300">
                Отменить правки
              </button>
              <input type="checkbox" className="w-[16px] h-[16px] rounded text-blue-600 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none" />
            </div>
          )}
        </div>

        <ProfileModal open={profileModalState} sendDataToParent={handleDataFromProfileModal} sendDataToParentFromButton={handleDataFromButtonOnProfile} />

        <DialogChatModal open={chatModalState} sendDataToParent={handleDataFromChatModal} onCancel={handleChatModal} />

        <BroadCastModal open={modalState} onClose={handlePost} />
      </div>
    </div>
  );
}

export default Moderation;
