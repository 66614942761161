import React from 'react'
import { Modal } from 'antd'

function NotificationModal({open, sendDataToParent}) {

    const handleCancel = () => {
        sendDataToParent(false)
    }

    return (
        <>
            <Modal 
                open={open}
                onCancel={handleCancel}
                footer=""
            >
                <div className='mx-auto p-6 flex flex-col w-full gap-2'>
                    <div className='flex w-full justify-center items-center'>
                        <label htmlFor='message' className='flex text-4xl'>
                            Уведомить
                        </label>
                    </div>
                    <div className='flex w-full'>
                        <textarea
                            id='message'
                            name='message'
                            rows='8'
                            className='block text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none disabled:bg-gray-200'
                            placeholder='Ваше сообщение' 
                        >
                        </textarea>
                    </div>
                    <div className='felx w-full'>
                        <button 
                            type='button'
                            className='text-center w-full border rounded py-2 px-4  text-button border-button border-1 hover:text-white bg-white hover:bg-button transition ease-in-out duration-300'
                            onClick={handleCancel}
                        >
                            Отправить
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default NotificationModal