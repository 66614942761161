import React, { useState } from "react";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import Select from "react-tailwindcss-select";

import { Link } from "react-router-dom";

var check_state = 0;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ActiveList({ actionState }) {
  const [stateLists, setActiveLists] = useState([
    {
      key: 1,
      num: 152,
      date: "15.01.24",
      state: false,
      jobName: "Грузчики на склад",
      jobPosterName: "Газпром",
      regState: 0,
      type: "стартовый",
      manager: {
        name: "Ivan",
        options: [
          { value: "fox", label: "Fox", num: 152 },
          { value: "Butterfly", label: "Butterfly", num: 152 },
          { value: "Honeybee", label: "Honeybee", num: 152 },
        ],
      },
    },
    {
      key: 2,
      num: 151,
      date: "12.01.24",
      state: false,
      jobName: "Горничная в отель",
      jobPosterName: "Ямал СПГ",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Maria",
        options: [
          { value: "fox", label: "Fox", num: 151 },
          { value: "Butterfly", label: "Butterfly", num: 151 },
          { value: "Honeybee", label: "Honeybee", num: 151 },
        ],
      },
    },
    {
      key: 3,
      num: 150,
      date: "11.01.24",
      state: false,
      jobName: "Разнорабочий",
      jobPosterName: "Роснефть",
      regState: 1,
      type: "HR 1",
      manager: {
        name: "Natalia",
        options: [
          { value: "fox", label: "Fox", num: 150 },
          { value: "Butterfly", label: "Butterfly", num: 150 },
          { value: "Honeybee", label: "Honeybee", num: 150 },
        ],
      },
    },
  ]);

  let activeLists = [
    {
      key: 1,
      num: 152,
      date: "15.01.24",
      state: false,
      jobName: "Грузчики на склад",
      jobPosterName: "Газпром",
      regState: 0,
      type: "стартовый",
      manager: {
        name: "Ivan",
        options: [
          { value: "fox", label: "Fox", num: 152 },
          { value: "Butterfly", label: "Butterfly", num: 152 },
          { value: "Honeybee", label: "Honeybee", num: 152 },
        ],
      },
    },
    {
      key: 2,
      num: 151,
      date: "12.01.24",
      state: false,
      jobName: "Горничная в отель",
      jobPosterName: "Ямал СПГ",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Maria",
        options: [
          { value: "fox", label: "Fox", num: 151 },
          { value: "Butterfly", label: "Butterfly", num: 151 },
          { value: "Honeybee", label: "Honeybee", num: 151 },
        ],
      },
    },
    {
      key: 3,
      num: 150,
      date: "11.01.24",
      state: false,
      jobName: "Разнорабочий",
      jobPosterName: "Роснефть",
      regState: 1,
      type: "HR 1",
      manager: {
        name: "Natalia",
        options: [
          { value: "fox", label: "Fox", num: 150 },
          { value: "Butterfly", label: "Butterfly", num: 150 },
          { value: "Honeybee", label: "Honeybee", num: 150 },
        ],
      },
    },
  ];

  const handleCheckChange = (e) => {
    if (e.target.checked === true) {
      check_state += 1;
    } else check_state -= 1;
    actionState(check_state, "active");
  };

  const handleChange = (value) => {
    console.log(value);
    for (var i = 0; i < activeLists.length; i++) {
      if (activeLists[i].num + "" === value.num + "") {
        activeLists[i].manager.name = value.label;
        setActiveLists(activeLists);
      }
    }
  };

  const handleManagerChange = (e) => {
    console.log(e.target.name);
    for (var i = 0; i < activeLists.length; i++) {
      if (activeLists[i].num + "" === e.target.name + "") {
        activeLists[i].manager.name = "";
        setActiveLists(activeLists);
      }
    }
  };

  return (
    <div className="flex w-full flex-col">
      <div className="overflow-x-auto">
        <div className="p-2 min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 mb-20">
              <tbody className="divide-y divide-gray-200 ">
                {stateLists.map((list) => (
                  <tr key={list.key}>
                    <td className="p-2">
                      <div className="flex items-center h-5">
                        <input
                          id={list.num}
                          type="checkbox"
                          aria-checked={false}
                          onClick={handleCheckChange}
                          className="w-4 h-4 border-gray-200 rounded accent-button"
                        />
                      </div>
                    </td>
                    <td className="p-2 hidden md:table-cell">
                      <p>
                        {list.num} - {list.date}
                      </p>
                    </td>
                    <td className="flex flex-col w-fit min-w-56 py-1">
                      <p className="block md:hidden">
                        {list.num} - {list.date}
                      </p>
                      {list.regState === 1 ? (
                        <Link to={`/activation`}>
                          <p className="flex">
                            {list.jobName}
                            {!list.regState ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex icon icon-tabler icon-tabler-alert-triangle self-center ml-1 align-middle text-yellow-600"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 9v2m0 4v.01"></path>
                                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                              </svg>
                            ) : (
                              ""
                            )}
                          </p>
                          <span className="flex text-gray-400">
                            {list.jobPosterName}
                          </span>
                        </Link>
                      ) : (
                        <Link to={`/activationManager`}>
                          <p className="flex flex-row">
                            {list.jobName}
                            {!list.regState ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="flex icon icon-tabler icon-tabler-alert-triangle self-center ml-1 align-middle text-yellow-600"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                strokeWidth="2"
                                stroke="currentColor"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12 9v2m0 4v.01"></path>
                                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                              </svg>
                            ) : (
                              ""
                            )}
                          </p>
                          <span className="flex text-gray-400">
                            {list.jobPosterName}
                          </span>
                        </Link>
                      )}
                    </td>
                    <td className="p-2">{list.type}</td>
                    <td className="p-2">
                      {list.manager.name !== "" ? (
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center border-0 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                              {list.manager.name}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12px"
                                height="12px"
                                fill="currentColor"
                                className="bi bi-caret-down-fill self-center"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                              </svg>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      onClick={handleManagerChange}
                                      name={list.num}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      снять менеджера
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/`}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      профиль менеджера
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      ) : (
                        <Select
                          classNames={{
                            menuButton: ({ isDisabled }) =>
                              `flex justify-between pr-1.5 py-1 text-gray-900 text-sm ${
                                isDisabled
                                  ? "bg-gray-200"
                                  : "bg-white hover:border-gray-400"
                              }`,
                            menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded text-sm text-gray-700",
                            listItem: ({ isSelected }) =>
                              `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                isSelected
                                  ? `text-white bg-blue-500`
                                  : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                              }`,
                            searchIcon: "hidden ",
                            searchBox:
                              "w-full text-sm text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                          }}
                          placeholder="manager"
                          value={list.manager.name}
                          onChange={handleChange}
                          options={list.manager.options}
                          isSearchable
                        />
                      )}
                    </td>
                    <td className="p-2">
                      <Link to={`/unprocessed`}>
                        <button
                          type="button"
                          className="text-center border rounded py-2 px-2  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                        >
                          Отклики
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActiveList;
