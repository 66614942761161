import { createSlice } from '@reduxjs/toolkit';
import { initStateLoginUser, reducerLoginUser } from '../actions/user/loginUser';
import { initStateCheckAccess, reducerCheckAccess } from '../actions/user/checkAccess';
import { initStateUpdateRefresh, reducerUpdateRefresh } from '../actions/user/updateRefresh';
import { initStateLogoutUser, reducerLogoutUser } from '../actions/user/logoutUser';
import { initStatechangePasswordUser, reducerchangePasswordUser } from '../actions/user/changePasswordUser';

export const initialState = {
  ...initStateLoginUser,
  ...initStateCheckAccess,
  ...initStateUpdateRefresh,
  ...initStateLogoutUser,
  ...initStatechangePasswordUser,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetCheckAccess(state, action) {
      state.checkAccess = initialState.checkAccess;
    },
    resetUpdateRefresh(state, action) {
      state.updateRefresh = initialState.updateRefresh;
    },
    resetLoginUser(state, action) {
      state.loginUser = initialState.loginUser;
    },
    resetLogoutUser(state, action) {
      state.logoutUser = initialState.logoutUser;
    },
    resetChangePasswordUser(state, action) {
      state.changePasswordUser = initialState.changePasswordUser;
    },
  },
  extraReducers: {
    ...reducerLoginUser,
    ...reducerCheckAccess,
    ...reducerUpdateRefresh,
    ...reducerLogoutUser,
    ...reducerchangePasswordUser,
  },
});
export const { resetCheckAccess, resetUpdateRefresh, resetLoginUser, resetLogoutUser, resetChangePasswordUser } = userSlice.actions;
export const userReducer = userSlice.reducer;
