import React, { useState, useEffect } from "react";
import ContractorFilter from "../../components/Filter/ContractorFilter";
import ContractorMenu from "./ContractorMenu";
import Pagination from "../../components/Pagination/ContractorPagination";

function ContractorIndex() {
  let tab = localStorage.getItem("contractorTabState");

  const [page, setPage] = useState("");

  useEffect(() => {
    if (tab === null) {
      localStorage.setItem("contractorTabState", "all");
      setPage("all");
    }
    setPage(tab);
  }, [tab]);

  const handlePageFromMenu = (data) => {
    setPage(data);
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <div className="container relative flex flex-1 flex-col zero:flex-row mx-auto max-w-[1163px]">
        <div
          className={`flex flex-col gap-y-2 w-full ${
            page !== "applicant" ? "lg:w-3/4" : ""
          }`}
        >
          <div className="flex w-full px-4 lg:px-0">
            <h2 className="text-[25px] font-normal text-black">Контрагенты</h2>
          </div>
          <div className="flex flex-col w-full px-4 lg:px-0">
            <ContractorMenu sendDataToParent={handlePageFromMenu} />
          </div>
          <div className="flex w-full px-4 lg:px-0">
            {/* <Pagination page={page} items={items} itemsPerPage={10} sortFlag={sortFlag} /> */}
            <Pagination page={page} itemsPerPage={10} />
          </div>
        </div>
        {page !== "applicant" ? (
          <div className="flex w-full lg:w-1/4 mt-10 px-4 lg:px-0">
            <ContractorFilter />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default ContractorIndex;
