import React from "react";
import FeedBackMessage from "./FeedBackMessage";
import { Link } from "react-router-dom";

function FeedBacks() {
  return (
    <div className="flex justify-start w-full flex-col">
      <div className="flex items-center relative w-full">
        <Link to={`/unprocessed`}>
          <h2 className="text-[20px] tracking-[1px] font-semibold">Отклики:</h2>
        </Link>
      </div>
      <div className="flex items-center flex-col border-1 rounded-md mt-2 border-gray-200 shadow-md">
        <div className="h-56 max-h-56 overflow-y-scroll flex flex-col items-start w-full mt-2 pb-10 scrollbar-thin scrollbar-thumb-[#888] scrollbar-track-[#f1f1f1]">
          <ul className="w-full justify-around  p-3 pr-5 list-item">
            <FeedBackMessage />
            <FeedBackMessage />
            <FeedBackMessage />
            <FeedBackMessage />
            <FeedBackMessage />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default FeedBacks;
