import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateLogoutUser = {
  logoutUser: { data: null, loading: false, error: null },
};

export const logoutUser = createAsyncThunk('user/logoutUser', async (data, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .post(`${process.env.REACT_APP_SERVER_API}/auth/logout`, {
      refresh: localStorage.getItem('refreshToken'),
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerLogoutUser = {
  [logoutUser.pending]: (state) => {
    state.logoutUser.loading = true;
    state.logoutUser.error = null;
  },
  [logoutUser.fulfilled]: (state, action) => {
    state.logoutUser.loading = false;
    state.logoutUser.data = action.payload;
    state.logoutUser.error = null;
  },
  [logoutUser.rejected]: (state, action) => {
    state.logoutUser.loading = false;
    state.logoutUser.error = action.payload;
  },
};
