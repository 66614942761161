import React, { useState } from "react";

import { Modal } from "antd";
import Select from "react-tailwindcss-select";

const options = [
  { value: "fox", label: "Fox" },
  { value: "Butterfly", label: "Butterfly" },
  { value: "Honeybee", label: "Honeybee" },
];

function MessageModal({ open, onCancel }) {
  const [selectName, setSelectName] = useState("");

  const handleChange = (value) => {
    setSelectName(value);
  };

  const handleCancel = () => {
    onCancel(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={handleCancel}
        title="Написать сообщение"
        width={600}
        footer=""
      >
        <div className="space-y-5">
          <div className="flex flex-col mx-auto gap-y-5 mt-5">
            <input
              type="text"
              id=""
              className="shadow-sm mb-3 text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none block w-full p-2.5"
              placeholder="Тема сообщения"
              required
            />
            <textarea
              id=""
              rows="4"
              className="block text-black w-full mb-3 p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none"
              placeholder="Ваше сообщение"
            ></textarea>

            <Select
              classNames={{
                menuButton: ({ isDisabled }) =>
                  `flex justify-between pr-1.5 py-1 shadow-sm text-gray-900 text-sm border border-1 border-gray-300 focus:border-button focus:outline-none ${
                    isDisabled
                      ? "bg-gray-200"
                      : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                  }`,
                menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                listItem: ({ isSelected }) =>
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                    isSelected
                      ? `text-white bg-blue-500`
                      : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                  }`,
                searchIcon: "hidden ",
                searchBox:
                  "w-full text-sm text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
              }}
              placeholder="контрагента и менеджера"
              value={selectName}
              onChange={handleChange}
              options={options}
              isSearchable
              isClearable
            />

            <button
              className="bg-button text-white font-bold py-2 px-4 rounded-lg w-full middle:w-40"
              onClick={handleCancel}
            >
              Отправить
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MessageModal;
