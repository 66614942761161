import React, { useEffect, useState } from 'react';
import UserList from '../../components/List/Manage/UserList';
import { Link, useNavigate } from 'react-router-dom';
import Avatar from '../../submodule/img/01.jpg';

import { Upload } from 'antd';
import Input from '../../components/Input/Input';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { resetChangePasswordUser } from '../../redux/slices/user.slice';
import { toast } from 'react-toastify';
import { changePasswordUser } from '../../redux/actions/user/changePasswordUser';
import Loading from '../../components/Loading/Loading';

const items = [
  {
    key: 1,
    name: 'Куликова Александра Мироновна',
  },
  {
    key: 2,
    name: 'Андреева Василиса Матвеевна',
  },
  {
    key: 3,
    name: 'Пахомов Александр Романович',
  },
  {
    key: 4,
    name: 'Крючкова Милана Артёмовна',
  },
  {
    key: 5,
    name: 'Гусев Макар Артёмович',
  },
];

function UserManageIndex() {
  const [adminManage, setAdminManage] = useState(true);
  const [managerManage, setManagerManage] = useState(true);
  const [counterPartyManage, setCounterPartyManage] = useState(true);
  const [editState, setEditState] = useState(false);
  const [nameState, setName] = useState('Иванов Иван Иванович');
  const [userState, setUser] = useState('ivanov');
  const [passwordState, setPassword] = useState('iuhew9809w');
  const [fileList, setFileList] = useState([]);

  const onChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleAdmin = () => {
    setAdminManage(!adminManage);
  };

  const handleManager = () => {
    setManagerManage(!managerManage);
  };

  const handleCounterParty = () => {
    setCounterPartyManage(!counterPartyManage);
  };

  const handleEdit = () => {
    setEditState(true);
  };

  const handleSave = () => {
    setEditState(false);
  };

  const handleChange = (e) => {
    if (e.target.name === 'name') {
      setName(e.target.value);
    } else if (e.target.name === 'user') {
      setUser(e.target.value);
    } else {
      setPassword(e.target.value);
    }
  };

  const form = useForm({
    defaultValues: {
      password: '',
      new_password: '',
    },
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(changePasswordUser(data));
  };
  const {
    changePasswordUser: { data: changePasswordUserData, error: changePasswordUserError, loading: changePasswordUserLoading },
  } = useSelector((state) => state.user);
  useEffect(() => {
    if (changePasswordUserData) {
      toast.success('Пароль успешно изменен', { theme: 'colored', autoClose: '3000' });
      form.reset();
      handleSave();
      dispatch(resetChangePasswordUser());
    }
  }, [changePasswordUserData]);
  useEffect(() => {
    return () => {
      dispatch(resetChangePasswordUser());
    };
  }, []);

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <div className="container relative flex flex-1 flex-col mx-auto max-w-[1163px]">
        <div className="flex flex-col w-full gap-3">
          <div className="flex w-full px-4 lg:px-0">
            <h2 className="text-[25px] font-normal text-black">Управление</h2>
          </div>
          <div className="flex w-full ">
            <div className="flex flex-col first:flex-row w-full gap-3 ">
              <div className="flex w-full first:w-[20%] mb-2 justify-center ">
                {!editState ? (
                  <img src={Avatar} className="avatar rounded-sm w-[200px] h-[200px] p-5" alt="avatar" />
                ) : (
                  <div className="flex justify-center items-center">
                    <Upload action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188" listType="picture-card" fileList={fileList} onChange={onChange} onPreview={onPreview}>
                      {fileList.length < 1 && 'ВыгрузитьЬ'}
                    </Upload>
                  </div>
                )}
              </div>
              <div className="flex flex-col w-full first:w-[50%] mb-2 p-5 gap-3 ">
                <div className="">
                  {!editState ? (
                    <label className="text-lg">Иванов Иван Иванович</label>
                  ) : (
                    <input type="text" name="name" className="shadow-sm edit_btn text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none block w-full p-2.5" placeholder="ПАО Сбербанк" onChange={handleChange} value={nameState} />
                  )}
                </div>
                <div className="">
                  {!editState ? (
                    <label className="text-lg">ivanov</label>
                  ) : (
                    <input type="text" name="user" className="shadow-sm edit_btn text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none block w-full p-2.5" placeholder="ПАО Сбербанк" onChange={handleChange} value={userState} />
                  )}
                </div>
                <div className="">
                  {!editState ? (
                    <label className="text-lg">iuhew9809w</label>
                  ) : (
                    <input type="text" name="password" className="shadow-sm edit_btn text-gray-900 text-sm rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none block w-full p-2.5" placeholder="ПАО Сбербанк" onChange={handleChange} value={passwordState} />
                  )}
                </div>
                {editState && (
                  <>
                    {' '}
                    <Input form={form} type={'password'} name="password" placeholder={'Старый пароль'} disabled={changePasswordUserLoading} error={changePasswordUserError?.['password']?.[0]} />{' '}
                    <Input form={form} type={'password'} name="new_password" placeholder={'Новый пароль'} className="mt-1" disabled={changePasswordUserLoading} error={changePasswordUserError?.['new_password']?.[0]} />
                  </>
                )}
                <div class="relative">
                  {changePasswordUserLoading && <Loading className="absolute top-[135%] lg:top-[19px] left-1/2 lg:left-[132px] transform -translate-x-1/2 -translate-y-1/2" />}

                  {editState ? (
                    <button disabled={changePasswordUserLoading} type="button" onClick={form.handleSubmit(onSubmit)} className="text-center text-[14px] border rounded py-2 px-4 mb-2 text-white  mr-2 w-full break-words p-2 lg:w-fit hover:bg-[#069488] bg-[#00B2A4]">
                      Cохранить
                    </button>
                  ) : (
                    <button type="button" onClick={handleEdit} className="text-center text-[14px] border rounded py-2 px-4 mb-2 text-white  mr-2 w-full break-words p-2 lg:w-fit hover:bg-[#069488] bg-[#00B2A4]">
                      Редактировать
                    </button>
                  )}
                  {changePasswordUserError?.[0] && <div class="text-red-500 text-center text-sm text-start">{changePasswordUserError?.[0]}</div>}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col 468:flex-row justify-between align-middle mt-2 gap-3 px-4 lg:px-0">
              <label className="text-lg underline cursor-pointer" onClick={handleAdmin}>
                Администраторы
              </label>
              <Link to={`/addUser`}>
                <button type="button" className="text-center border rounded py-2 px-4 mb-2 bg-button text-white hover:bg-button-hov mr-2 w-full 468:w-fit transition ease-in-out duration-300">
                  Добавить администратора
                </button>
              </Link>
            </div>
            {adminManage ? (
              <div className="px-4">
                <div className="flex flex-col w-full p-4 border-1 border-[#C4C4C4] shadow-lg">
                  <UserList currentItems={items} />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="flex flex-col w-full gap-3">
            <div className="flex flex-col 468:flex-row justify-between align-middle mt-2 gap-3 px-4 lg:px-0">
              <label className="text-lg underline cursor-pointer" onClick={handleManager}>
                Менеджеры
              </label>
              <Link to={`/addUser`}>
                <button type="button" className="text-center border rounded py-2 px-4 mb-2 bg-button text-white hover:bg-button-hov mr-2 w-full 468:w-fit transition ease-in-out duration-300">
                  Добавить менеджера
                </button>
              </Link>
            </div>
            {managerManage ? (
              <div className="px-4">
                <div className="flex flex-col w-full p-4 border-1 border-[#C4C4C4] shadow-lg">
                  <UserList currentItems={items} />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="flex flex-col w-full gap-3 pb-8">
            <div className="flex flex-col 468:flex-row justify-between align-middle mt-2 gap-3 px-4 lg:px-0">
              <label className="text-lg underline cursor-pointer" onClick={handleCounterParty}>
                Контрагенты
              </label>
              <Link to={`/addUser`}>
                <button type="button" className="text-center border rounded py-2 px-4 mb-2 bg-button text-white hover:bg-button-hov mr-2 w-full 468:w-fit transition ease-in-out duration-300">
                  Добавить контрагента
                </button>
              </Link>
            </div>
            {counterPartyManage ? (
              <div className="px-4">
                <div className="flex flex-col w-full p-4 border-1 border-[#C4C4C4] shadow-lg">
                  <UserList currentItems={items} />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserManageIndex;
