import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateUpdateRefresh = {
  updateRefresh: { data: null, loading: false, error: null },
};

export const updateRefresh = createAsyncThunk('user/updateRefresh', async (data, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .post(`${process.env.REACT_APP_SERVER_API}/auth/refresh`, {
      refresh: localStorage.getItem('refreshToken'),
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerUpdateRefresh = {
  [updateRefresh.pending]: (state) => {
    state.updateRefresh.loading = true;
    state.updateRefresh.error = null;
  },
  [updateRefresh.fulfilled]: (state, action) => {
    state.updateRefresh.loading = false;
    state.updateRefresh.data = action.payload;
    state.updateRefresh.error = null;
  },
  [updateRefresh.rejected]: (state, action) => {
    state.updateRefresh.loading = false;
    state.updateRefresh.error = action.payload;
  },
};
