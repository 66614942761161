import React, { useState, useEffect } from "react";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import Select from "react-tailwindcss-select";

import { Link } from "react-router-dom";

var check_state = 0;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function InActiveVacancyLists(props) {
  const [stateLists, setActiveLists] = useState([
    {
      key: 1,
      num: 152,
      date: "15.01.24",
      state: false,
      jobName: "Грузчики на склад",
      jobPosterName: "Кузбассразрезуголь",
      regState: 0,
      type: "стартовый",
      manager: {
        name: "Ivan",
        options: [
          { value: "fox", label: "Fox", num: 152 },
          { value: "Butterfly", label: "Butterfly", num: 152 },
          { value: "Honeybee", label: "Honeybee", num: 152 },
        ],
      },
    },
    {
      key: 2,
      num: 151,
      date: "12.01.24",
      state: false,
      jobName: "Горничная в отель",
      jobPosterName: "Полюс",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Maria",
        options: [
          { value: "fox", label: "Fox", num: 151 },
          { value: "Butterfly", label: "Butterfly", num: 151 },
          { value: "Honeybee", label: "Honeybee", num: 151 },
        ],
      },
    },
    {
      key: 3,
      num: 150,
      date: "11.01.24",
      state: false,
      jobName: "Разнорабочий",
      jobPosterName: "Металлоинвест",
      regState: 1,
      type: "HR 1",
      manager: {
        name: "Natalia",
        options: [
          { value: "fox", label: "Fox", num: 150 },
          { value: "Butterfly", label: "Butterfly", num: 150 },
          { value: "Honeybee", label: "Honeybee", num: 150 },
        ],
      },
    },
    {
      key: 4,
      num: 149,
      date: "13.01.24",
      state: false,
      jobName: "Комплектовщик на склад",
      jobPosterName: "МХК Еврохим",
      regState: 1,
      type: "HR 2",
      manager: {
        name: "Ekaterina",
        options: [
          { value: "fox", label: "Fox", num: 149 },
          { value: "Butterfly", label: "Butterfly", num: 149 },
          { value: "Honeybee", label: "Honeybee", num: 149 },
        ],
      },
    },
    {
      key: 5,
      num: 148,
      date: "10.01.24",
      state: false,
      jobName: "Повар в ресторан",
      jobPosterName: "Арктикгаз",
      regState: 1,
      type: "HR 3",
      manager: {
        name: "Anna",
        options: [
          { value: "fox", label: "Fox", num: 148 },
          { value: "Butterfly", label: "Butterfly", num: 148 },
          { value: "Honeybee", label: "Honeybee", num: 148 },
        ],
      },
    },
    {
      key: 6,
      num: 147,
      date: "08.01.24",
      state: false,
      jobName: "Копирайтер",
      jobPosterName: "ИНК",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Anastasia",
        options: [
          { value: "fox", label: "Fox", num: 147 },
          { value: "Butterfly", label: "Butterfly", num: 147 },
          { value: "Honeybee", label: "Honeybee", num: 147 },
        ],
      },
    },
    {
      key: 7,
      num: 146,
      date: "09.01.24",
      state: false,
      jobName: "Разнорабочий",
      jobPosterName: "СУЭК",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Sasha",
        options: [
          { value: "fox", label: "Fox", num: 146 },
          { value: "Butterfly", label: "Butterfly", num: 146 },
          { value: "Honeybee", label: "Honeybee", num: 146 },
        ],
      },
    },
    {
      key: 8,
      num: 145,
      date: "07.01.24",
      state: false,
      jobName: "Горничная в отель",
      jobPosterName: "Акрон",
      regState: 1,
      type: "стартовый",
      manager: {
        name: "Ekaterina",
        options: [
          { value: "fox", label: "Fox", num: 145 },
          { value: "Butterfly", label: "Butterfly", num: 145 },
          { value: "Honeybee", label: "Honeybee", num: 145 },
        ],
      },
    },
    {
      key: 9,
      num: 144,
      date: "05.01.24",
      state: false,
      jobName: "Охранник в ТЦ",
      jobPosterName: "Сургутнефтегаз",
      regState: 1,
      type: "стартовый",
      manager: {
        name: "Dmitri",
        options: [
          { value: "fox", label: "Fox", num: 144 },
          { value: "Butterfly", label: "Butterfly", num: 144 },
          { value: "Honeybee", label: "Honeybee", num: 144 },
        ],
      },
    },
  ]);

  const [sortType, setSortType] = useState(props.sortType);
  const [sortDirection, setSortDirection] = useState(props.sortDirection);

  let activeLists = [
    {
      key: 1,
      num: 152,
      date: "15.01.24",
      state: false,
      jobName: "Грузчики на склад",
      jobPosterName: "Кузбассразрезуголь",
      regState: 0,
      type: "стартовый",
      manager: {
        name: "Ivan",
        options: [
          { value: "fox", label: "Fox", num: 152 },
          { value: "Butterfly", label: "Butterfly", num: 152 },
          { value: "Honeybee", label: "Honeybee", num: 152 },
        ],
      },
    },
    {
      key: 2,
      num: 151,
      date: "12.01.24",
      state: false,
      jobName: "Горничная в отель",
      jobPosterName: "Полюс",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Maria",
        options: [
          { value: "fox", label: "Fox", num: 151 },
          { value: "Butterfly", label: "Butterfly", num: 151 },
          { value: "Honeybee", label: "Honeybee", num: 151 },
        ],
      },
    },
    {
      key: 3,
      num: 150,
      date: "11.01.24",
      state: false,
      jobName: "Разнорабочий",
      jobPosterName: "Металлоинвест",
      regState: 1,
      type: "HR 1",
      manager: {
        name: "Natalia",
        options: [
          { value: "fox", label: "Fox", num: 150 },
          { value: "Butterfly", label: "Butterfly", num: 150 },
          { value: "Honeybee", label: "Honeybee", num: 150 },
        ],
      },
    },
    {
      key: 4,
      num: 149,
      date: "13.01.24",
      state: false,
      jobName: "Комплектовщик на склад",
      jobPosterName: "МХК Еврохим",
      regState: 1,
      type: "HR 2",
      manager: {
        name: "Ekaterina",
        options: [
          { value: "fox", label: "Fox", num: 149 },
          { value: "Butterfly", label: "Butterfly", num: 149 },
          { value: "Honeybee", label: "Honeybee", num: 149 },
        ],
      },
    },
    {
      key: 5,
      num: 148,
      date: "10.01.24",
      state: false,
      jobName: "Повар в ресторан",
      jobPosterName: "Арктикгаз",
      regState: 1,
      type: "HR 3",
      manager: {
        name: "Anna",
        options: [
          { value: "fox", label: "Fox", num: 148 },
          { value: "Butterfly", label: "Butterfly", num: 148 },
          { value: "Honeybee", label: "Honeybee", num: 148 },
        ],
      },
    },
    {
      key: 6,
      num: 147,
      date: "08.01.24",
      state: false,
      jobName: "Копирайтер",
      jobPosterName: "ИНК",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Anastasia",
        options: [
          { value: "fox", label: "Fox", num: 147 },
          { value: "Butterfly", label: "Butterfly", num: 147 },
          { value: "Honeybee", label: "Honeybee", num: 147 },
        ],
      },
    },
    {
      key: 7,
      num: 146,
      date: "09.01.24",
      state: false,
      jobName: "Разнорабочий",
      jobPosterName: "СУЭК",
      regState: 1,
      type: "разовый",
      manager: {
        name: "Sasha",
        options: [
          { value: "fox", label: "Fox", num: 146 },
          { value: "Butterfly", label: "Butterfly", num: 146 },
          { value: "Honeybee", label: "Honeybee", num: 146 },
        ],
      },
    },
    {
      key: 8,
      num: 145,
      date: "07.01.24",
      state: false,
      jobName: "Горничная в отель",
      jobPosterName: "Акрон",
      regState: 1,
      type: "стартовый",
      manager: {
        name: "Ekaterina",
        options: [
          { value: "fox", label: "Fox", num: 145 },
          { value: "Butterfly", label: "Butterfly", num: 145 },
          { value: "Honeybee", label: "Honeybee", num: 145 },
        ],
      },
    },
    {
      key: 9,
      num: 144,
      date: "05.01.24",
      state: false,
      jobName: "Охранник в ТЦ",
      jobPosterName: "Сургутнефтегаз",
      regState: 1,
      type: "стартовый",
      manager: {
        name: "Dmitri",
        options: [
          { value: "fox", label: "Fox", num: 144 },
          { value: "Butterfly", label: "Butterfly", num: 144 },
          { value: "Honeybee", label: "Honeybee", num: 144 },
        ],
      },
    },
  ];

  if (sortType === "date") {
    sortDirection !== true
      ? stateLists.sort((a, b) => a.date.localeCompare(b.date))
      : stateLists.sort((a, b) => a.date.localeCompare(b.date)).reverse();
  } else if (sortType === "reg") {
    sortDirection !== true
      ? stateLists.sort((a, b) => a.regState - b.regState)
      : stateLists.sort((a, b) => a.regState - b.regState).reverse();
  } else if (sortType === "pay") {
    sortDirection !== true
      ? stateLists.sort((a, b) => a.payState - b.payState)
      : stateLists.sort((a, b) => a.payState - b.payState).reverse();
  } else {
    sortDirection !== true
      ? stateLists.sort((a, b) => a.manager.name.localeCompare(b.manager.name))
      : stateLists
          .sort((a, b) => a.manager.name.localeCompare(b.manager.name))
          .reverse();
  }

  const handleCheckChange = (e) => {
    if (e.target.checked === true) {
      check_state += 1;
    } else check_state -= 1;
    props.actionState(check_state, "inactive");
  };

  useEffect(() => {
    setSortType(props.sortType);
    setSortDirection(props.sortDirection);
  }, [props]);

  const handleChange = (value) => {
    console.log(value);
    for (var i = 0; i < activeLists.length; i++) {
      if (activeLists[i].num + "" === value.num + "") {
        activeLists[i].manager.name = value.label;
        setActiveLists(activeLists);
      }
    }
  };

  const handleManagerChange = (e) => {
    console.log(e.target.name);
    for (var i = 0; i < activeLists.length; i++) {
      if (activeLists[i].num + "" === e.target.name + "") {
        activeLists[i].manager.name = "";
        setActiveLists(activeLists);
      }
    }
  };

  return (
    <div className="flex w-full flex-col">
      <div className="overflow-x-auto">
        <div className="p-2 min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-200 mb-20">
              <tbody className="divide-y divide-gray-200 ">
                {stateLists.map((list) => (
                  <tr key={list.key}>
                    <td className="p-2">
                      <div className="flex items-center h-5">
                        <input
                          id={list.num}
                          type="checkbox"
                          aria-checked={false}
                          onClick={handleCheckChange}
                          className="w-4 h-4 border-gray-200 rounded accent-button"
                        />
                      </div>
                    </td>
                    <td className="p-2 hidden md:table-cell">
                      <p>
                        {list.num} - {list.date}
                      </p>
                    </td>
                    <td className="flex flex-col w-fit px-2 py-1">
                      <p className="block md:hidden">
                        {list.num} - {list.date}
                      </p>
                      <Link to={`/inactivation`}>
                        <p className="flex flex-row">
                          {list.jobName}
                          {!list.regState ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="flex icon icon-tabler icon-tabler-alert-triangle self-center ml-1 align-middle text-yellow-600"
                              width="16"
                              height="16"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M12 9v2m0 4v.01"></path>
                              <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                            </svg>
                          ) : (
                            ""
                          )}
                        </p>
                        <span className="flex text-gray-400">
                          {list.jobPosterName}
                        </span>
                      </Link>
                    </td>
                    <td className="p-2">{list.type}</td>
                    <td className="p-2">
                      {list.manager.name !== "" ? (
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center border-0 gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                              {list.manager.name}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12px"
                                height="12px"
                                fill="currentColor"
                                className="bi bi-caret-down-fill self-center"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                              </svg>
                            </Menu.Button>
                          </div>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      onClick={handleManagerChange}
                                      name={list.num}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      снять менеджера
                                    </Link>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <Link
                                      to={`/editUser`}
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "block px-4 py-2 text-sm"
                                      )}
                                    >
                                      профиль менеджера
                                    </Link>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      ) : (
                        <Select
                          classNames={{
                            menuButton: ({ isDisabled }) =>
                              `flex justify-between pr-1.5 py-1 text-gray-900 text-sm ${
                                isDisabled
                                  ? "bg-gray-200"
                                  : "bg-white hover:border-gray-400"
                              }`,
                            menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded text-sm text-gray-700",
                            listItem: ({ isSelected }) =>
                              `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                isSelected
                                  ? `text-white bg-blue-500`
                                  : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                              }`,
                            searchIcon: "hidden ",
                            searchBox:
                              "w-full text-sm text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                          }}
                          placeholder="manager"
                          value={list.manager.name}
                          onChange={handleChange}
                          options={list.manager.options}
                          isSearchable
                        />
                      )}
                    </td>
                    <td className="p-2">
                      <Link to={`/unprocessed`}>
                        <button
                          type="button"
                          className="text-center border rounded py-2 px-2  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
                        >
                          Отклики
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InActiveVacancyLists;
