import React, { useState } from "react";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import SelectionFilter from "../../components/Filter/SelectionFilter";
import Pagination from "../../components/Pagination/PerformerPagination";
import AscendingSvg from "../../components/Form/SortSvg/AscendingSvg";
import DescendingSvg from "../../components/Form/SortSvg/DescendingSvg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function SelectionPerformer() {
  const [checkCount, setCheckCount] = useState(0);
  const [buttonState, setButtonState] = useState(0);
  const [sortType, setSortType] = useState("name");
  const [sortDirection, setSortDirection] = useState(true);
  const [sortDisplayName, setSortDisplayName] = useState("по ФИО");

  const sort = (e) => {
    if (e.target.name === sortType) {
      setSortDirection(!sortDirection);
    } else {
      setSortDirection(true);
    }
    console.log(e.target.name);
    if (e.target.name === "name") {
      setSortType("name");
      setSortDisplayName("по ФИО");
    } else if (e.target.name === "reg") {
      setSortType("reg");
      setSortDisplayName("по рег данным");
    } else if (e.target.name === "pay") {
      setSortType("pay");
      setSortDisplayName("по статусу оплаты");
    } else {
      setSortType("manager");
      setSortDisplayName("по менеджер");
    }
  };

  const handleData = (data) => {
    setCheckCount(data);
    setButtonState(0);
  };

  const handleClickButton = () => {
    setButtonState(1);
  };

  return (
    <div className="mx-auto flex flex-1 flex-col w-full max-w-[1163px]">
      <div className="w-full mt-6 p-2">
        <div className="flex flex-col 468:flex-row justify-between gap-3">
          <p className="text-2xl font-medium">Выборка исполнителей</p>
          <p className="flex flex-wrap justify-center items-center">
            <button
              type="button"
              onClick={handleClickButton}
              className="text-center border rounded py-2 px-2  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300"
            >
              Очистить выбор
            </button>
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col first:flex-row p-2 mt-2 gap-x-3 justify-between">
        <div className="w-full first:w-3/4 flex flex-col">
          <div className="w-full flex flex-col 468:flex-row justify-between 468:items-center gap-3">
            <p className="px-2">Выбрано: {checkCount}</p>
            <div className="flex items-center gap-x-3 pr-2">
              <div className="flex items-center">
                <p className="px-2">Сортировать: </p>
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <Menu.Button className="inline-flex w-full justify-center gap-x-3 bg-white p-2 text-sm font-semibold text-gray-900 shadow-sm border-b-1 border-b-gray-700 hover:bg-gray-50">
                      {sortDisplayName}
                      {sortDirection ? (
                        <AscendingSvg color="#000000" />
                      ) : (
                        <DescendingSvg color="#000000" />
                      )}
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="py-1">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              onClick={sort}
                              name="name"
                              className={classNames(
                                "flex flex-row justify-between items-center gap-x-3.5",
                                active
                                  ? "bg-gray-100 text-gray-900"
                                  : "text-gray-700",
                                "block px-4 py-2 text-sm"
                              )}
                            >
                              по ФИО
                              {sortType === "name" ? (
                                sortDirection ? (
                                  <AscendingSvg color="#000000" />
                                ) : (
                                  <DescendingSvg color="#000000" />
                                )
                              ) : (
                                ""
                              )}
                            </Link>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
          <div className="flex flex-col mb-2">
            <Pagination
              itemsPerPage={10}
              sortType={sortType}
              sortDirection={sortDirection}
              buttonState={buttonState}
              onData={handleData}
            />
          </div>
        </div>
        <div className="w-full first:w-1/4 flex flex-col mt-4">
          <SelectionFilter reset={handleClickButton} />
        </div>
      </div>
    </div>
  );
}

export default SelectionPerformer;
