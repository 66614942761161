import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import 'preline/preline';
import { HSStaticMethods } from 'preline/preline';

import './App.css';
import '../src/submodule/assets/font.css';
import '../src/submodule/assets/overall.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// layout
import NavBar from './components/layout/NavBar';
import Footer from './components/layout/Footer';
// dashboard
import DashboardIndex from './pages/dashboard';
import VacancyIndex from './pages/Vacancies';
import Moderation from './pages/Moderation/Moderation';
import SelectionPerformer from './pages/SelectionPerformer';
import ProfileIndex from './pages/Profile/ProfileIndex';
import ActivationIndex from './pages/Activation/ActivationIndex';
import Edit from './components/VacEditComponent/Edit';
import InActivationIndex from './pages/InActivation/InActivationIndex';
import DeletionIndex from './pages/Deletion/DeletionIndex';
import CreateVacancy from './pages/Vacancies/CreateVacancy';
import UnProcessedProposalPage from './pages/Proposal/UnProcessedProposalsPage';
import ProcessedProposalsPage from './pages/Proposal/ProcessedProposalsPage';
import ContractorIndex from './pages/Contractor/ContractorIndex';
import ContractorDetail from './pages/Contractor/ContractorDetail';
import ApplicantsIndex from './pages/Applicants/ApplicantsIndex';
import MessagesIndex from './pages/Messages/MessagesIndex';
import CounterPartyMessages from './pages/Messages/CounterPartyMessages';
import ApplicantMessages from './pages/Messages/ApplicantMessages';
import ManagerMessages from './pages/Messages/ManagerMessages';
import CounterPartyToApplicantMessages from './pages/Messages/CounterPartyToApplicantMessages';
import ChatPage from './pages/Messages/ChatPage';
import UserManageIndex from './pages/Manage/UserManageIndex';
import AdminManage from './pages/Manage/AdminManage';
import ManagerManage from './pages/Manage/ManagerManage';
import CounterPartyManage from './pages/Manage/CounterPartyManage';
import AddUser from './pages/Manage/AddUser';
import EditUser from './pages/Manage/EditUser';

import PageVacActiveBoard from './components/JobDetail/PageVacActiveBoard';
import PageVacDelete from './components/JobDetail/PageVacDelete';
import PageVacNotActive from './components/JobDetail/PageVacNotActive';
import VacancyNotActiveFirst from './components/JobDetail/VacancyNotActiveFirst';
import VacancyNotActiveSecond from './components/JobDetail/VacancyNotActiveSecond';
import VacancyNotActiveThird from './components/JobDetail/VacancyNotActiveThird';
import PageJobActiveBoard from './components/JobDetail/PageJobActiveBoard';
import PageJobNotActive from './components/JobDetail/PageJobNotActive';
import JobNotActiveFirst from './components/JobDetail/JobNotActiveFirst';
import JobNotActiveSecond from './components/JobDetail/JobNotActiveSecond';
import VacancyActiveFromEdit from './components/JobDetail/VacancyActiveFromEdit';
import JobActiveFromEdit from './components/JobDetail/JobActiveFromEdit';
import LoginIndex from './pages/Login';
import { useDispatch, useSelector } from 'react-redux';
import { checkAccess } from './redux/actions/user/checkAccess';
import { updateRefresh } from './redux/actions/user/updateRefresh';
import { setAuth } from './redux/slices/app.slice';
import { resetCheckAccess, resetUpdateRefresh } from './redux/slices/user.slice';

function App() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    checkAccess: { data: checkAccessData, error: checkAccessError, loading: checkAccessLoading },
    updateRefresh: { data: updateRefreshData, error: updateRefreshError, loading: updateRefreshLoading },
  } = useSelector((state) => state.user);
  const { isAuth } = useSelector((state) => state.app);
  useEffect(() => {
    import('preline/preline');
    HSStaticMethods.autoInit();
  }, [location.pathname]);
  const isLoginPage = location.pathname == '/login';

  useEffect(() => {
    if (!localStorage.getItem('refreshToken') || !localStorage.getItem('accessToken')) {
      navigate('/login');
    } else if (location.pathname !== '/login' && !isAuth) {
      dispatch(checkAccess());
    }
    return () => {
      dispatch(resetCheckAccess());
      dispatch(resetUpdateRefresh());
    };
  }, [location.pathname]);

  useEffect(() => {
    if (checkAccessData) {
      dispatch(setAuth(true));
    }
    if (checkAccessError) {
      dispatch(updateRefresh());
    }
  }, [checkAccessError, checkAccessData]);

  useEffect(() => {
    if (updateRefreshData) {
      localStorage.setItem('accessToken', updateRefreshData.access);
      dispatch(checkAccess());
    }
    if (updateRefreshError) {
      navigate('/login');
    }
  }, [updateRefreshError, updateRefreshData]);

  return (
    <div className="flex flex-col min-h-screen">
      <ToastContainer />
      {!isLoginPage && isAuth && <NavBar />}

      <Routes>
        {isAuth && (
          <>
            {' '}
            <Route path="/" element={<DashboardIndex />} />
            <Route path="/vacancy" element={<VacancyIndex />} />
            <Route path="/moderation/:id" element={<Moderation role="admin" />} />
            {/* <Route path='/moderationManager/:id' element={<Moderation role='manager' />} /> */}
            <Route path="/selectionPerformer" element={<SelectionPerformer />} />
            <Route path="/profile" element={<ProfileIndex />} />
            <Route path="/activation" element={<ActivationIndex role="admin" />} />
            <Route path="/activationManager" element={<ActivationIndex role="manager" />} />
            <Route path="/editActivation" element={<Edit page="activation" />} />
            <Route path="/inactivation" element={<InActivationIndex />} />
            <Route path="/editInActivation" element={<Edit page="inactivation" />} />
            <Route path="/deletion" element={<DeletionIndex />} />
            <Route path="/deletionNoResponse" element={<DeletionIndex response={false} />} />
            <Route path="/create" element={<CreateVacancy />} />
            <Route path="/unprocessed" element={<UnProcessedProposalPage />} />
            <Route path="/processed" element={<ProcessedProposalsPage />} />
            <Route path="/contractor" element={<ContractorIndex />} />
            <Route path="/detail" element={<ContractorDetail />} />
            <Route path="/applicants" element={<ApplicantsIndex />} />
            <Route path="/messages" element={<MessagesIndex />} />
            <Route path="/counterpartydetail" element={<CounterPartyMessages />} />
            <Route path="/applicantdetail" element={<ApplicantMessages />} />
            <Route path="/managerdetail" element={<ManagerMessages />} />
            <Route path="/toApplicantdetail" element={<CounterPartyToApplicantMessages />} />
            <Route path="/chatPage" element={<ChatPage managerSelect={true} />} />
            <Route path="managerChatPage" element={<ChatPage managerSelect={false} />} />
            <Route path="/manage" element={<UserManageIndex />} />
            <Route path="/adminManage" element={<AdminManage />} />
            <Route path="/managerManage" element={<ManagerManage />} />
            <Route path="/counterpartyManage" element={<CounterPartyManage />} />
            <Route path="/addUser" element={<AddUser />} />
            <Route path="/editUser" element={<EditUser />} />
            <Route path="/activeBoard" element={<PageVacActiveBoard />} />
            <Route path="/activejobboard" element={<PageJobActiveBoard />} />
            <Route path="/delete" element={<PageVacDelete />} />
            <Route path="/notActive" element={<PageVacNotActive />} />
            <Route path="/first" element={<VacancyNotActiveFirst />} />
            <Route path="/second" element={<VacancyNotActiveSecond />} />
            <Route path="/notActiveJob" element={<PageJobNotActive />} />
            <Route path="/vacFromEdit" element={<VacancyActiveFromEdit />} />
            <Route path="/firstJob" element={<JobNotActiveFirst />} />
            <Route path="/secondJob" element={<JobNotActiveSecond />} />
            <Route path="/third" element={<VacancyNotActiveThird />} />
            <Route path="/jobFromEdit" element={<JobActiveFromEdit />} />
          </>
        )}

        <Route path="/login" element={<LoginIndex />} />
      </Routes>

      {!isLoginPage && isAuth && <Footer />}
    </div>
  );
}

export default App;
