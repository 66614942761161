import React from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../../submodule/img/01.jpg";

function UserList({ currentItems }) {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate("/editUser");
  };

  return (
    <div className="overflow-x-auto">
      <div className="min-w-full inline-block align-middle">
        <div className="overflow-hidden">
          <table className="divide-y divide-gray-200">
            <tbody className="divide-y divide-gray-200 items-center justify-center align-middle">
              {currentItems &&
                currentItems.map((item) => (
                  <tr
                    key={item.key}
                    onClick={handleClick}
                    className="cursor-pointer"
                  >
                    <td className="p-2 whitespace-nowrap">
                      <div className="w-[30px] h-[30px]">
                        <img
                          src={Avatar}
                          className="avatar rounded-sm bg-cover"
                          alt="avatar"
                        />
                      </div>
                    </td>
                    <td className="p-2 whitespace-nowrap text-sm text-gray-800">
                      {item.name}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UserList;
