import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isAuth: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAuth(state, action) {
      state.isAuth = action.payload;
    },
  },
  extraReducers: {},
});
export const { setAuth } = appSlice.actions;
export const appReducer = appSlice.reducer;
