import React, { useState } from "react";
import ChatMessLeft from "../../components/Chat/ChatMessLeft";
import ChatMessRight from "../../components/Chat/ChatMessRight";
import Select from "react-tailwindcss-select";

const options = [
  { value: "fox", label: "Fox" },
  { value: "Butterfly", label: "Butterfly" },
  { value: "Honeybee", label: "Honeybee" },
];

function ChatPage({ managerSelect }) {
  const [selectName, setSelectName] = useState("");

  console.log(managerSelect);

  const handleChange = (value) => {
    setSelectName(value);
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <div className="container relative flex flex-1 flex-col mx-auto max-w-[1163px]">
        <div className="flex flex-col lg:flex-row w-full gap-3">
          <div className="flex flex-col w-full lg:w-3/4 gap-3 p-4">
            <div className="flex w-full">
              <h2 className="text-2xl">Диалог с Иванов Иван Иванович</h2>
            </div>
            <div
              className="flex flex-1 overflow-y-scroll flex-col h-2/3 space-y-4 p-5 overflow-auto border-1 border-[#C4C4C4] shadow-lg"
              style={{ maxHeight: "calc(100vh - 380px)" }}
            >
              <ChatMessLeft />
              <ChatMessRight />
              <ChatMessLeft />
              <ChatMessRight />
              <ChatMessLeft />
              <ChatMessRight />
              <ChatMessLeft />
              <ChatMessRight />
              <ChatMessLeft />
              <ChatMessRight />
            </div>
            <div className="border-t-1 border-gray-200 sm:mb-0">
              <div className="relative flex">
                <input
                  type="text"
                  placeholder="Текст сообщения"
                  className="  text-gray-600 placeholder-gray-400 rounded-lg border border-1 border-gray-300 focus:border-button focus:outline-none block w-full p-2 m-2 transition duration-400 ease-in-out"
                />
                <div className="items-center flex gap-1">
                  <button
                    type="button"
                    className="flex p-2 items-center justify-center rounded-md  transition duration-500 ease-in-out text-white bg-button hover:bg-button-hov focus:outline-none"
                  >
                    Отправить
                  </button>
                </div>
              </div>
            </div>
          </div>
          {managerSelect ? (
            <div className="flex flex-col w-full gap-2 lg:w-1/4 mt-4 p-4">
              <label className="text-base">Добавить менеджера</label>
              <Select
                id="manager"
                name="manager"
                classNames={{
                  menuButton: ({ isDisabled }) =>
                    `flex justify-between pr-1.5 py-1 shadow-sm text-gray-900 text-sm border border-1 border-gray-300 focus:border-button focus:outline-none ${
                      isDisabled
                        ? "bg-gray-200"
                        : "bg-white hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                    }`,
                  menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                  listItem: ({ isSelected }) =>
                    `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                      isSelected
                        ? `text-white bg-blue-500`
                        : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                    }`,
                  searchIcon: "hidden ",
                  searchBox:
                    "w-full text-sm px-4 py-2 text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                }}
                placeholder="менеджера"
                value={selectName}
                onChange={handleChange}
                options={options}
                isSearchable
                isClearable
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default ChatPage;
