import React, { useState } from "react";
import { Modal } from "antd";
import Avatar from "../../submodule/img/01.jpg";
import { Link } from "react-router-dom";

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

import Select from "react-tailwindcss-select";

const JobPosterOptions = [
  { value: "fox", label: "Fox" },
  { value: "Butterfly", label: "Butterfly" },
  { value: "Honeybee", label: "Honeybee" },
];

const managerOptions = [
  { value: "fox", label: "Fox" },
  { value: "Butterfly", label: "Butterfly" },
  { value: "Honeybee", label: "Honeybee" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProfileModal(props) {
  const [divState, setDivState] = useState(false);

  const [jobPosterName, setJobPosterName] = useState("");
  const [managerName, setManagerName] = useState("");

  const handleCancel = () => {
    props.sendDataToParent(false);
  };

  const handleChatModal = () => {
    props.sendDataToParentFromButton(true);
  };

  const handleDivState = () => {
    setDivState(!divState);
  };

  const handleChange = (value) => {
    setJobPosterName(value);
  };

  const handleChangeManager = (value) => {
    setManagerName(value);
  };

  return (
    <>
      <Modal open={props.open} onCancel={handleCancel} width={1000} footer="">
        <div className="mx-auto p-6 flex flex-col first:flex-row w-full">
          <div className="flex w-full first:w-[20%] mt-0 first:mt-8 mb-2 justify-center">
            <img
              src={Avatar}
              className="avatar avatar-md-sm rounded-sm w-[200px] h-[200px] p-5"
              alt="avatar"
            />
          </div>
          <div className="flex w-full first:w-[60%] mt-0 first:mt-8 mb-2 p-5">
            <div className="flex flex-col justify-start">
              <p className="text-2xl font-medium mb-3">Иванов Иван Иванович</p>
              <p className="text-xl font-medium mb-3">муж, 30 лет</p>
              <p className="text-xs font-medium mb-3 text-shaded-text">Адрес</p>
              <p className="text-xl font-medium mb-3">
                г. Краснодар, ул. Гидростроителей, 65б
              </p>
              <p className="text-xs font-medium mb-3 text-shaded-text">
                Telegram
              </p>
              <p className="text-xl font-medium mb-3">username</p>
              <p className="text-xs font-medium mb-3 text-shaded-text">
                Телефон
              </p>
              <p className="text-xl font-medium mb-5">8 999 999 99 99</p>
              <p className="text-xl font-medium mb-3">Комментарий менеджера</p>
              <Link onClick={handleDivState} className="">
                <p className="flex flex-row text-2xs font-medium mb-3 text-shaded-text">
                  Не заполнен
                  {divState ? (
                    <span className="pl-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.5 15.75l7.5-7.5 7.5 7.5"
                        />
                      </svg>
                    </span>
                  ) : (
                    <span className="pl-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    </span>
                  )}
                </p>
              </Link>
              {divState ? (
                <div className="space-y-3">
                  <div className="mb-3 animate-fade-down animate-duration-[3000ms] animate-delay-[10ms] animate-ease-in">
                    <textarea
                      name="comment"
                      rows="4"
                      className="block  text-black w-full p-2.5 rounded-lg leading-tight border border-1 border-gray-300 focus:border-button focus:outline-none"
                      placeholder="Please write a comment!"
                    ></textarea>
                  </div>
                  <div className="mb-2">
                    <button
                      type="button"
                      className="text-center text-[14px] border rounded py-2 px-4 mb-2 text-white  mr-2 w-full break-words p-2 xs:w-full hover:bg-[#069488] bg-[#00B2A4]"
                    >
                      Сохранять
                    </button>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="flex flex-col w-full first:w-[20%] mt-0 first:mt-8 mb-2 p-5">
            <div className="mb-2">
              <button
                type="button"
                onClick={handleChatModal}
                className="text-center text-[14px] border rounded py-2 px-4 mb-2 text-white  mr-2 w-full break-words p-2 xs:w-full hover:bg-[#069488] bg-[#00B2A4]"
              >
                Написать сообщение
              </button>
            </div>
            <div className="mb-4">
              <Menu as="div" className="relative inline-block text-left w-full">
                <div>
                  <Menu.Button className="inline-flex text-center border rounded py-2 px-6 items-center justify-center  text-button border-button border-1 w-full hover:text-white bg-white hover:bg-button transition ease-in-out duration-300 gap-2">
                    Отправки
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12px"
                      height="12px"
                      fill="currentColor"
                      className="bi bi-caret-down-fill self-center"
                      viewBox="0 0 16 16"
                    >
                      <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                    </svg>
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        <Select
                          classNames={{
                            menuButton: ({ isDisabled }) =>
                              `flex justify-between pr-1.5 py-1 shadow-sm text-gray-900 text-sm outline-none ${
                                isDisabled ? "bg-gray-200" : "bg-white"
                              }`,
                            menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                            listItem: ({ isSelected }) =>
                              `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                isSelected
                                  ? `text-white bg-blue-500`
                                  : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                              }`,
                            searchIcon: "hidden ",
                            searchBox:
                              "w-full text-sm px-4 py-2 text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                          }}
                          placeholder="Менеджер"
                          value={managerName}
                          onChange={handleChangeManager}
                          options={managerOptions}
                          isSearchable
                          isClearable
                        />
                      </Menu.Item>
                      <Menu.Item>
                        <Select
                          classNames={{
                            menuButton: ({ isDisabled }) =>
                              `flex justify-between pr-1.5 py-1 shadow-sm text-gray-900 text-sm outline-none ${
                                isDisabled ? "bg-gray-200" : "bg-white"
                              }`,
                            menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                            listItem: ({ isSelected }) =>
                              `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded ${
                                isSelected
                                  ? `text-white bg-blue-500`
                                  : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                              }`,
                            searchIcon: "hidden ",
                            searchBox:
                              "w-full text-sm px-4 py-2 text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                          }}
                          placeholder="Контрагенту"
                          value={jobPosterName}
                          onChange={handleChange}
                          options={JobPosterOptions}
                          isSearchable
                          isClearable
                        />
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            // onClick={handleApprove}
                            className={classNames(
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700",
                              "block px-4 py-2 text-sm"
                            )}
                          >
                            Отправить
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProfileModal;
