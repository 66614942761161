import React, { useState } from "react";
import ApplicantsMenu from "./ApplicantsMenu";
import Pagination from "../../components/Pagination/ApplicantsPagination";
import ApplicantsFilter from "../../components/Filter/ApplicantsFilter";

const itemsIndex = [
  {
    key: 1,
    num: 153,
    name: "Куликова Александра Мироновна",
    state: "Активных",
    gender: 0,
    age: 24,
    reg_date: "18.05.22 ",
  },
  {
    key: 2,
    num: 151,
    name: "Андреева Василиса Матвеевна",
    state: "Активных",
    gender: 1,
    age: 32,
    reg_date: "15.05.22 ",
  },
  {
    key: 3,
    num: 150,
    name: "Пахомов Александр Романович",
    state: "Удалены",
    gender: 0,
    age: 22,
    reg_date: "19.05.22 ",
  },
  {
    key: 4,
    num: 149,
    name: "Крючкова Милана Артёмовна",
    state: "Не активных",
    gender: 1,
    age: 28,
    reg_date: "18.06.22 ",
  },
  {
    key: 5,
    num: 148,
    name: "Гусев Макар Артёмович",
    state: "На модерации",
    gender: 0,
    age: 26,
    reg_date: "18.05.23 ",
  },
  {
    key: 6,
    num: 147,
    name: "Егоров Лев Всеволодович",
    state: "Активных",
    gender: 0,
    age: 21,
    reg_date: "18.05.22 ",
  },
  {
    key: 7,
    num: 146,
    name: "Кулешова Василиса Фёдоровна",
    state: "Активных",
    gender: 1,
    age: 24,
    reg_date: "20.05.22 ",
  },
  {
    key: 8,
    num: 145,
    name: "Воронин Никита Фёдорович",
    state: "Активных",
    gender: 0,
    age: 23,
    reg_date: "18.05.22 ",
  },
  {
    key: 9,
    num: 144,
    name: "Муравьев Даниил Львович",
    state: "Активных",
    gender: 1,
    age: 25,
    reg_date: "28.05.22 ",
  },
];
itemsIndex.sort((a, b) => a.name.localeCompare(b.name)).reverse();
const itemsPermanently = [
  {
    key: 1,
    num: 153,
    name: "Пахомов Александр Романович",
    state: "Активных",
    gender: 0,
    age: 24,
    reg_date: "18.05.22 ",
  },
  {
    key: 2,
    num: 151,
    name: "Гусев Макар Артёмович",
    state: "Активных",
    gender: 1,
    age: 32,
    reg_date: "15.05.22 ",
  },
  {
    key: 3,
    num: 150,
    name: "Куликова Александра Мироновна",
    state: "Удалены",
    gender: 0,
    age: 22,
    reg_date: "19.05.22 ",
  },
  {
    key: 4,
    num: 149,
    name: "Андреева Василиса Матвеевна",
    state: "Не активных",
    gender: 1,
    age: 28,
    reg_date: "18.06.22 ",
  },
  {
    key: 5,
    num: 148,
    name: "Крючкова Милана Артёмовна",
    state: "На модерации",
    gender: 0,
    age: 26,
    reg_date: "18.05.23 ",
  },
  {
    key: 6,
    num: 147,
    name: "Егоров Лев Всеволодович",
    state: "Активных",
    gender: 0,
    age: 21,
    reg_date: "18.05.22 ",
  },
  {
    key: 7,
    num: 146,
    name: "Кулешова Василиса Фёдоровна",
    state: "Активных",
    gender: 1,
    age: 24,
    reg_date: "20.05.22 ",
  },
  {
    key: 8,
    num: 145,
    name: "Воронин Никита Фёдорович",
    state: "Активных",
    gender: 0,
    age: 23,
    reg_date: "18.05.22 ",
  },
  {
    key: 9,
    num: 144,
    name: "Муравьев Даниил Львович",
    state: "Активных",
    gender: 1,
    age: 25,
    reg_date: "28.05.22 ",
  },
];
itemsPermanently.sort((a, b) => a.name.localeCompare(b.name)).reverse();

function ApplicantsIndex() {
  const [page, setPage] = useState("all");
  const [sortFlag, setSortFlag] = useState(0);
  const [items, setItems] = useState(itemsIndex);
  const [actionState, setActionState] = useState(0);

  const handlePageFromMenu = (data) => {
    setPage(data);
    if (data === "permanently") {
      itemsPermanently.sort((a, b) => a.name.localeCompare(b.name)).reverse();
      setItems(itemsPermanently);
    } else {
      itemsIndex.sort((a, b) => a.name.localeCompare(b.name)).reverse();
      setItems(itemsIndex);
    }
  };

  const handleActionState = (data) => {
    setActionState(data);
    console.log(data);
  };

  const handleSort = (data) => {
    setSortFlag(sortFlag + 1);
    if (data.type === "name") {
      data.direction !== true
        ? items.sort((a, b) => a.name.localeCompare(b.name))
        : items.sort((a, b) => a.name.localeCompare(b.name)).reverse();
    } else if (data.type === "gender") {
      data.direction !== true
        ? items.sort((a, b) => a.gender - b.gender)
        : items.sort((a, b) => a.gender - b.gender).reverse();
    } else if (data.type === "age") {
      data.direction !== true
        ? items.sort((a, b) => a.age - b.age)
        : items.sort((a, b) => a.age - b.age).reverse();
    } else if (data.type === "reg_date") {
      data.direction !== true
        ? items.sort((a, b) => a.reg_date.localeCompare(b.reg_date))
        : items.sort((a, b) => a.reg_date.localeCompare(b.reg_date)).reverse();
    } else {
    }
  };

  return (
    <div className="w-full flex flex-1 mt-10 p-1">
      <div className="container relative flex flex-1 flex-col lg:flex-row  mx-auto max-w-[1163px]">
        <div className="flex flex-col w-full lg:w-3/4 gap-y-2 px-4 lg:px-0">
          <div className="flex w-full">
            <h2 className="text-[25px] font-normal text-black">Соискатели</h2>
          </div>
          <div className="w-full">
            <ApplicantsMenu
              actionState={actionState}
              sendDataToParent={handlePageFromMenu}
              sendSortState={handleSort}
            />
          </div>
          <div className="flex w-full">
            <Pagination
              page={page}
              items={items}
              itemsPerPage={10}
              sendDataToParent={handleActionState}
              sortFlag={sortFlag}
            />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-1/4 gap-y-2 mt-10 px-4 lg:px-0">
          <ApplicantsFilter />
        </div>
      </div>
    </div>
  );
}

export default ApplicantsIndex;
