import React from 'react'

function MessagesFilter({ type }) {
    return (
        <div className='flex flex-col w-full p-4 rounded-md border-1 border-[#C4C4C4] shadow-lg mb-4'>
            <div className='mb-6'>
                <p className='text-xl font-medium'>Фильтр</p>
            </div>

            {
                type === 1 ?
                    <div className='mb-2'>
                        <input
                            type="text"
                            name="keyword"
                            id="keyword"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                            placeholder='Поиск по фразе, предложению'
                            required
                        />
                    </div> : ''
            }

            {
                type === 2 ?
                    <div className='mb-2'>
                        <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                            placeholder='ФИО'
                            required
                        />
                    </div> : ''
            }

            {
                type === 2 ?
                    <div className='mb-2'>
                        <select
                            id="gender"
                            name="gender"
                            className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 outline-none ring-gray-300 focus:ring-1 focus:ring-blue-500 sm:text-sm sm:leading-6"
                        >
                            <option> Пол </option>
                            <option> Мужской </option>
                            <option> Женский </option>
                            <option> Неважно </option>
                        </select>
                    </div> : ''
            }

            {
                type === 3 ?
                    <div className='mb-2'>
                        <input
                            type="text"
                            name="counterparty"
                            id="counterparty"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                            placeholder='Поиск по Контрагентам'
                            required
                        />
                    </div> : ''
            }

            {
                type === 3 ?
                    <div className='mb-2'>
                        <input
                            type="text"
                            name="applicant"
                            id="applicant"
                            className="block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-blue-500 focus:outline-none sm:text-sm sm:leading-6"
                            placeholder='Поиск по соискателям'
                            required
                        />
                    </div> : ''
            }

            <div className='mb-2'>
                <select
                    id="manager"
                    name="manager"
                    className="block w-full rounded-md border-0 p-2 text-gray-900 shadow-sm ring-1 outline-none ring-gray-300 focus:ring-1 focus:ring-blue-500 sm:text-sm sm:leading-6"
                >
                    <option> Менеджер </option>
                    <option> Fox </option>
                    <option> ButterFly </option>
                    <option> HoneyBee </option>
                </select>
            </div>

            <div className='mb-2 px-2 flex flex-col gap-y-2'>
                <button
                    type='button'
                    className='text-center border rounded py-2 px-4 bg-button text-white hover:bg-button-hov mr-2 w-full transition ease-in-out duration-300'
                >
                    Применить
                </button>
                <button
                    type='button'
                    className='text-center border rounded py-2 px-4  text-button border-button border-1 w-full md:w-auto hover:text-white bg-white hover:bg-button transition ease-in-out duration-300'
                >
                    Сбросить
                </button>
            </div>
        </div>
    )
}

export default MessagesFilter