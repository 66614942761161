import React, { useState, Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";

import Swal from "sweetalert2";
import Select from "react-tailwindcss-select";

const managerOptions = [
  { value: "fox", label: "Fox" },
  { value: "Butterfly", label: "Butterfly" },
  { value: "Honeybee", label: "Honeybee" },
];

const JobPosterOptions = [
  { value: "fox", label: "Fox" },
  { value: "Butterfly", label: "Butterfly" },
  { value: "Honeybee", label: "Honeybee" },
];

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }

function ProcessedFeedBackList({ currentItems }) {
  const [checkState, setCheckState] = useState(0);
  const [sendButtonState, setSendButtonState] = useState(false);
  const [managerName, setManagerName] = useState("");
  const [jobPosterName, setJobPosterName] = useState("");

  const handleCheck = (e) => {
    const tag = document.getElementById("select_all");
    if (e.target.name === "all") {
      const checkboxes = document.querySelectorAll(".select_checkbox");
      if (tag.checked) {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = true;
        });
      } else {
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      }

      if (e.target.checked === true) {
        setCheckState(checkboxes.length);
      } else setCheckState(0);
    } else {
      tag.checked = false;
      if (e.target.checked === true) {
        setCheckState(checkState + 1);
      } else setCheckState(checkState - 1);
    }
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Вы действительно хотите удалить отклик?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Да",
      cancelButtonText: "Нет",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Отклик удален",
          showConfirmButton: false,
          timer: 1000,
          timerProgressBar: true,
        });
      }
    });
  };

  const handleSend = (event) => {
    event.preventDefault();
    setSendButtonState(!sendButtonState);
  };

  const handleChangeManager = (value) => {
    setManagerName(value);
  };

  const handleChangeCounterParty = (value) => {
    setJobPosterName(value);
  };

  return (
    <div className="flex flex-col w-full">
      <div className="overflow-y-auto">
        <div className="p-2 min-w-full inline-block align-middle">
          <div className="border rounded-lg overflow-hidden justify-start">
            <table className="min-w-full items-center align-middle divide-y divide-gray-200 ">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="pr-2 py-3 text-start text-[16px] font-medium uppercase"
                  >
                    <input
                      id="select_all"
                      name="all"
                      type="checkbox"
                      aria-checked={false}
                      className="h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                      onClick={handleCheck}
                    />
                  </th>
                  <th
                    scope="col"
                    className=" px-2 py-3 text-start text-[16px] font-medium uppercase"
                  >
                    №
                  </th>
                  <th
                    scope="col"
                    className=" px-2 py-3 text-start text-[16px] font-medium uppercase"
                  >
                    ФИО
                  </th>
                  <th className="flex py-1 text-start text-[16px] font-medium justify-start">
                    {checkState ? (
                      <Menu
                        as="div"
                        className="relative inline-block text-left"
                      >
                        <div>
                          <Menu.Button className="inline-flex w-full border-0 gap-x-1.5 rounded-md bg-white px-3 py-2 font-semibold text-gray-900">
                            Действия
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12px"
                              height="12px"
                              fill="currentColor"
                              className="bi bi-caret-down-fill self-center"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"></path>
                            </svg>
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              <Menu.Item>
                                {!sendButtonState ? (
                                  <button
                                    onClick={handleSend}
                                    className={`bg-gray-100 text-gray-900 block px-4 py-2 text-sm `}
                                  >
                                    Отправить контрагенту
                                  </button>
                                ) : (
                                  <Select
                                    classNames={{
                                      menuButton: ({ isDisabled }) =>
                                        `flex justify-between pr-1.5 px-2 py-1 shadow-sm text-gray-900 text-sm outline-none ${
                                          isDisabled
                                            ? "bg-gray-200"
                                            : "bg-white"
                                        }`,
                                      menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                                      listItem: ({ isSelected }) =>
                                        `block transition duration-200 p-2 cursor-pointer select-none truncate rounded ${
                                          isSelected
                                            ? `text-white bg-blue-500`
                                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                        }`,
                                      searchIcon: "hidden ",
                                      searchBox:
                                        "w-full text-sm p-2 text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                                    }}
                                    placeholder="Менеджер"
                                    value={managerName}
                                    onChange={handleChangeManager}
                                    options={managerOptions}
                                    isSearchable
                                    isClearable
                                  />
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {!sendButtonState ? (
                                  <Link
                                    onClick={handleDelete}
                                    className={`bg-gray-100 text-gray-900 block px-4 py-2 text-sm `}
                                  >
                                    Удалить
                                  </Link>
                                ) : (
                                  <Select
                                    classNames={{
                                      menuButton: ({ isDisabled }) =>
                                        `flex justify-between pr-1.5 px-2 py-1 shadow-sm text-gray-900 text-sm outline-none ${
                                          isDisabled
                                            ? "bg-gray-200"
                                            : "bg-white"
                                        }`,
                                      menu: "absolute z-10 w-full bg-white shadow-lg border-1 rounded py-1 mt-1.5 text-sm text-gray-700",
                                      listItem: ({ isSelected }) =>
                                        `block transition duration-200 p-2 cursor-pointer select-none truncate rounded ${
                                          isSelected
                                            ? `text-white bg-blue-500`
                                            : `text-gray-500 hover:bg-blue-100 hover:text-blue-500`
                                        }`,
                                      searchIcon: "hidden ",
                                      searchBox:
                                        "w-full text-sm px-4 py-2 text-gray-500 bg-gray-100 border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                                    }}
                                    placeholder="Контрагенту"
                                    value={jobPosterName}
                                    onChange={handleChangeCounterParty}
                                    options={JobPosterOptions}
                                    isSearchable
                                    isClearable
                                  />
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      ""
                    )}
                  </th>
                </tr>
              </thead>
              <tbody className={`items-center align-middle`}>
                {currentItems &&
                  currentItems.map((item) => (
                    <tr key={item.key} className="">
                      <td className="pr-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        <input
                          name={item.num}
                          type="checkbox"
                          aria-checked={"false"}
                          onClick={handleCheck}
                          className="select_checkbox h-3 w-3 rounded border-gray-300 text-blue-500 focus:ring-blue-500"
                        />
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        {item.num}
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        <Link to={`/profile`}>{item.name}</Link>
                      </td>
                      <td className="px-2 py-4 whitespace-nowrap text-sm text-gray-800">
                        <button
                          className={`flex w-fit px-2.5 py-1 rounded-md bg-gray-300`}
                          onClick={handleDelete}
                        >
                          Удалить
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProcessedFeedBackList;
