import clsx from 'clsx';
import React from 'react';

export default function Loading({ className = 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' }) {
  return (
    <div class={clsx('spinner', className)}>
      <div class="bar1"></div>
      <div class="bar2"></div>
      <div class="bar3"></div>
      <div class="bar4"></div>
      <div class="bar5"></div>
      <div class="bar6"></div>
      <div class="bar7"></div>
      <div class="bar8"></div>
      <div class="bar9"></div>
      <div class="bar10"></div>
      <div class="bar11"></div>
      <div class="bar12"></div>
    </div>
  );
}
